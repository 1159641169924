import React, { useEffect, useRef, useState } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SearchSelect from "../../../components/Common/SearchSelect"
import Icon from "../../../components/Common/Icon"
import Details from "../Details"
import Reject from "../Reject"

import { statusOptions } from "./helper"
import {
  dateConverter,
  getDate,
  reactSelectCustomStyle,
  renderTableValue,
  setTitle,
  toTop,
} from "../../../helpers/functions"
import { get, put } from "../../../helpers/api_helper"
import { typeOptions } from "../Applications/helper"
import { TYPE_VALUES } from "../Post/helper"

import "./styles.scss"

const InternshipShortListed = () => {
  setTitle("Internship Shortlist")

  const formRef = useRef()
  const todayDate = getDate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [tableData, setTableData] = useState([])

  const [viewModal, setViewModal] = useState({ show: false, id: null })
  const [modal, setModal] = useState({ show: false, id: null, status: 5 })
  const [filterObject, setFilterObject] = useState({ status: 2 })
  const [selectedFields, setSelectedFields] = useState({})

  const [companyOptions, setCompanyOptions] = useState([])
  const [internshipOptions, setInternshipOptions] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleInternshipOptions()
  }, [filterObject]) //eslint-disable-line

  const handleCompanyOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `internship/applications/options/company?${params}`,
      )
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInternshipOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `internship/applications/options/internship?${params}`,
      )
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleTableData = async (currentPage = page) => {
    const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`internship/applications/shortlist?${query}`)
    const { count, data } = response

    data.map((item, index) => {
      item.id = index + 1 + (currentPage - 1) * limit
      item.date = dateConverter(item.date)

      item.internship = renderTableValue(item.internship?.name)
      item.company = renderTableValue(item.company?.name)
      item.type = renderTableValue(TYPE_VALUES[item.type])

      const candidate = item.candidate
      item.candidateId = renderTableValue(candidate?.uniqueId)
      item.candidate = renderTableValue(candidate?.name)

      item.resume = item.resume?.uploaded
      item.status = (
        <Select
          styles={reactSelectCustomStyle}
          menuPosition="fixed"
          value={""}
          options={statusOptions}
          onChange={selected =>
            handleStatusChange({ id: item._id, status: selected.value })
          }
        />
      )

      item.options = (
        <div className="d-flex align-items-center justify-content-center">
          <Icon
            icon="eye"
            title="View"
            onClick={() => handleViewModal(item._id, true)}
          />

          <Icon
            icon="pdf"
            title="Uploaded Resume"
            href={item.resume}
            hidden={!item.resume}
          />
        </div>
      )

      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
  }

  const handleStatusChange = async ({ id, status }) => {
    try {
      if (!id || !status) return
      if (status === 5) {
        handleToggle({ show: true, id })
        return
      }
      const response = await put(`internship/applications/status`, {
        id,
        status,
      })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error.response?.data?.message)
    }
  }

  const handleViewModal = (id, show = false) => {
    setViewModal({ id, show })
  }

  const handleToggle = ({ show = false, id = null, status = 5 } = {}) => {
    setModal({ show, id, status })
    handleTableData()
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value: value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
    toTop()
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    setFilterObject({ status: 2 })
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "ID", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Type", field: "type" },
      { label: "Internship", field: "internship" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Internship - Shortlisted" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    options={companyOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "company" })
                    }}
                    value={selectedFields.company || ""}
                    isClearable
                  />
                </Col>

                <Col md={2}>
                  <Label>Internship</Label>
                  <Select
                    options={internshipOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "internship" })
                    }}
                    value={selectedFields.internship || ""}
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "candidate" })
                    }}
                    value={selectedFields.candidate || ""}
                    isMulti
                    master={filterObject}
                    api="internship/applications/options/candidate"
                  />
                </Col>
                <Col md={2}>
                  <Label>Type</Label>
                  <Select
                    options={typeOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "type" })
                    }}
                    value={selectedFields.type || ""}
                    isMulti
                  />
                </Col>
                <Col className="form-button">
                  <Button onClick={reset} color="danger" type="button">
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="internshipShortlistTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>

      <Details
        id={viewModal.id}
        isOpen={viewModal.show}
        onToggle={() => handleViewModal()}
      />
      <Reject
        id={modal.id}
        isOpen={modal.show}
        onToggle={() => handleToggle({})}
        status={modal.status}
      />
    </div>
  )
}

export default InternshipShortListed
