import { Tooltip } from "@mui/material"
import React from "react"
import { Card, CardBody, Row, Col, Input } from "reactstrap"
import Avatar from "@mui/material/Avatar"
import { Box, Divider, IconButton } from "@mui/material"

const CandidateCard = ({
  id,
  name,
  uniqueId,
  mobile,
  onCheckboxChange,
  checked,
  index,
  screening,
  isGroomed

}) => {
  const handleCheckboxChange = e => {
    e.stopPropagation()
    onCheckboxChange({ id, index, checked: !checked })
  }



  const handleCardClick = () => {
    onCheckboxChange({ id, index, checked: !checked })
  }
  return (
    <Card
      className={`candidate-card ${checked ? "bg-light-blue" : ""}`}
      onClick={handleCardClick}
    >
      <CardBody>
        <Row>
          <Col xs="auto" className="checkbox-col">
            <Input
              type="checkbox"
              name={id}
              checked={checked}
              onChange={handleCheckboxChange}
              onClick={e => e.stopPropagation()}
              style={{ margin: 0 }}
            />
          </Col>

          <Col className="candidate-info">
            <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h6>
                <Tooltip title={name} arrow placement="top">
                  {name || ""}
                </Tooltip>
              </h6>
              {isGroomed?(
                <>
                 <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src="/logo-person.png"
                  alt="Logo 3"
                  style={{
                    width: "28px",
                    height: "28px",
                    cursor: "pointer",
                    border: "1px solid #e0e0e0",
                    padding: "4px",
                    borderRadius: "50%",
                    boxSizing: "border-box",
                    objectFit: "contain",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <IconButton style={{ padding: "2px" }} />
              </div>
                </>

              ):null}
             

            </div>


            <span className="cgpa">
              <b>Id :</b>
              {uniqueId}
            </span>
            <br />
            <span className="cgpa">
              <b>Mobile :</b>
              {mobile}
            </span>
            <br />
            <span className="cgpa">
              <b>percentage :</b>
              {screening?.percentage}%
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default CandidateCard
