import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { Col, Row } from "reactstrap";
import { Modal } from "react-bootstrap";
import "./style.scss";
import { get } from "../../../helpers/api_helper";
import { API_URL } from "../../../config";

const JobApplied = ({ candidateId }) => {
  const [studentInternship, setStudentInternship] = useState([]);
  const [popupView, setPopupView] = useState(false); 
  const [modalData, setModalData] = useState(null); 

  const statusMap = {
    0: "Applied",
    1: "Deleted",
    2: "Shortlisted",
    3: "Placed",
    4: "Rejected from Application",
    5: "Rejected from Shortlist",
    6:"WithDrawn",
    7:"WithDraw Pending",
    8:"WithDraw Rejected",
    9:"Completed",
    10:"Pending"
  };

  const Internshiptype = {
    1: "KTU",
    2: "General",
    3: "Public Sector",
  };

  useEffect(() => {
    handleTableData();
  }, [candidateId]);


  function handleTableData() {
    get(`candidate/internship?candidate=${candidateId}`)
      .then(res => {
        const result = res?.data || [];
        console.log("Fetched data:", result);
        setStudentInternship(result);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  const rows = studentInternship.map((application, index) => (
    {
    id: index + 1,
    date: application.applications[0]?.date ? moment(application.applications[0]?.date).format("DD-MM-YYYY"): "",
    type: Internshiptype[application.applications[0]?.internship?.type] || "",
    uniqueId: application.applications[0]?.internship?.uniqueId,
    name: application.applications[0]?.internship?.name,
    status1: statusMap[application.applications[0]?.status] || "",
    applied: application.applications[0]?.date ? moment(application.applications[0]?.date).format("DD-MM-YYYY") : "-",
    shortlisted: application.shortlisted?.date ? moment(application.shortlisted?.date).format("DD-MM-YYYY") : "-",
    placed:application.placed?.date ? moment(application.placed?.date).format("DD-MM-YYYY") : "-",
    completed: application.completed?.date ? moment(application.completed?.date).format("DD-MM-YYYY") : "-",
    rejected: application.rejected?.date ? moment(application.rejected?.date).format("DD-MM-YYYY") : "-",
    withdraw: application.withdrawn?.date ? moment(application.withdrawn?.date).format("DD-MM-YYYY") : "-",
    options: (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <i
          className="fas fa-eye"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.5rem",
          }}
          onClick={() => {
            setPopupView(true); 
            setModalData(application); 
          }}
        ></i>
        {application?.offerLetter?.company ? (
          <Tooltip title="Offer Letter" arrow placement="top">
            <a
              href={`${API_URL}${application.offerLetter?.company}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-file-pdf pdf-icon" />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
        {application?.offerLetter?.asap ? (
          <Tooltip title="ASAP Offer Letter" arrow placement="top">
            <a
              href={`${API_URL}${application.offerLetter?.asap}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fas fa-file-download asap-offer-letter-icon"
                style={{ color: "gray" }}
              />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
        {application?.completion?.file?  (
          <Tooltip title="Completion Letter" arrow placement="top">
            <a
              href={`${API_URL}${application?.completion?.file}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-solid fa-file-circle-check completion-letter-icon"
                style={{ color: "yellow" }}
              />
            </a>
          </Tooltip>
        ) : (
          ""
        )}

{application?.report?.file?  (
          <Tooltip title="Completion Report" arrow placement="top">
            <a
              href={`${API_URL}${application?.report?.file}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-solid fa-file-arrow-down report-icon"
                // style={{ color: "yellow" }}
              />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    ),
  }));

  const jobPosts = {
    columns: [
      { label: "#", field: "id", sort: "asc", width: 150 },
      { label: "Date", field: "date", sort: "asc", width: 400 },
      { label: "Type", field: "type", sort: "asc", width: 400 },
      { label: "ID", field: "uniqueId", sort: "asc", width: 400 },
      { label: "Name", field: "name", sort: "asc", width: 400 },
      { label: "Current Status", field: "status1", sort: "asc", width: 200 },
      { label: "Applied", field: "applied", sort: "asc", width: 200 },
      { label: "Shortlisted", field: "shortlisted", sort: "asc", width: 200 },
      { label: "Placed", field: "placed", sort: "asc", width: 200 },
      { label: "Completed", field: "completed", sort: "asc", width: 200 },
      { label: "Rejected", field: "rejected", sort: "asc", width: 200 },
      { label: "Withdraw", field: "withdraw", sort: "asc", width: 200 },
      { label: "Action", field: "options", sort: "asc", width: 200 },
    ],
    rows: rows,
  };

  const closeModal = () => {
    setPopupView(false); 
    setModalData(null); 
  };

  return (
    <>
      <Modal
        show={popupView} 
        onHide={closeModal} 
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Internship Details</h5>
          </div>
          <button
            type="button"
            onClick={closeModal} 
            className="close"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
  {modalData && (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
       
        <div style={{ flex: 1 }}>
          {/* <h4 style={{ marginBottom: "15px", color: "#333", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>
            Internship Details
          </h4> */}
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Date:</strong>{" "}
            {modalData.applications[0]?.date
              ? moment(modalData.applications[0]?.date).format("DD-MM-YYYY")
              : "N/A"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>ID:</strong> {modalData.applications[0]?.internship?.uniqueId || "--"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Name:</strong> {modalData.applications[0]?.internship?.name || "--"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Type:</strong>{" "}
            {Internshiptype[modalData.applications[0]?.internship?.type] || "--"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Status:</strong>{" "}
            {statusMap[modalData.applications[0]?.status] || "--"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Stipend:</strong> {modalData.applications[0]?.internship?.stipend || "--"}
          </p>
        </div>

     
        <div style={{ flex: 1 }}>
          {/* <h4 style={{ marginBottom: "15px", color: "#333", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>
            Application Progress
          </h4> */}
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Applied:</strong> {modalData.applications[0]?.date
              ? moment(modalData.applications[0]?.date).format("DD-MM-YYYY")
              : ""}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Shortlisted:</strong> {modalData.shortlisted?.date?moment(modalData.shortlisted?.date).format("DD-MM-YYYY") :"--"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Placed:</strong> {modalData.placed?.date ? moment(modalData.placed?.date).format("DD-MM-YYYY") : "--"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Completed:</strong> {modalData.completed?.date ?moment(modalData.completed?.date).format("DD-MM-YYYY") : "--"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Rejected:</strong> {modalData.rejected?.date ?moment(modalData.rejected?.date).format("DD-MM-YYYY") : "--"}
          </p>
          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
            <strong style={{ color: "#222" }}>Withdraw:</strong> {modalData.withdraw?.date ?moment(modalData.withdraw?.date).format("DD-MM-YYYY") : "--"}
          </p>
        </div>
      </div>
    </div>
  )}
</div>


      </Modal>

      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="shortlistedJobDataId"
            className="job-applied"
            data={jobPosts}
            responsive
            bordered
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default JobApplied;
