import React, { useState, useEffect } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Select from "react-select"
import queryString from "query-string"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import toastr from "toastr"

import {
  interviewStatusOptions,
  screeningStatusOptions,
  smeStatusOptions,
  statusOptions,
  typeOptions,
} from "./helper"

import "./styles.scss"
import { get, put } from "../../../helpers/api_helper"
import SearchSelect from "../../../components/Common/SearchSelect"
import { dateConverter } from "../../../helpers/functions"
const AssessmentReport = () => {
  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [companyOptions, setCompanyOptions] = useState([])
  const [internshipOptions, setInternshipOptions] = useState([])

 

  useEffect(() => {
    handleCompanyOptions()
    handleJobOptions()
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    getAlltabledata()
  }, [filterObject,page]) //eslint-disable-line

  const handleCompanyOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `job/applications/options/company?${params}`,
      )
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `job/applications/options/job?${params}`,
      )
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value: value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Id", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Internship", field: "job" },
      // { label: "Type", field: "type" },
      { label: "Candidate", field: "candidatename" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Status", field: "status" },
      { label: "Screening", field: "screening" },
      { label: "Interview", field: "interview" },
      { label: "SME", field: "sme" },
      { label: "Action", field: "option" },
    ],
    rows: tableData,
  }

  function resetAll() {
    setPage(1)
    setFilterObject({})
    setSelectedFields({})
  }


  function getAlltabledata(obj = filterObject) {
    const params = queryString.stringify(obj)
    get(`assessment/report/job?page=${page}&limit=${limit}&${params}`).then((res) => {
      const { data, count } = res
      data.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.date = dateConverter(item.date)
        item.candidatename = item.candidate.name
        item.candidateId = item.candidate.uniqueId
        // const typeName = typeOptions.find(option => option.value === item.type);
        const statusName = statusOptions.find(option => option.value === item.status);
        const screeningName = screeningStatusOptions.find(option => option.value === item.screening)
        const interviewName = interviewStatusOptions.find(option => option.value === item.interview)
        const smeNmae = smeStatusOptions.find(option => option.value === item.sme)
        item.status = statusName ? statusName.label : "---"
        // item.type = typeName ? typeName.label : "---"
        item.screening = screeningStatusOptions ? screeningName.label : "---"
        item.interview = interviewName ? interviewName.label : "---"
        item.sme = smeNmae ? smeNmae.label : "---"
        if (item?.forwarded?.status === 1) {
          item.option = (
            <div  className="d-flex justify-content-center">
              <i onClick={()=>forwarddApplication(item._id)} className="fas fa-share share-icon" />
            </div>
          )

        }
        return item

      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    })
  }


  const handlePageChange = value => {
    setPage(value)
  }


  function forwarddApplication(id){
    put(`job/applications/forward`,{id:id}).then((res)=>{
      toastr.success(res.message)
      getAlltabledata()
    }).catch((err)=>{
      toastr.error(err.response.data.message)
    })
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Assessment Report - Job" />
        <Card>
          <CardBody>
            <Row>
              {/* <Col md={2}>
                <Label>Type</Label>
                <Select
                  options={typeOptions}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "type" })
                  }}
                  value={selectedFields.type || ""}
                // isMulti
                />
              </Col> */}
              <Col md={3}>
                <Label>Company</Label>
                <Select
                  options={companyOptions}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "company" })
                  }}
                  value={selectedFields.company || ""}
                  isClearable
                />
              </Col>
              <Col md={3}>
                <Label>Job</Label>
                <Select
                  options={internshipOptions}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "job" })
                  }}
                  value={selectedFields.job || ""}
                  isClearable
                />
              </Col>
              <Col md={3}>
                <Label>Candidate</Label>
                <SearchSelect
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "candidate" })
                  }}
                  value={selectedFields.candidate || ""}
                  isMulti
                  master={filterObject}
                  api="job/applications/options/candidate"
                />
              </Col>

              <Col md={2}>
                <Label>Status</Label>
                <Select
                  name="status"
                  options={statusOptions}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "status" })
                  }}
                  value={selectedFields.status || ""}
                  isClearable
                />
              </Col>
              <Col md={2} className="mb-3">
                <Label>Screening</Label>
                <Select options={screeningStatusOptions}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "screening" })
                  }}
                  value={selectedFields.screening || ""}
                />
              </Col>
              <Col md={2} className="mb-3">
                <Label>Interview</Label>
                <Select options={interviewStatusOptions}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "interview" })
                  }}
                  value={selectedFields.interview || ""}

                />
              </Col>
              <Col md={2} className="mb-3">
                <Label>SME</Label>
                <Select options={smeStatusOptions}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "sme" })
                  }}
                  value={selectedFields.sme || ""}

                />
              </Col>
              <Col>
                <div className="d-flex gap-2 form-button">
                  <Button onClick={resetAll} color="danger">
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <TablePagination
              page={page}
              data={data}
              count={totalPage}
              onChange={handlePageChange}
              tableId="assessmentReport"
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AssessmentReport
