import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"
import { Tooltip } from "@mui/material"

import TablePagination from "../../../../components/Common/TablePagination"
import SearchField from "../../../../components/Common/SearchField"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import Icon from "../../../../components/Common/Icon"

import { del, get, post, put } from "../../../../helpers/api_helper"
import {
  dateConverter,
  numberToString,
  renderTableValue,
  toTop,
} from "../../../../helpers/functions"
import { typeOptions, TYPES } from "../helper"

import "./styles.scss"

const AddSME = () => {
  const formRef = useRef()
  const filterRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [filterObject, setFilterObject] = useState({})
  const [selectedFilter, setSelectedFilter] = useState({})

  const [internshipOptions, setInternshipOptions] = useState([])
  const [jobOptions, setJobOptions] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  const handleInternshipOptions = async () => {
    try {
      const response = await get(`options/internship`)
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleJobOptions = async () => {
    try {
      const response = await get(`options/job`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}`
      const response = await get(`sme${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        if (item.type === TYPES.INTERNSHIP)
          item.internshipOrJob = item.internship?.name
        else item.internshipOrJob = item.job?.name

        item.type = renderTableValue(TYPES[item.type])
        item.internshipOrJob = renderTableValue(item.internshipOrJob)

        item.staff = renderTableValue(item?.addedBy?.name)
        item.name = renderTableValue(item?.name)
        item.description = renderTableValue(item?.description)

        item.totalMark = numberToString(item.totalMark)

        item.options = (
          <div className="d-flex justify-content-center align-items-center">
            <Icon
              icon="edit"
              title="Edit"
              onClick={() => handleUpdate(item._id)}
            />
            <Icon
              icon="delete"
              title="Delete"
              onClick={() => handleDelete(item._id)}
            />
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`sme`, masterObject)
      else response = await post(`sme`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`sme/update/${id}`)
      const { data } = response

      setMasterObject({
        ...data,
        id,
        job: data?.job?.value,
        internship: data?.internship?.value,
      })
      setSelectedFields({
        ...data,
        type: { label: TYPES[data.type], value: data.type },
      })
      toTop()

      if (data.type === TYPES.JOB && !jobOptions.length) handleJobOptions()
      else if (!internshipOptions.length) handleInternshipOptions()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`sme/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response?.data?.message)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value

    if (name === "type") {
      if (value === TYPES.JOB && !jobOptions.length) handleJobOptions()
      else if (!internshipOptions.length) handleInternshipOptions()
    }
    if (
      name === "technicalCompetence" ||
      name === "problemSolving" ||
      name === "communication" ||
      name === "presentation" ||
      name === "teamWork"
    ) {
      obj.totalMark =
        Number(obj.technicalCompetence || 0) +
        Number(obj.problemSolving || 0) +
        Number(obj.communication || 0) +
        Number(obj.presentation || 0) +
        Number(obj.teamWork || 0)
    }
    setMasterObject(obj)
  }

  const handleFilterSelectValueChange = async ({ selected, name }) => {
    setSelectedFilter(prev => ({ ...prev, [name]: selected }))
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    toTop()
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    filterRef.current?.reset()

    setMasterObject({})
    setSelectedFields({})

    setFilterObject({})
    setSelectedFilter({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      // { label: "Date", field: "date" },
      { label: "Type", field: "type" },
      { label: "Internship/Job Name", field: "internshipOrJob" },
      { label: "Name", field: "name" },

      {
              label: (
                <Tooltip title="Communication Skills" arrow placement="top">
                  Comm.
                </Tooltip>
              ),
              field: "communication",
            },
            {
              label: (
                <Tooltip title="Technical Competence" arrow placement="top">
                  Tech.
                </Tooltip>
              ),
              field: "technicalCompetence",
            },
            {
              label: (
                <Tooltip title="Problem-Solving Skills" arrow placement="top">
                  Solve.
                </Tooltip>
              ),
              field: "problemSolving",
            },
            {
              label: (
                <Tooltip title="Teamwork Skills" arrow placement="top">
                  Team.
                </Tooltip>
              ),
              field: "teamWork",
            },
            {
              label: (
                <Tooltip title="Presentation Skills" arrow placement="top">
                  Pres.
                </Tooltip>
              ),
              field: "presentation",
            },


    
      { label: "Total Mark", field: "totalMark" },



      { label: "Description", field: "description" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="SME" />

        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md={3}>
                  <Label>Type</Label>
                  <Select
                    menuPosition="fixed"
                    options={typeOptions}
                    value={selectedFields.type || ""}
                    onChange={selected =>
                      handleSelectValueChange({ name: "type", selected })
                    }
                  />
                </Col>

                {masterObject.type === TYPES.INTERNSHIP && (
                  <Col md={3}>
                    <Label> Internship</Label>
                    <Select
                      menuPosition="fixed"
                      options={internshipOptions}
                      value={selectedFields.internship || ""}
                      onChange={selected =>
                        handleSelectValueChange({
                          name: "internship",
                          selected,
                        })
                      }
                    />
                  </Col>
                )}

                {masterObject.type === TYPES.JOB && (
                  <Col md={3}>
                    <Label>Job</Label>
                    <Select
                      menuPosition="fixed"
                      options={jobOptions}
                      value={selectedFields.job || ""}
                      onChange={selected =>
                        handleSelectValueChange({ name: "job", selected })
                      }
                    />
                  </Col>
                )}

                <Col md={3}>
                  <Label>Name</Label>
                  <AvField
                    name="name"
                    placeholder="Name"
                    errorMessage="Enter name"
                    value={masterObject.name || ""}
                    validate={{ required: { value: true } }}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md={2}>
                  <Label>Communication</Label>
                  <AvField
                    type="number"
                    name="communication"
                    placeholder="communication"
                    errorMessage="Enter Communication"
                    value={masterObject.communication || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Technical Competence</Label>
                  <AvField
                    type="number"
                    name="technicalCompetence"
                    placeholder="Technical Competence"
                    errorMessage="Enter Technical Competence"
                    value={masterObject.technicalCompetence || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md={2}>
                  <Label>Problem Solving </Label>
                  <AvField
                    type="number"
                    name="problemSolving"
                    placeholder="Problem Solving"
                    errorMessage="Enter Problem Solving"
                    value={masterObject.problemSolving || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Team Work</Label>
                  <AvField
                    type="number"
                    name="teamWork"
                    placeholder="Team Work "
                    errorMessage="Enter Team Work "
                    value={masterObject.teamWork || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Presentation</Label>
                  <AvField
                    type="number"
                    name="presentation"
                    placeholder="Presentation"
                    errorMessage="Enter Presentation"
                    value={masterObject.presentation || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md={2}>
                  <Label>Total Mark</Label>
                  <AvField
                    type="number"
                    disabled
                    name="totalMark"
                    placeholder="Total Mark"
                    value={masterObject.totalMark || ""}
                  // onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md={3}>
                  <Label>Description</Label>
                  <AvField
                    type="textarea"
                    name="description"
                    placeholder="description"
                    value={masterObject.description || ""}
                    onChange={e => handleValueChange(e.target)}
                    rows={1}
                  />
                </Col>
                <Col md={3}>
                  <div className="d-flex gap-2 form-button">
                    <Button
                      className="me-2"
                      color={masterObject.id ? "warning" : "primary"}
                      type="submit"
                    >
                      {masterObject.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" type="button" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={3}>
                  <Label>Type</Label>
                  <Select
                    menuPosition="fixed"
                    options={typeOptions}
                    value={selectedFilter.type || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange({ name: "type", selected })
                    }
                  />
                </Col>

                <Col md={3}>
                  <Label>Search</Label>
                  <SearchField
                    name="search"
                    value={filterObject.search || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    placeholder="Search name"
                  />
                </Col>

                <Col md={3} className="form-button">
                  <Button type="button" color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="smeTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AddSME
