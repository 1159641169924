export const STATUS = { 0: "Active", 2: "In Active", 4: "Rejected" }

export const OFFER_LETTER = {
  0: "Not Given",
  1: "Shared",
  2: "Accepted",
  3: "Rejected",
}

export const APPLICATION_STATUS = {
  0: { text: "Applied", color: "primary" },
  2: { text: "Shortlisted", color: "warning" },
  3: { text: "Placed", color: "success" },
  4: { text: "Rejected", color: "danger" },
  5: { text: "Rejected", color: "danger" },
  6: { text: "Withdrawn", color: "danger" },
  7: { text: "Withdrawn Pending", color: "warning" },
  9: { text: "Completed", color: "info" },
  10: { text: "Pending", color: "primary" },
  REJECTED_FROM_APPLICATION: 4,
  REJECTED_FROM_SHORTLIST: 5,
}

export const statusOptions = [
  { label: "Applied", value: 0 },
  { label: "Shortlist", value: 2 },
  { label: "Placed", value: 3 },
  { label: "Rejected", value: 4 },
  { label: "Withdrawn", value: 6 },
  { label: "Withdrawn Pending", value: 7 },
  { label: "Completed", value: 9 },
]
