import React, { useState, useRef, useEffect, useTransition } from "react"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import toastr from "toastr"
import Swal from "sweetalert2"
import moment from "moment"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { del, get, post, put } from "../../helpers/api_helper"
import Breadcrumb from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import Icon from "../../components/Common/Icon"
import { dateConverter, toTop } from "../../helpers/functions"
import "./Feedabackform.scss"
import { ratingOptions, AssignedForOptions, assignedForValues, categoryOptions, categoryValues, statgeOptions, stageValues } from "./helpers"
import queryString from "query-string"
import cryingface from "../../assets/fotos/cryingface.svg"
import confusedface from "../../assets/fotos/confusedface.svg"
import Neutralface from "../../assets/fotos/neutralface.svg"
import slightlysmile from "../../assets/fotos/slightlysmileface.svg"
import grinningface from "../../assets/fotos/grinningface.svg"

const FeedbackLog = () => {
    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [tableData, setTableData] = useState([])
    const [masterObj, setmasterObj] = useState()
    const [selected, setSelected] = useState()

    const [jobFairOptions, setjobFairOptions] = useState([])
    const [jobOptions, setjobOptions] = useState([])
    const [internshipOptions, setinternshipOptions] = useState([])
    const [formOptions, setformOptions] = useState([])
    const [companyOptions, setcompanyOptions] = useState([])


    const [popupView, setPopupView] = useState(false)
    const handleCloseModal = () => setPopupView(false)
    const [popUpData, setpopUpData] = useState([])

    useEffect(() => {
        getAllJobfiarOptions()
        getAllJobOptions()
        getAllInternshipOptions()
        getAllformOptions()
        getAllcompanyOptions()
    }, [masterObj])

    function getAllJobfiarOptions() {
        const query = queryString.stringify(masterObj)
        get(`feedback/options/jobFair?${query}`).then((res) => {
            setjobFairOptions(res.data)
        }).catch((err) => {
        })
    }
    function getAllJobOptions() {
        const query = queryString.stringify(masterObj)
        get(`feedback/options/job?${query}`).then((res) => {
            setjobOptions(res.data)
        }).catch((err) => {
        })
    }
    function getAllInternshipOptions() {
        const query = queryString.stringify(masterObj)
        get(`feedback/options/internship?${query}`).then((res) => {
            setinternshipOptions(res.data)
        }).catch((err) => {
        })
    }
    function getAllformOptions() {
        const query = queryString.stringify(masterObj)
        get(`feedback/options/form?${query}`).then((res) => {
            setformOptions(res.data)
        }).catch((err) => {
        })
    }

    function getAllcompanyOptions() {
        const query = queryString.stringify(masterObj)
        get(`feedback/options/company?${query}`).then((res) => {
            setcompanyOptions(res.data)
        }).catch((err) => {
        })
    }

    function resetAllFilter() {
        setmasterObj({})
        setSelected({})
    }
    const handlePageChange = value => {
        setPage(value)
    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "Type", field: "type" },
            { label: "Assign", field: "assign" },
            { label: "Category", field: "category" },
            { label: "Form", field: "form1" },
            { label: "Name", field: "name" },
            { label: "Mobile", field: "mobile" },
            { label: "Email", field: "email" },
            // { label: "Status", field: "status" },
            { label: "Action", field: "options" },
        ],
        rows: tableData,
    }

    useEffect(() => {
        handleTableData()
    }, [masterObj])


    function handleTableData() {
        const query = queryString.stringify(masterObj)
        get(`feedback?${query}`).then((res) => {
            const { count, data } = res+
            data.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = dateConverter(item.date)
                item.type = item?.type === 1 ? "Static" : item?.type === 2 ? "Dynamic" : "---"
                item.assign = assignedForValues[item.assignFor]
                item.category = categoryValues[item.category]
                item.form1 = item.form.name

                item.options = (
                    <div>
                        {/* <Icon
                            icon="copy"
                            title={``}
                            hidden={item?.type === 2 ? true : false}
                            additionalClass="copy-icon"
                            onClick={() => handleCopy(item.permalink)}
                        /> */}
                        <Icon
                            icon="eye"
                            title="View"
                            onClick={() => handlePopupView(item._id)}
                        />

                    </div>
                )

                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTableData(data)
        })
    }
    function handleCopy(link) {
        const baseUrl = "https://sda.srvinfotech.com/feedback/fbs/";
        const fullUrl = `${baseUrl}${link}`;
        navigator.clipboard
            .writeText(fullUrl)
            .then(() => {
                toastr.success("Link copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    }


    function handlePopupView(id) {
        get(`feedback/details?id=${id}`).then((res) => {
            const data = res.data
            setpopUpData(data)
        })
        setPopupView(true)
    }

    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Feedback Log" />
                    <Card>
                        <CardBody>
                            <AvForm>
                                <Row>
                                    <Col md="2">
                                        <div className="mb-3">
                                            <Label>Type</Label>
                                            <Select
                                                options={[{ label: "Static", value: 1 }, { label: "Dynamic", value: 2 }]}
                                                classNamePrefix="select2-selection"
                                                onChange={item => {
                                                    setmasterObj({
                                                        ...masterObj,
                                                        type: item?.value,
                                                    })
                                                    setSelected({ ...selected, type: item })
                                                }}
                                                value={selected?.type || null}
                                                placeholder="Type"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="mb-3">
                                            <Label>Assigned For</Label>
                                            <Select
                                                options={AssignedForOptions}
                                                classNamePrefix="select2-selection"
                                                onChange={item => {
                                                    setmasterObj({
                                                        ...masterObj,
                                                        assignFor: item?.value,
                                                    })
                                                    setSelected({ ...selected, assignFor: item })
                                                }}
                                                value={selected?.assignFor || null}
                                                placeholder="Assigned For"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label>Category</Label>
                                            <Select
                                                options={categoryOptions}
                                                classNamePrefix="select2-selection"
                                                onChange={item => {
                                                    setmasterObj({
                                                        ...masterObj,
                                                        category: item?.value,
                                                    })
                                                    setSelected({ ...selected, category: item })
                                                }}
                                                value={selected?.category || null}
                                                placeholder="Type"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="mb-3">
                                            <Label>Stage</Label>
                                            <Select
                                                options={statgeOptions}
                                                classNamePrefix="select2-selection"
                                                onChange={item => {
                                                    setmasterObj({
                                                        ...masterObj,
                                                        stage: item?.value,
                                                    })
                                                    setSelected({ ...selected, stage: item })
                                                }}
                                                value={selected?.stage || null}
                                                placeholder="Stage"
                                            />
                                        </div>
                                    </Col>
                                    {selected?.category?.value === 1 ? (
                                        <>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label>Internship</Label>
                                                    <Select
                                                        classNamePrefix="select2-selection"
                                                        onChange={item => {
                                                            setmasterObj({
                                                                ...masterObj,
                                                                internship: item?.value,
                                                            })
                                                            setSelected({ ...selected, internship: item })
                                                        }}
                                                        value={selected?.internship || null}
                                                        placeholder="Internship"
                                                        options={internshipOptions}

                                                    />
                                                </div>
                                            </Col>

                                        </>
                                    ) : null}
                                    {selected?.category?.value === 2 ? (
                                        <>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label>Job</Label>
                                                    <Select
                                                        classNamePrefix="select2-selection"
                                                        onChange={item => {
                                                            setmasterObj({
                                                                ...masterObj,
                                                                job: item?.value,
                                                            })
                                                            setSelected({ ...selected, job: item })
                                                        }}
                                                        value={selected?.job || null}
                                                        placeholder="Job"
                                                        options={jobOptions}
                                                    />
                                                </div>
                                            </Col>

                                        </>
                                    ) : null}

                                    {selected?.category?.value === 3 ? (
                                        <>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label>Job Fair</Label>
                                                    <Select
                                                        classNamePrefix="select2-selection"
                                                        options={jobFairOptions}
                                                        onChange={item => {
                                                            setmasterObj({
                                                                ...masterObj,
                                                                jobFair: item?.value,
                                                            })
                                                            setSelected({ ...selected, jobFair: item })
                                                        }}
                                                        value={selected?.jobFair || null}
                                                        placeholder="job Fair"
                                                    />
                                                </div>
                                            </Col>

                                        </>
                                    ) : null}

                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label>Form</Label>
                                            <Select
                                                classNamePrefix="select2-selection"
                                                options={formOptions}
                                                onChange={item => {
                                                    setmasterObj({
                                                        ...masterObj,
                                                        form: item?.value,
                                                    })
                                                    setSelected({ ...selected, form: item })
                                                }}
                                                value={selected?.form || null}
                                                placeholder="Form"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label>Company</Label>
                                            <Select
                                                classNamePrefix="select2-selection"
                                                options={companyOptions}
                                                onChange={item => {
                                                    setmasterObj({
                                                        ...masterObj,
                                                        company: item?.value,
                                                    })
                                                    setSelected({ ...selected, company: item })
                                                }}
                                                value={selected?.company || null}
                                                placeholder="Company"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="1">
                                        <Button onClick={resetAllFilter} className="mt-4" type="button" color="danger">Reset</Button>
                                    </Col>

                                </Row>

                            </AvForm>
                            <Row>
                                <TablePagination
                                    page={page}
                                    // tableId="assignTest"
                                    data={data}
                                    count={totalPage}
                                    onChange={handlePageChange}
                                />

                            </Row>

                        </CardBody>
                    </Card>


                </div>
            </div>
            <Modal
                show={popupView}
                isOpen={popupView}
                toggle={() => setPopupView(!popupView)}
                size="lg"
                centered={true}
                className="lead-modal"
            >
                <div className="modal-header">
                    <div className="modal-title">
                        <h6 style={{ marginBottom: "0px" }} className="modal-title ">
                            <h6 className="mx-2 my-2">Feedback Log Details</h6>
                        </h6>
                    </div>
                    <button
                        style={{ marginBottom: "2px" }}
                        type="button"
                        onClick={handleCloseModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <Col md="12">
                        <ul className="feedback-details-modal">
                            {/* <li>
                                            Date:<Label>{popUpData?.date}</Label>
                                        </li> */}
                            <li>
                                Form: <Label>{popUpData?.form?.name}</Label>
                            </li>
                            <li>
                                Created By: <Label>{popUpData?.addedBy?.name}</Label>
                            </li>
                            <li>
                                Type : <Label>{popUpData?.type === 1 ? "Static" : popUpData?.type === 2 ? "Dynamic" : "---"}</Label>
                            </li>
                            <li>
                                Assign For : <Label>{assignedForValues[popUpData.assignFor]}</Label>
                            </li>
                            <li>
                                Category : <Label>{categoryValues[popUpData?.category]}</Label>
                            </li>
                            <li>
                                {popUpData?.internship ? "Internship" : popUpData?.job ? "Job" : popUpData?.jobFair ? "JobFair" : "Internship/job/job Fair"} : <Label>{popUpData?.internship ? popUpData.internship.name : popUpData?.job ? popUpData.job.name : popUpData?.jobFair ? popUpData.jobFair.name : "---"}</Label>
                            </li>
                            <li>
                                Stage : <Label>{popUpData?.stage ? stageValues[popUpData.stage] : "--"}</Label>
                            </li>
                            <li>
                                Name : <Label>{popUpData?.name}</Label>
                            </li>
                            <li>
                                Mobile : <Label>{popUpData?.mobile}</Label>
                            </li>
                            <li>
                                Email : <Label>{popUpData?.email}</Label>
                            </li>
                        </ul>
                    </Col>
                    <div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ width: "120px", textAlign: "left" }}>
                                        Question
                                    </th>
                                    <th style={{ width: "320px", textAlign: "left" }}>Type</th>
                                    <th style={{ width: "320px", textAlign: "left" }}>Answer</th>

                                </tr>
                            </thead>
                            <tbody>
                                {popUpData &&
                                    popUpData?.questions?.map((item, index) => (
                                        <tr>
                                            <td style={{ textAlign: "left" }}>{item.question}</td>
                                            <td style={{ textAlign: "left" }}>
                                                {item.type == 1
                                                    ? "Yes/No"
                                                    : item?.type == 2
                                                        ? "Rating"
                                                        : item.type == 3
                                                            ? "Emoji Rating"
                                                            : ""}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                {item.type == 2 && item.answer == 1
                                                    ? "⭐"
                                                    : item.type == 2 && item.answer == 2
                                                        ? "⭐⭐"
                                                        : item.type == 2 && item.answer == 3
                                                            ? "⭐⭐⭐"
                                                            : item.type == 2 && item.answer == 4
                                                                ? "⭐ ⭐ ⭐ ⭐"
                                                                : item.type == 2 && item.answer == 5
                                                                    ? "⭐ ⭐ ⭐ ⭐ ⭐"
                                                                    : item.type == 1 && item.answer == 1
                                                                        ? "Yes"
                                                                        : item.type == 1 && item.answer == 2
                                                                            ? "No"
                                                                            : item.type == 3 && item.answer == 5
                                                                                ? <img
                                                                                    src={grinningface}
                                                                                    alt="Grinning face"
                                                                                />
                                                                                : item.type == 3 && item.answer == 4
                                                                                    ? <img
                                                                                        src={slightlysmile}
                                                                                        alt="Grinning face"
                                                                                    />
                                                                                    : item.type == 3 && item.answer == 3
                                                                                        ? <img
                                                                                            src={Neutralface}
                                                                                            alt="Grinning face"
                                                                                        />
                                                                                        : item.type == 3 && item.answer == 2
                                                                                            ? <img
                                                                                                src={confusedface}
                                                                                                alt="Grinning face"
                                                                                            />
                                                                                            : item.type == 3 && item.answer == 1
                                                                                                ? <img
                                                                                                    src={cryingface}
                                                                                                    alt="Grinning face"
                                                                                                /> : "---"

                                                }
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default FeedbackLog
