import React, { useEffect, useState } from "react"
import {
  Row,
  Col, BreadcrumbItem, FormGroup
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Box, Divider } from "@mui/material";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Modal } from "react-bootstrap"
import Select from "react-select"
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MiniWidget from './mini-widget'
import { useParams } from 'react-router-dom';
import { get, put } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"
import moment from "moment";
import TablePagination from "../../../components/Common/TablePagination"
import toastr from "toastr"
import { renderTableValue} from "../../../helpers/functions"//eslint-disable-line



const ViewCompany = (props) => {

  const navigate = useNavigate()
  const { recruiterId } = useParams()//eslint-disable-line
  const { companyId } = useParams()
  const [companyDetails, setCompanyDetails] = useState(null)
  const [summary, setSummary] = useState(null)
  const [jobPost, setJobPost] = useState([])
  const [internships, setInternships] = useState([])
  const [jobFair, setJobFair] = useState([])
  const [modalData] = useState(null)
  const [modal, setModal] = useState(false)
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [popupView4, setPopupView4] = useState(false)

  const [tab3, setTab3] = React.useState("1");

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 18


  const [totalPage2, setTotalPage2] = useState(0)
  const [page2, setPage2] = useState(1)

  const [state, setState] = useState(false)

  const status = [
    { label: "Ongoing", value: 0 },
    { label: "Inactive", value: 2 },
    { label: "Expired", value: 3 },
    { label: "Filled", value: 4 },
  ]


  useEffect(() => {
    fetchCompanyDetails()
  }, []) //eslint-disable-line

  useEffect(() => {
    fetchSummary()
    fetchInternship()
  }, [])//eslint-disable-line

  useEffect(() => {
    fetchjobPost()
  }, [page, state])//eslint-disable-line

  useEffect(() => {
    fetchjobFair()
  }, [page, state])//eslint-disable-line

  useEffect(() => {
    let status = -1
    if (tab3 === 1) {
      status = 0
    } else if (tab3 === 3) {
      status = 2
    } else if (tab3 === 4) {
      status = 3
    }
    if (status >= 0) {
      fetchInternship(status)
    }
  }, [tab3, page2])//eslint-disable-line

  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleChange2 = (event, value) => {
    setPage2(value)
  }


  const fetchCompanyDetails = async () => {
    try {
      const response = await get(`/company/company-details?companyId=${companyId}&type=1`)
      console.log("response", response)
      setCompanyDetails(response?.data[0])
    } catch (error) {
    }
  }

  const fetchSummary = async () => {
    try {
      const response = await get(`/company/company-details?companyId=${companyId}&type=3`)
      setSummary(response?.data)
    } catch (error) {

    }
  }

  const fetchjobPost = async () => {
    try {
      const response = await get(`/company/company-details?companyId=${companyId}&type=4&page=${page}&limit=${limit}`)
      setJobPost(response?.data)
      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {

    }
  }

  
  const fetchjobFair = async () => {
    try {
      const response = await get(`/company/company-details?companyId=${companyId}&type=5&page=${page}&limit=${limit}`)
      setJobFair(response?.data)
      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {

    }
  }


  const fetchInternship = async (status) => {
    try {
      const response = await get(`/company/company-details?companyId=${companyId}&type=2`)
      setInternships(response?.data.internships || []);
      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage2(totalPage)
    } catch (error) {

    }
  }
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);

  };

  const handleStatusChange = async (requestId, approvedStatus) => {
    put("/job/status", { _id: requestId, status: approvedStatus })
      .then(res => {
        setState(!state)
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }


  const handleStatusChange2 = async (requestId, approvedStatus) => {
    put("/internship/status", { id: requestId, status: approvedStatus })
      .then(res => {
        fetchInternship()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  const handlePasswordReset = async (event, values) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      toastr.error("Passwords do not match.");
      return;
    }
    try {
      const response = await put(`/company-user/reset-password`, {
        companyId,
        newPassword: password,
        confirmPassword
      });
      if (response.status === 200) {
        toastr.success("Password has been reset successfully.");
        setModal(false);
      } else {
        toastr.error(response.message || "Error resetting password.");
      }
    } catch (error) {

      if (error.response && error.response.status === 404) {
        toastr.error(error.response.data.message || "Company not found.");
        setModal(false);
      } else {

        toastr.error("An error occurred while resetting password.");
      }
    }
  };

  const job = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
        width: 400,
      },
      {
        label: "Job Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Status",
        field: "approvalStatus",
        sort: "asc",
        width: 200,
      },

      {
        label: "Staff",
        field: "addedBy",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows:
    jobPost.map((item, index) => {
        return {
          id: index + 1,
          date: moment(item.date).format("DD-MM-YYYY"),
          uniqueId: item?.uniqueId,
          name: item?.name,
          status: (
            <div className="mb-3">
              <Select
                menuPosition="fixed"
                name="job_status"
                placeholder="status"
                onChange={selected => {
                  handleStatusChange(item?._id, selected.value)
                }}
                value={status?.filter(status => status.value === item?.status)}
                options={status}
                classNamePrefix="select2-selection"
              />
            </div>
          ),

          action: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  navigate(`/create-job-post/${item?.uniqueId}`, {
                    state: { id: item._id },
                  })
                }}
              ></i>
            </div>
          )
        };
      })
  };

  const internship = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
        width: 400,
      },
      {
        label: "Internship Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },

      {
        label: "Staff",
        field: "Staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: internships.map((item, index) => {
      return {
        id: index + 1 + (page2 - 1) * limit,
        date: moment(item.date).format("DD-MM-YYYY"),
        uniqueId: item?.uniqueId,
        name: item?.name,
        status: (
          <div className="mb-3">
            <Select
              menuPosition="fixed"
              name="status"
              placeholder="status"
              onChange={selected => {
                handleStatusChange2(item?._id, selected.value)
              }}
              value={status?.filter(status => status.value === item?.status)}
              options={status}
              classNamePrefix="select2-selection"
            />
          </div>
        ),
        Staff: `${item?.firstname || ""} ${item?.lastname || ""}`,
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/internship/post`, {
                  state: { id: item._id },
                })
              }}
            // onClick={() => {
            //   setPopupView4(true)
            //   setModalData(item)
            // }}
            ></i>


          </div>
        )
      };
    })


  };


  const jobfair = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Venue",
        field: "venue",
        sort: "asc",
        width: 200,
      },

      {
        label: "Start Date",
        field: "startDate",
        sort: "asc",
        width: 200,
      },
      {
        label: "End Date",
        field: "endDate",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "jobfairStatus",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows:
    jobFair.map((item, index) => {
        return {
          id: index + 1,
          date: moment(item.date).format("DD-MM-YYYY"),
          uniqueId: item?.uniqueId,
          name: item?.name,
          venue: item?.venue,
          startDate: item?.startDate,
          endDate: item?.endDate,
          jobfairStatus:item?.jobfairStatus === 1
            ? "Upcoming"
            : item.jobfairStatus === 2
            ? "Ongoing"
            : "Completed",
          action: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  navigate(`/create-job-post/${item?.uniqueId}`, {
                    state: { id: item._id },
                  })
                }}
              ></i>
            </div>
          )
        };
      })
  };


  const series1 = [50]

  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [

    {
      id: 1,
      title: "Job",
      value: summary?.jobPost,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      title: "Internship",
      value: summary?.internship,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      title: "Job Fair",
      value: summary?.jobFair,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series1,
      options: options1,
    },
  ]


  return (
    <React.Fragment>

      <Modal
        show={popupView4}
        isOpen={popupView4}
        toggle={() => setPopupView4(!popupView4)}
        size="lg"
        centered={true}>
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applied Details</h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView4(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: '0px' }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: 'left' }}>Job</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px' }}>{modalData?.jobDetails?.name}</td>
                  <th style={{ textAlign: "left" }}>Company</th>
                  <th> : </th>
                  {/* <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{recruiterDetails?.name}</td> */}
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: '10px' }}>Application ID</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px' }}>{modalData?.uniqueId}</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{modalData?.applicantDetails?.name}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: '10px' }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{modalData?.applicantDetails?.mobile}</td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{modalData?.applicantDetails?.email}</td>
                </tr>
                {tab3 === 4 &&
                  <tr>
                    <th style={{ textAlign: "left", padding: '10px' }}>Placed Date</th>
                    <th> : </th>
                    <td style={{ paddingLeft: '20px' }}>{moment(modalData?.placedDate).format("DD-MM-YYYY")}</td>
                    <th style={{ textAlign: "left" }}>Placed Time</th>
                    <th> : </th>
                    <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{moment(modalData?.placedTime, "HH:mm:ss").format("hh:mm A")} </td>
                  </tr>
                }

                {tab3 === 4 &&
                  <tr>
                    <th style={{ textAlign: "left", padding: '10px' }}>Shortlisted Date</th>
                    <th> : </th>
                    <td style={{ paddingLeft: '20px' }}>{moment(modalData?.shortlistDate).format("DD-MM-YYYY")}</td>
                    <th style={{ textAlign: "left" }}>Shortlisted Time</th>
                    <th> : </th>
                    <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{moment(modalData?.shortlistTime, "HH:mm:ss").format("hh:mm A")}</td>
                  </tr>
                }


                <tr>
                  <th style={{ textAlign: "left", padding: '10px' }}>Experiance</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>Fresher</td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{modalData?.qualificationDetails?.name}</td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>


      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Company - {companyDetails?.companyName}</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/company">company Details</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{companyDetails?.companyName}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}
              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <h6 className="font-size-14">
                          <i className="fas fa-key" style={{ marginRight: "32px", color: "#00afc3", cursor: "pointer" }}
                            onClick={() => {
                              // setMaster(pre => ({ ...pre, id: item._id }))
                              setModal(true)
                            }}></i>
                        </h6>
                      </div>
                      {/* <div className="card-body" style={{ maxHeight: "400px", overflowY: "auto",marginTop:"-12px" }}> */}
                      <div className="float-end dropdown">
                      </div>
                      <h5
                        className="mt-2 mb-2"
                        style={{
                          textAlign: "center",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      >
                        {companyDetails?.companyName}
                      </h5>
                      <Stack direction="row" style={{ justifyContent: "center" }}>
                        <img
                          alt="Imag"
                          src={`${API_URL}/${companyDetails?.logo}`}
                          style={{
                            width: "5rem",
                            height: "4rem",
                            borderRadius: "5px",
                          }}
                        />
                      </Stack>
                      <div className="text-muted" style={{
                        textAlign: "center",
                        color: "green !important",
                        fontFamily: "IBM Plex Sans,sans-serif",
                        fontSize: "14px",
                        marginBottom: "3px",
                      }}>
                      </div>
                      <Divider />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Company ID:</p>
                        <h5 className="font-size-14">{companyDetails?.companyCode}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Company Code:</p>
                        <h5 className="font-size-14">{companyDetails?.shortCode}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Contact Person:</p>
                        <h5 className="font-size-14">{companyDetails?.contactPerson}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Mobile:</p>
                        <h5 className="font-size-14">{companyDetails?.mobile}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Email:</p>
                        <h5 className="font-size-14">{companyDetails?.email}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Corporate Address:</p>
                        <h5 className="font-size-14">{companyDetails?.address}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1" >District:</p>
                        <h5 className="font-size-14">{companyDetails?.district}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1" >State:</p>
                        <h5 className="font-size-14">{companyDetails?.state}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2">
                        <p className="mb-1" style={{ paddingLeft: "8px" }}>Designation:</p>
                        <h5 className="font-size-14">{companyDetails?.designation}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">CIN No:</p>
                        <h5 className="font-size-14">{companyDetails?.cinNo}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">From:</p>
                        <h5 className="font-size-14">{companyDetails?.from === 1 ? "CRM" : "WEB"}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">PAN No:</p>
                        <h5 className="font-size-14">{companyDetails?.panNo}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Pincode:</p>
                        <h5 className="font-size-14">{companyDetails?.pinCode}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">TDS No:</p>
                        <h5 className="font-size-14">{companyDetails?.tdsNo}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Website:</p>
                        <h5 className="font-size-14">{companyDetails?.website}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">GST No:</p>
                        <h5 className="font-size-14">{companyDetails?.gstin}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Industry:</p>
                        <h5 className="font-size-14">{companyDetails?.gstin}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Service Offered:</p>
                        <h5 className="font-size-14">{companyDetails?.serviceOffered}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Year of Establishment:</p>
                        <h5 className="font-size-14">{companyDetails?.startYear}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Organization Size:</p>
                        <h5 className="font-size-14">{companyDetails?.size}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Staff:</p>
                        <h5 className="font-size-14">{companyDetails?.addedBy || ""}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Latitude:</p>
                        <h5 className="font-size-14">{companyDetails?.latitude || ""}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Logitude:</p>
                        <h5 className="font-size-14">{companyDetails?.logitude || ""}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Landline No:</p>
                        <h5 className="font-size-14">{companyDetails?.landLine || ""}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Branch:</p>
                        <h5 className="font-size-14">{companyDetails?.branch || ""}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">GST Certificate:</p>
                        <h5 className="font-size-14">{companyDetails?.gstCertificate || ""}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Pan Card:</p>
                        <h5 className="font-size-14">{companyDetails?.panCard || ""}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Incorporation Certificate:</p>
                        <h5 className="font-size-14">{companyDetails?.incorporationCertificate || ""}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">AboutUs</p>
                        <h5 className="font-size-14">{companyDetails?.about || ""}</h5>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={<SummarizeIcon style={{ fontSize: "20px" }} />}
                                label="Summary"
                                value={'1'}
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                               <Tab
                                icon={<AssignmentIcon style={{ fontSize: "20px" }} />}
                                label="Internship"
                                value={'2'}
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />  
                             <Tab
                                icon={
                                  <LocationCityIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Job Fair"
                                value={'3'}
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <LocationCityIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="job"
                                value={'4'}
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>

                          <TabPanel
                            value={'1'}
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}>
                            <Row>
                              <MiniWidget reports={reports} />
                            </Row>
                          </TabPanel>
                          <TabPanel value={'2'} style={{
                            fontSize: "13px",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}>
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="movementTable"
                                  responsive
                                  bordered
                                  data={internship}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  paginationLabel={false}
                                  paging={false}
                                />

                                <TablePagination
                                  page={page2}
                                  onChange={handleChange2}
                                  count={totalPage2}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value={'3'} style={{
                            fontSize: "13px",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}>
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="dashboardTableId"
                                  responsive
                                  bordered
                                  searching={true}
                                  entries={20}
                                  disableRetreatAfterSorting={true}
                                  data={jobfair}
                                  paginationLabel={false}
                                  paging={false}
                                />
                                <TablePagination
                                  page={page}
                                  onChange={handleChange}
                                  count={totalPage}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value={'4'} style={{
                            fontSize: "13px",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}>
                            <Row>
                              <Col className="col-12">

                                <MDBDataTable
                                  id="movementTable"
                                  responsive
                                  bordered
                                  data={job}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  paginationLabel={false}
                                  paging={false}
                                />

                                <TablePagination
                                  page={page2}
                                  onChange={handleChange2}
                                  count={totalPage2}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

            </Col>
          </Row>
          <Modal
            show={modal}
            onHide={() => setModal(false)}
            centered={true}
            size="md"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Reset Password</h5>
              <button
                type="button"
                onClick={() => setModal(false)}
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <AvForm
              className="needs-validation"
              onValidSubmit={handlePasswordReset}
            >
              <div className="modal-body">
                <Row>
                  <Col md="12">
                    <FormGroup className="my-3 position-relative">
                      <AvField
                        name="password"
                        placeholder="New Password"
                        type="password"
                        errorMessage="Please provide a valid password"
                        validate={{ required: { value: true } }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        errorMessage="Please confirm the password"
                        validate={{
                          required: { value: true },
                          match: { value: "password", errorMessage: "Passwords do not match" },
                        }}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => setModal(false)}
                >
                  Close
                </button>
                <button className="btn btn-primary" type="submit">
                  Confirm
                </button>
              </div>
            </AvForm>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );


}

export default ViewCompany