import moment from "moment"

import { APP_NAME, CRYPTO_HASH } from "../config"

import CryptoJS from "crypto-js"

import { Button } from "reactstrap"
import Select from "react-select"
import * as XLSX from "xlsx"

export const setTitle = name => (document.title = name + ` | ${APP_NAME} `)

export const setBrowserToken = token => {
  localStorage.setItem("browserToken", token)
}

export const getFirstDay = date =>
  moment().startOf("month").format("YYYY-MM-DD")

export const getOneMonthAgo = date =>
  moment(date).subtract(1, "months").format("YYYY-MM-DD")

export const getDate = () => moment().format("YYYY-MM-DD")
export const getTime = () => moment().format("HH:mm:ss")

export const dateConverter = date => {
  const formats = [moment.ISO_8601, "YYYY-MM-DD HH:mm:ss"]
  const isValidDate = moment(date, formats, true).isValid()
  return isValidDate ? moment(date).format("DD-MM-YYYY") : ""
}

export const timeConverter = time => {
  const formats = ["HH:mm:ss", "HH:mm", "YYYY-MM-DD HH:mm:ss"]
  const isValidTime = moment(time, formats, true).isValid()
  return isValidTime ? moment(time, formats).format("hh:mm a") : ""
}

export function encrypt(data) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CRYPTO_HASH,
  ).toString()
  return encrypted
}

export const decrypt = data => {
  const decrypted = CryptoJS.AES.decrypt(data, CRYPTO_HASH).toString(
    CryptoJS.enc.Utf8,
  )
  return decrypted
}

export const handleImageValidation = async (
  file,
  width = 250,
  height = 140,
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      const img = new Image()
      img.src = e.target.result

      img.onload = () => {
        if (img.width === width && img.height === height) {
          resolve(true)
        } else {
          reject(
            `Please upload an image with dimensions ${width}x${height} pixels.`,
          )
        }
      }
    }

    reader.readAsDataURL(file)
  })
}

export const getStaticApproveOptions = activeStatus => {
  if (activeStatus === 0 || activeStatus === 1) {
    const statusValue = {
      0: { text: "Approved", color: "success" },
      1: { text: "Rejected", color: "danger" },
    }
    const status = statusValue[activeStatus]
    return (
      <Button size="sm" disabled color={status.color}>
        {status.text}
      </Button>
    )
  } else {
    return (
      <Select
        menuPosition="fixed"
        options={[
          { label: "Approve", value: 0 },
          { label: "Reject", value: 1 },
        ]}
      />
    )
  }
}

export const genderOptions = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Other", value: 3 },
]

export const genderValue = value =>
  value === 1 ? "Male" : value === 2 ? "Female" : value === 3 ? "Other" : null

export const toTop = () =>
  window.scroll({ top: 0, left: 0, behavior: "smooth" })

export const numberToString = (number = 0) => {
  return (
    number.toLocaleString("en-IN", {
      maximumFractionDigits: 2,
    }) || 0
  )
}

export const numberToCurrency = (number = 0) => {
  return (
    number?.toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    }) || "₹0"
  )
}

export const renderTableValue = value =>
  value || <div className="text-center">-</div>

export const timeSince = (date, now_date = new Date()) => {
  var seconds = Math.floor((now_date - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + " yrs"
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + " months"
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + " days"
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + " hrs"
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + " min"
  }
  return Math.floor(seconds) + " sec"
}

export const jsonToExcel = (
  jsonArray,
  sheetName = moment().format("DDMMYYhhmmss"),
) => {
  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(jsonArray)

  // Create a workbook
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetName)

  // Save to a file
  XLSX.writeFile(wb, `${sheetName}.xlsx`)
}

export const isIncludesAll = data => {
  return Array.isArray(data)
    ? data.some(item => item.value === "all")
    : data.value === "all"
}

// export const reactSelectCustomStyleOld = {
//   control: provided => ({
//     ...provided,
//     height: "30px",
//     minHeight: "30px",
//     display: "flex",
//     alignItems: "center",
//   }),
//   valueContainer: provided => ({
//     ...provided,
//     height: "30px",
//     display: "flex",
//     alignItems: "center",
//     padding: "0 8px",
//   }),
//   input: provided => ({
//     ...provided,
//     margin: "0",
//     padding: "0",
//   }),
//   singleValue: provided => ({
//     ...provided,
//     display: "flex",
//     alignItems: "center",
//   }),
//   indicatorsContainer: provided => ({
//     ...provided,
//     height: "30px",
//     display: "flex",
//     alignItems: "center",
//   }),
//   indicatorSeparator: provided => ({
//     ...provided,
//     height: "20px",
//     alignSelf: "center",
//     margin: "0 3px",
//   }),
//   dropdownIndicator: provided => ({
//     ...provided,
//     display: "flex",
//     alignItems: "center",
//     padding: "0",
//     margin: "0 5px",
//   }),
// }
export const reactSelectCustomStyle = {
  control: (provided, state) => ({
    ...provided,
    height: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
  }),
  valueContainer: provided => ({
    ...provided,
    height: "30px",
    padding: "0 8px",
  }),
  input: provided => ({
    ...provided,
    margin: "0",
    padding: "0",
  }),

  indicatorsContainer: provided => ({
    ...provided,
    height: "30px",
    display: "flex",
    alignItems: "center",
  }),
}

export const durationFormateSecond = durationInSeconds => {
  const duration = moment.duration(durationInSeconds, "seconds")
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  if (hours > 0) {
    return `${hours} ${hours === 1 ? "Hour" : "Hours"}`
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "Minute" : "Minutes"}`
  } else {
    return `${seconds} ${seconds === 1 ? "Second" : "Seconds"}`
  }
}

export const toggleSideBar = () => {
  const body = document.body

  body.classList.toggle("vertical-collpsed")
  body.classList.toggle("sidebar-enable")
  body.setAttribute("data-sidebar-size", "sm")
}
