import React, { useState, useEffect } from 'react'
import MiniWidget from './mini-widget';
import { Container, Row, Col, Label } from "reactstrap";
import { get } from '../../helpers/api_helper';
import ReactECharts from 'echarts-for-react';

const Dashboard = () => {
    const [countValues, setcountValues] = useState([])
    const [sdaPidata, setsdaPidata] = useState([])
    const reports = [
        { id: 1, title: "TotalCandidates", value: countValues.totalCandidates },
        { id: 2, title: "Groomed Candidates", value: countValues.groomedCandidates },
        { id: 3, title: "Approved Institues", value: countValues.approvedInstitues },
        { id: 4, title: "Pending Institues", value: countValues.pendingInstitues },
        { id: 5, title: "Approved Companies", value: countValues.approvedCompanies },
        { id: 6, title: "Pending Companies", value: countValues.pendingCompanies },
        { id: 7, title: "Total Tickets", value: countValues.totalTickets },
        { id: 8, title: "Active ScreenTests", value: countValues.activeScreenTests },
    ]

    useEffect(() => {
        fetchAllCount()
        fetchAllsdaPiedata()
    }, [])


    function fetchAllCount() {
        get(`sda-dashboard/counts`).then((res) => {
            setcountValues(res.data)
        }).catch((err) => {

        })
    }

    function fetchAllsdaPiedata() {
        get(`sda-dashboard/tickets`).then((res) => {
            setsdaPidata(res.data)
        }).catch((err) => {

        })
    }

    const optionpi1 = {
        title: {
            subtext: 'SDA Tickets',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        color: ['#ff6f61', '#6b8e23', '#4682b4','#3333'], // Define custom colors
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: '50%',   
                data: sdaPidata || [],
                label: {
                    show: true,
                    formatter: '{b}: {c} ({d}%)', // Displays name, value, and percentage
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Row>
                        <h6></h6>
                        <MiniWidget reports={reports} />
                    </Row>
                    <Row className='mt-4'>
                        <Col md="6">
                        <ReactECharts option={optionpi1} style={{ height: 400, width: '100%' }} />
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    )
}

export default Dashboard
