import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Select from "react-select"
import queryString from "query-string"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import { jsonToExcel } from "../../helpers/functions"
import { dateConverter,setTitle,getDate} from "../../helpers/functions"
import { get } from "../../helpers/api_helper"
import SearchField from "../../components/Common/SearchField"
import { useTransition } from "react"
const CompanyWiseInternshipReport = props => {
  setTitle("Company")
  const formRef = useRef(null)
  const filterRef = useRef(null)
  const [tableData, setTableData] = useState([])
  const [filterCompanyOption, setFilterCompanyOption] = useState([])
  const limit = 13
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})
  const [exports, setExports] = useState([])
  const todayDate = getDate()
  const [isExportPending, startExport] = useTransition()
  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handlefilterCompanyOptions()
  }, []) // eslint-disable-line

  const handlefilterCompanyOptions = async (state = "") => {
    try {
      const response = await get(`/options/company`)
      setFilterCompanyOption(response.data)
    } catch (error) {
      //   console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      
      const exportData = []
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`/report/company-wise-internship-report${query}`)
      const { data, count } = response
     data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1;
       item.date = dateConverter(item.date);
       item.locations = item.locations.join(", ");
      //  item.streams = item.streams.join(", ");
       item.clampedStreams = (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip
                id="button-tooltip-2"
                style={{ position: "absolute", top: "-30px" }}
              >
                {item.streams.join(", ")}
              </Tooltip>
            }
          >
            <div>
              {item.streams?.join(", ")?.slice(0, 24)}
              {item.streams?.join(", ")?.length > 24 ? "..." : ""}
            </div>
          </OverlayTrigger>
        </>
      )
        const dataExport = {}
        dataExport["#"] = index + 1
        dataExport["Date"] = item.date
        dataExport["Company Name"] = item.companyName
        dataExport["Company ID"] = item.companyId
        dataExport["Internship Name"] = item.name
        dataExport["Vacancies"] = item.vacancies
        dataExport["Available Vacancies"] = item.availableVacancy
        dataExport["Locations"] = item.locations
        dataExport["Specializations"] = item.streams
        exportData.push(dataExport)
        setExports(exportData)
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      startExport(() => {
        handleExportData(count)
      })
      setTableData(data)
    } catch (error) {
      //   console.error(error)
    }
  }


  const handleExportData = async (count) => {
    try {
      const exportData = []
  
       const query = `?page=0&limit=${count}&${queryString.stringify(filterObject)}`
      const response = await get(`/report/company-wise-internship-report${query}`)
      const { data } = response
  
      data.forEach((item, index) => {
        item.date = dateConverter(item.date)
        item.locations = item.locations.join(", ")
        item.streams = item.streams.join(", ")
  
        exportData.push({
          "#": index + 1,
          "Date": item.date,
          "Company Name": item.companyName,
          "Company ID": item.companyId,
          "Internship Name": item.name,
          "Vacancies": item.vacancies,
          "Available Vacancies": item.availableVacancy,
          "Locations": item.locations,
          "Specializations": item.streams,
        })
      })
      setExports(exportData)
    } catch (error) {
      // console.error("Error exporting data:", error)
    }
  }
  

  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected
    if (name === "state") {
      obj.district = null
    }
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    obj[name] = value.trim()
    if (name === "state") {
      obj.district = null
      //   handleFilterDistrictOptions(value)
    }

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    if (formRef.current) formRef.current.reset()
    if (filterRef.current) filterRef.current.reset()

    setTotalPage(1)
    toTop()

    setSelectFilter({})
    setFilterObject({})
      handleTableData(1);
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Company Name", field: "companyName" },
      { label: "Internship Name", field: "name" },
      { label: "Company ID", field: "companyId" },
      { label: "Vacancies", field: "vacancies" },
      { label: "Available Vacancies", field: "availableVacancy" },
      { label: "Expiry Date", field: "expiryDate" },
      { label: "Branch", field: "branch" },
      { label: "Locations", field: "locations" },
      { label: "Specializations", field: "clampedStreams" },
    ],
    rows: tableData,
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `company-wise-internship-report`)
    } else {
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Company Wise Internship Reports"
          />
          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      type="date"
                      name="from"
                      value={filterObject.from || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      max={todayDate}
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      type="date"
                      name="to"
                      value={filterObject.to || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      min={filterObject.fr}
                      max={todayDate}
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <Label>Company</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterCompanyOption}
                      value={selectFilter.company || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "company" })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={filterObject.search || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      placeholder="Search Name"
                    />
                  </Col>
                  <Col md={2} className="form-button">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                    <Button
                      style={{ marginLeft: "14px" }}
                      color="warning"
                      type="button"
                      disabled={isExportPending}
                      onClick={handleExport}
                    >
                      Export{" "}
                      {isExportPending && (
                              <i className="mdi mdi-spin mdi-loading"></i>
                            )}
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                tableId="companyTable"
                page={page}
                onChange={handlePageChange}
                data={data}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompanyWiseInternshipReport
