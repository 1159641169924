import React, { useEffect, useRef, useState } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import queryString from "query-string"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SearchSelect from "../../../components/Common/SearchSelect"

import {
  dateConverter,
  renderTableValue,
  setTitle,
  toTop,
  getDate,
  numberToCurrency,
  numberToString,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"
import "./styles.scss"
import Details from "../Details"
import { typeOptions } from "../Applications/helper"
const InternshipCompleted = () => {
  setTitle("Internship Completed")

  const formRef = useRef()
  const todayDate = getDate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [tableData, setTableData] = useState([])
  const [viewModal, setViewModal] = useState({ show: false, id: null })
  const [filterObject, setFilterObject] = useState({ status: 9 })
  const [selectedFields, setSelectedFields] = useState({})

  const [companyOptions, setCompanyOptions] = useState([])
  const [internshipOptions, setInternshipOptions] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleInternshipOptions()
  }, [filterObject]) //eslint-disable-line

  const handleCompanyOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `internship/applications/options/company?${params}`,
      )
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInternshipOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `internship/applications/options/internship?${params}`,
      )
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`internship/applications/completed?${query}`)
    const { count, data } = response

    data.map((item, index) => {
      const candidate = item.candidate
      // const unit =
      //   item.unit === 1
      //     ? "Per Day"
      //     : item.unit === 2
      //       ? "Per Week"
      //       : item.unit === 3
      //         ? "Per Month"
      //         : "Per Year"

      item.id = index + 1 + (currentPage - 1) * limit
      item.date = dateConverter(item.date)

      item.internship = renderTableValue(item.internship?.name)
      item.company = renderTableValue(item.company?.name)

      item.candidate = renderTableValue(candidate?.name)
      item.candidateUniqueId = renderTableValue(candidate?.uniqueId)

      item.totalWorking = numberToString(item.total_working)
      item.attendance = numberToString(item.attendance_marked)
      item.stipend = numberToCurrency(item.stipend)
      item.providedStipend = numberToCurrency(item.provided_stipend)

      item.options = (
        <div className="d-flex align-items-center justify-content-center">
          <Tooltip title="View" arrow placement="top">
            <i
              className="fas fa-eye eye-icon"
              onClick={() => handleViewModal(item._id, true)}
            ></i>
          </Tooltip>
        </div>
      )

      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value: value, name })
  }
  const handleValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
    toTop()
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    setFilterObject({ status: 9 })
    setSelectedFields({})
  }

  const handleViewModal = (id, show = false) => {
    setViewModal({ id, show })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "ID", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Internship", field: "internship" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateUniqueId" },
      { label: "Total Working (days)", field: "totalWorking" },
      { label: "Attendance (days)", field: "attendance" },
      { label: "Stipend", field: "stipend" },
      { label: "Provided Stipend", field: "providedStipend" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Internship - Completions" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    options={companyOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "company" })
                    }}
                    value={selectedFields.company || ""}
                    isClearable
                  />
                </Col>

                <Col md={2}>
                  <Label>Internship</Label>
                  <Select
                    options={internshipOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "internship" })
                    }}
                    value={selectedFields.internship || ""}
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "candidate" })
                    }}
                    value={selectedFields.candidate || ""}
                    isMulti
                    master={filterObject}
                    api="internship/applications/options/candidate"
                  />
                </Col>
                <Col md={2}>
                  <Label>Type</Label>
                  <Select
                    options={typeOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "type" })
                    }}
                    value={selectedFields.type || ""}
                    isMulti
                  />
                </Col>
                <Col className="form-button">
                  <Button onClick={reset} color="danger" type="button">
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="internshipCompletedTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
      <Details
        id={viewModal.id}
        isOpen={viewModal.show}
        onToggle={() => handleViewModal()}
      />
    </div>
  )
}

export default InternshipCompleted
