import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Container, Card,Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import toastr from "toastr"

import { useParams } from "react-router-dom"

import { get, put } from "../../../helpers/api_helper"
import {
  dateConverter,
  durationFormateSecond,
  jsonToExcel,
  numberToString,
  renderTableValue,
  timeConverter,
} from "../../../helpers/functions"

import "./styles.scss"
import Icon from "../../../components/Common/Icon"

const RankListDetails = () => {
  const STATUS_VALUES = {
    PUBLISHED: 0,
    NOT_PUBLISHED: 2,
    0: { text: "Published", color: "success" },
    2: { text: "Not published", color: "danger" },
  }

  const [questions, setRankList] = useState([])
  const [details, setDetails] = useState({})
  const [loading, setloading] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    if (id) handleData()
  }, [id]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`rank-list/details?id=${id}`)
      const data = response.data
      const list = data.list

      const status = STATUS_VALUES[data.status]

      data.statusText = status?.text || ""
      data.totalRanks = numberToString(data.totalRanks)

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.publishedDate = dateConverter(data.publish?.date)
      data.publishedTime = timeConverter(data.publish?.time)

      if (Array.isArray(list)) {
        list.map(item => {
          item.rank = numberToString(item.rank)

          item.candidateId = renderTableValue(item.candidate?.uniqueId)
          item.candidate = renderTableValue(item.candidate?.name)

          item.startDate = dateConverter(item.attendTest?.startTime)
          item.startTime = timeConverter(item.attendTest?.startTime)

          item.endDate = dateConverter(item.attendTest?.endTime)
          item.endTime = timeConverter(item.attendTest?.endTime)

          item.total = numberToString(item?.attendTest?.markObtained?.total)
          item.percentage = `${(item?.attendTest?.markObtained?.percentage || 0).toFixed(2)}%`
          item.general = numberToString(item?.attendTest?.markObtained?.general)
          item.specialization = numberToString(
            item?.attendTest?.markObtained?.specialization,
          )

          item.timeTaken = durationFormateSecond(item?.attendTest?.elapsedTime)
          if (data.job || data.internship) {
            if (item?.attendTest?.internshipApplication?.forwarded?.status == 1 || item?.attendTest?.jobApplication?.forwarded?.status == 1) {
              item.action = (
                <Icon
                  icon="share"
                  title="Forward"
                  onClick={() => handleForwardAction(item)}
                />
              )
            } else {
              item.action = (
                <>
                  <span>Forwarded</span>
                </>
              )
            }
          }
          return item
        })
      }

      setDetails(data)
      setRankList(list || [])
    } catch (error) {
      console.error(error)
    }
  }



  const handleDataexport = async () => {
    setloading(true)
    try {
      const response = await get(`rank-list/details?id=${id}`)
      const data = response.data
      const list = data.list

      const status = STATUS_VALUES[data.status]
      const exports = []

      if (Array.isArray(list)) {
        list.map(item => {
          const exportData = {}
          exportData.Rank = numberToString(item.rank)

          exportData.CandidateId = renderTableValue(item.candidate?.uniqueId)
          exportData.Candidate = renderTableValue(item.candidate?.name)

          exportData.StartDate = dateConverter(item.attendTest?.startTime)
          exportData.StartTime = timeConverter(item.attendTest?.startTime)

          exportData.EndDate = dateConverter(item.attendTest?.endTime)
          exportData.endTime = timeConverter(item.attendTest?.endTime)

          exportData.total = numberToString(item?.attendTest?.markObtained?.total)
          exportData.percentage = `${(item?.attendTest?.markObtained?.percentage || 0).toFixed(2)}%`
          exportData.general = numberToString(item?.attendTest?.markObtained?.general)
          exportData.specialization = numberToString(
            exportData?.attendTest?.markObtained?.specialization,
          )

          exportData.timeTaken = durationFormateSecond(item?.attendTest?.elapsedTime)
          if (data.job || data.internship) {
            if (item?.attendTest?.internshipApplication?.forwarded?.status == 1 || item?.attendTest?.jobApplication?.forwarded?.status == 1) {
              exportData.Status = "Not-Forwarded"
            } else {
              exportData.Status = "Forwarded"
            }
          }
          exports.push(exportData)
          return item
        })
        jsonToExcel(exports, `Ranklist`)

      }
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }
  const data = {
    columns: [
      { label: "Rank", field: "rank" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Start Date", field: "startDate" },
      { label: "Start Time", field: "startTime" },
      { label: "End Date", field: "endDate" },
      { label: "End Time", field: "endTime" },
      { label: "Time Taken", field: "timeTaken" },
      { label: "Total", field: "total" },
      { label: "Percentage", field: "percentage" },
      { label: "Action", field: "action" },
      // { label: "Specialization", field: "specialization" },
    ],
    rows: questions,
  }


  const handleForwardAction = (item) => {
    if (item?.attendTest?.internshipApplication?.forwarded?.status === 1) {
      forwardInternshipApplication(item.attendTest.internshipApplication._id);
    } else if (item?.attendTest?.jobApplication?.forwarded?.status === 1) {
      forwardJobApplication(item.attendTest.jobApplication._id);
    } else {
      console.error("No valid application found to forward");
    }
  };


  function forwardInternshipApplication(id) {
    put(`internship/applications/forward`, { id: id }).then((res) => {
      toastr.success(res.message)
      handleData()
    }).catch((err) => {
      toastr.error(err.response.data.message)
    })
  }

  function forwardJobApplication(id) {
    put(`job/applications/forward`, { id: id }).then((res) => {
      toastr.success(res.message)
      handleData()
    }).catch((err) => {
      toastr.error(err.response.data.message)
    })
  }


  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Rank List Details" />

        <Row>
          <Col lg={3}>
            <Card className="card-shadow">
              <CardBody>
                <SectionHeading title={details?.screeningTest?.name} />
                <InfoRow label="Unique Id" value={details.uniqueId} />
                <InfoRow label="Total Ranks" value={details.totalRanks} />
                <InfoRow label="Status" value={details.statusText} />
                <InfoRow label="Created By" value={details.addedBy?.name} />
                <InfoRow label="Date" value={details.date} />
                <InfoRow label="Time" value={details.time} />
                <InfoRow
                  label="Published Date"
                  value={details.publishedDate}
                  hidden={details.status === STATUS_VALUES.NOT_PUBLISHED}
                />
                <InfoRow
                  label="Published Time"
                  value={details.publishedTime}
                  hidden={details.status === STATUS_VALUES.NOT_PUBLISHED}
                />
                <InfoRow
                  label="Publish By"
                  value={details.publish?.staff?.name}
                  hidden={details.status === STATUS_VALUES.NOT_PUBLISHED}
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg={9} style={{ marginBottom: "30px" }}>
            <Card>
              <CardBody>
                <Row>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Button color="warning"
                      onClick={handleDataexport}
                    >
                      {loading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        "Export"
                      )}
                    </Button>
                  </div>
                </Row>
                <MDBDataTable
                  id="rankListDetails"
                  responsive
                  bordered
                  data={data}
                  searching={false}
                  paging={false}
                  info={false}
                  sortable={false}
                  disableRetreatAfterSorting={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RankListDetails

const InfoRow = ({ label, value, hidden }) => {
  if (hidden) return null
  return (
    <>
      <div
        className="mt-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="mb-1">{label}:</p>
        <h5
          className="font-size-14 mb-0 text-wrap"
          style={{ wordBreak: "break-word", maxWidth: "100%" }}
        >
          {value || ""}
        </h5>
      </div>
      <hr style={{ margin: "0", color: "#00afc3" }} />
    </>
  )
}

const SectionHeading = ({ title }) => (
  <h5 className="font-size-16 text-center text-uppercase mt-3 mb-3">{title}</h5>
)
