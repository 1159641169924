export const statusOptions = [
  { label: "Applied", value: 0 },
  { label: "Shortlist", value: 2 },
  { label: "Placed", value: 3 },
  { label: "Rejected", value: 4 },
  { label: "Rejected", value: 5 },
  { label: "Withdrawn", value: 6 },
  { label: "Withdrawn Pending", value: 7 },
  { label: "Completed", value: 9 },
]

export const typeOptions = [
  { label: "KTU", value: 1 },
  { label: "General", value: 2 },
  { label: "Public Sector", value: 3 },
]

export const screeningStatusOptions = [
  { label: "Pass", value: 1 },
  { label: "Failed", value: 2 },
  { label: "Retake", value: 3 },
  { label: "Pending", value: 4 },
  { label: "Not Scheduled", value: 5 },
  { label: "Scheduled", value: 6 },
]

export const interviewStatusOptions = [
  { label: "Qualified", value: 1 },
  { label: "Not Qualified", value: 2 },
  { label: "Not scheduled", value: 3 },
  { label: "Scheduled", value: 4 },
]

export const smeStatusOptions = [
  { label: "Qualified", value: 1 },
  { label: "Not Qualified", value: 2 },
  { label: "Not scheduled", value: 3 },
  { label: "Scheduled", value: 4 },
]
