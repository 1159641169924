import React, { useEffect, useRef, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Container,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"

import toastr from "toastr"
import moment from "moment"
import Swal from "sweetalert2"
import queryString from "query-string"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import { del, get, post, put } from "../../../helpers/api_helper"
import {
  dateConverter,
  getDate,
  renderTableValue,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import { typeOptions, TYPES } from "../../Assessment/SME/helper"
import CandidateCard from "./CandidateCard"
import { STATUS_VALUES, statusOptions } from "./helper"
import Icon from "../../../components/Common/Icon"
import AssignDetails from "./AssignDetails"


const AssignCandidate = () => {
  const formRef = useRef()
  const filterRef = useRef()
  const todayDate = getDate()
  const now = moment()

  const [modal, setModal] = useState({ show: false, id: null })


  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [internshipOptions, setInternshipOptions] = useState([])
  const [jobOptions, setJobOptions] = useState([])
  const [interviewOptions, setinterviewOptions] = useState([])

  const [candidateOptions, setCandidatesOptions] = useState([])
  const [selectedCandidates, setSelectedCandidates] = useState([])

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectedFilter] = useState({})

  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => { }, []) // eslint-disable-line

  const handleInternshipOptions = async () => {
    try {
      const response = await get(`interview-assign/options/internship`)
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobOptions = async () => {
    try {
      const response = await get(`/interview-assign/options/job`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSmeOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)

      const response = await get(`interview-assign/options/interview?${query}`)
      setinterviewOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCandidateOptions = async (obj = masterObject) => {
    try {
      if (!obj.interview) return
      const query = queryString.stringify(obj)
      const response = await get(`interview-assign/options/candidates?${query}`)
      setCandidatesOptions(response.data)
    } catch (error) {
      console.error(error)
      setCandidatesOptions([])
      setSelectedCandidates([])
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`interview-assign?${query}`)

      const { count, data } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        const start = moment(item.startDateAndTime, "YYYY-MM-DD HH:mm", true)
        const end = moment(item.endDateAndTime, "YYYY-MM-DD HH:mm", true)

        item.date = dateConverter(item.date)

        if (item.type === TYPES.INTERNSHIP)
          item.internshipOrJob = item.internship?.name
        else item.internshipOrJob = item.job?.name

        item.type = renderTableValue(TYPES[item.type])
        item.internshipOrJob = renderTableValue(item.internshipOrJob)

        item.interview = item?.interview?.name

        item.startDate = renderTableValue(dateConverter(item.startDate))
        item.endDate = renderTableValue(dateConverter(item.endDate))

        item.startTime = renderTableValue(timeConverter(item.startTime))
        item.endTime = renderTableValue(timeConverter(item.endTime))

        const status = {
          ongoing: false,
          completed: false,
          rankListGenerated: item.rankList === 0,
          toUpdate:
            item.status === STATUS_VALUES.ACTIVE
              ? STATUS_VALUES.IN_ACTIVE
              : STATUS_VALUES.ACTIVE,
        }

        if (item.status !== STATUS_VALUES.IN_ACTIVE) {
          if (end.isBefore(now)) {
            item.status = STATUS_VALUES.COMPLETED
            status.completed = true
          } else if (start.isSameOrBefore(now)) {
            item.status = STATUS_VALUES.ONGOING
            status.ongoing = true
          }
        }

        const statusObj = STATUS_VALUES[item.status]

        console.log(item.status, typeof item.status)
        item.status = (
          <Button
            {...statusObj}
            onClick={() => {
              handleStatusChange(item._id, status.toUpdate)
            }}
          >
            {statusObj?.text}
          </Button>
        )

        item.options = (
          <div>
            <Icon
              icon="eye"
              title="View"
              onClick={() => handleToggleModal({ show: true, id: item._id })}
            />
            <Icon
              icon="edit"
              title="Edit"
              hidden={status.completed || status.ongoing}
              onClick={() => handleUpdate(item._id)}
            />

            <Icon
              icon="delete"
              title="Delete"
              hidden={status.completed || status.ongoing}
              onClick={() => handleDelete(item._id)}
            />
          </div>
        )

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response

      if (masterObject.id)
        response = await put(`interview-assign`, {
          ...masterObject,
          candidates: selectedCandidates,
        })
      else
        response = await post(`interview-assign`, {
          ...masterObject,
          candidates: selectedCandidates,
        })

      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`interview-assign/update/${id}`)
      const { data } = response
      toTop()

      const job = data?.job?.value
      const internship = data?.internship?.value

      setMasterObject({
        ...data,
        interview: data?.interview?.value,
        job,
        internship,
        candidates: data?.candidateIds,
        id,
      })

      setSelectedFields({
        ...data,
        type: { label: TYPES[data.type], value: data.type },
      })
      setSelectedCandidates(data?.candidateIds || [])

      handleSmeOptions({ job, internship })
      handleCandidateOptions({
        id,
        job,
        internship,
        type: data.type,
        interview: data.interview?.value,
      })

      if (data.type === TYPES.JOB && !jobOptions.length) {
        handleJobOptions()
      } else if (!internshipOptions.length) handleInternshipOptions()
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`interview-assign/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`interview-assign/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSelectValueChange = ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "type") {
      obj.job = null
      obj.interview = null
      obj.candidates = []
      obj.internship = null
    }
    if (name === "internship" || name === "job") {
      obj.interview = ""
    }
    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value

    if (name === "type") {
      if (value === TYPES.JOB && !jobOptions.length) {
        handleJobOptions()
      } else if (!internshipOptions.length) handleInternshipOptions()

      obj.job = null
      obj.interview = null
      obj.candidates = []
      obj.internship = null

      setSelectedCandidates([])
      setCandidatesOptions([])
    }

    if (name === "internship" || name === "job") {
      handleSmeOptions(obj)
      setSelectedCandidates([])
      handleCandidateOptions(obj)
      obj.interview = null
    }
    if (name === "interview") {
      handleCandidateOptions(obj)
    }
    setMasterObject(obj)
  }

  const handleFilterSelectValueChange = async ({ selected, name }) => {
    setSelectedFilter(prev => ({ ...prev, [name]: selected }))
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handleCheckboxChange = ({ id, checked } = {}) => {
    const selected = [...selectedCandidates]

    if (checked) {
      selected.push(id)
    } else {
      const candidateIndex = selected.indexOf(id)
      if (candidateIndex !== -1) selected.splice(candidateIndex, 1)
    }

    setSelectedCandidates(selected)
  }

  const handleSelectAll = async () => {
    if (selectAll) {
      setSelectedCandidates([])
    } else {
      const allSelected = candidateOptions.map(candidate => candidate.id)
      setSelectedCandidates(allSelected)
    }
    setSelectAll(!selectAll)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
    toTop()
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    filterRef.current?.reset()

    setMasterObject({})
    setSelectedFields({})

    setFilterObject({})
    setSelectedFilter({})

    setCandidatesOptions([])
    setinterviewOptions([])
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Type", field: "type" },
      { label: "internship/job name", field: "internshipOrJob" },
      { label: "Interview", field: "interview" },
      { label: "Start Date", field: "startDate" },
      { label: "Start Time", field: "startTime" },
      { label: "End Date", field: "endDate" },
      { label: "End Time", field: "endTime" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  const handleToggleModal = ({ show = false, id = null }) => {
    setModal({ show, id })
    // if (!show) handleTableData()
  }


  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Assign Candidate" />
        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md={3}>
                  <Label>Type</Label>
                  <Select
                    menuPosition="fixed"
                    options={typeOptions}
                    value={selectedFields.type || ""}
                    onChange={selected =>
                      handleSelectValueChange({ name: "type", selected })
                    }
                  />
                </Col>

                {masterObject.type === TYPES.INTERNSHIP && (
                  <Col md={3}>
                    <Label>Internship</Label>
                    <Select
                      menuPosition="fixed"
                      options={internshipOptions}
                      value={selectedFields.internship || ""}
                      onChange={selected =>
                        handleSelectValueChange({
                          name: "internship",
                          selected,
                        })
                      }
                    />
                  </Col>
                )}

                {masterObject.type === TYPES.JOB && (
                  <Col md={3}>
                    <Label>Job</Label>
                    <Select
                      menuPosition="fixed"
                      options={jobOptions}
                      value={selectedFields.job || ""}
                      onChange={selected =>
                        handleSelectValueChange({ name: "job", selected })
                      }
                    />
                  </Col>
                )}

                <Col md={3}>
                  <Label>Interview</Label>
                  <Select
                    options={interviewOptions}
                    value={selectedFields.interview || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "interview" })
                    }
                  />
                </Col>

                <Col md={2}>
                  <Label>Start Date</Label>
                  <AvField
                    name="startDate"
                    type="date"
                    value={masterObject.startDate || ""}
                    errorMessage="Enter Start date"
                    validate={{ required: { value: true } }}
                    min={!masterObject.id && todayDate}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Start Time</Label>
                  <AvField
                    name="startTime"
                    type="time"
                    validate={{ required: { value: true } }}
                    value={masterObject.startTime || ""}
                    errorMessage="Enter Start time"
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>End Date</Label>
                  <AvField
                    name="endDate"
                    type="date"
                    value={masterObject.endDate || ""}
                    errorMessage="Enter Start date"
                    validate={{ required: { value: true } }}
                    min={masterObject.startDate}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>End Time</Label>
                  <AvField
                    name="endTime"
                    type="time"
                    value={masterObject.endTime || ""}
                    validate={{ required: { value: true } }}
                    errorMessage="Enter End time"
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={3}>
                  <div className="d-flex gap-3 form-button">
                    <Button color={masterObject?._id ? "warning" : "primary"}>
                      {masterObject?._id ? "Update" : "Submit"}
                    </Button>
                    <Button type="reset" color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
            <Row>
              {candidateOptions && candidateOptions.length > 0 && (
                <Col md={12}>
                  <Label>Select All</Label>
                  <Input
                    className="ms-1"
                    type="checkbox"
                    onChange={handleSelectAll}
                  />
                </Col>
              )}
              {candidateOptions.map((candidate, index) => (
                <Col md={4} xxl={3}>
                  <CandidateCard
                    {...candidate}
                    index={index}
                    checked={selectedCandidates.includes(candidate.id)}
                    onCheckboxChange={handleCheckboxChange}
                  />
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={3}>
                  <Label>Type</Label>
                  <Select
                    options={typeOptions}
                    value={selectFilter.type || null}
                    onChange={selected =>
                      handleFilterSelectValueChange({ selected, name: "type" })
                    }
                  />
                </Col>

                <Col md={3}>
                  <Label>Status</Label>
                  <Select
                    options={statusOptions}
                    value={selectFilter.status || null}
                    onChange={selected =>
                      handleFilterSelectValueChange({
                        selected,
                        name: "status",
                      })
                    }
                  />
                </Col>

                <Col md={3} className="form-button">
                  <Button color="danger" type="button" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              tableId="assignTest"
              data={data}
              count={totalPage}
              onChange={handlePageChange}
            />
          </CardBody>
        </Card>
      </Container>
      <AssignDetails
        isOpen={modal.show}
        onToggle={() => handleToggleModal({})}
        id={modal.id}
      />


    </div>
  )
}

export default AssignCandidate
