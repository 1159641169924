import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { get } from '../../helpers/api_helper'
import TablePagination from "../../components/Common/TablePagination"
import "./Jobfairstyle.scss"
import Select from "react-select"
import moment from 'moment'
const Registrations = ({jobfairId}) => {
    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [tableData, settableData] = useState([])
    const [filterObj, setfilterObj] = useState({})
    const [selectValues, setselectValues] = useState({})
    useEffect(() => {
      fetchAllRegistrations()
    }, [jobfairId,filterObj])


    function fetchAllRegistrations() {
        get(`job-fair/details/candidate-registration?id=${jobfairId}&attendance=${filterObj?.attendance}&payment=${filterObj?.payment}`).then((res) => {
            let result = res.data;
            const count = res.count;
            result.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"
                item.name=item.candidate.name;
                item.mobile=item.candidate.mobile;
                item.attend=item.attendance===1?"Not Attended":item.attendance===2?"Attended":"---"
                item.fee=item.feePaid===0?"Unpaid":item.feePaid===1?"Paid":"---"
                
                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            settableData(result)

        })
    }

    const handlePageChange = value => {
        setPage(value)
    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "ID", field: "uniqueId"}, 
            { label: "Name", field: "name"},
            { label: "Mobile", field: "mobile" },
            { label: "Attendance", field: "attend" },
            { label: "fee", field: "fee" },

        ],
        rows: tableData,
    }


    function handleValuechange(selected,name){
      setselectValues({...selectValues,[name]:selected})
      setfilterObj({...filterObj,[name]:selected?.value})

    }
    return (
        <>
        <Row>
          <Col md="3">
          <Label>Attendance</Label>
          <Select
          isClearable
          options={[
            {label:"Attended",value:2},
            {label:"Not Attended",value:1},
          ]}
          onChange={(selected)=>{
            handleValuechange(selected,"attendance")
          }}
          value={selectValues?.attendance||null}
          
          />
          </Col>
          <Col md="3">
          <Label>Fee</Label>

          <Select
          isClearable
          options={[
            {label:"Unpaid",value:0},
            {label:"Paid",value:1},
          ]}
          onChange={(selected)=>{
            handleValuechange(selected,"payment")
          }}
          value={selectValues?.payment||null}

          />
          </Col>
          
        </Row>
            <Row>
              <TablePagination
                    page={page}
                    data={data}
                    tableId="jobfaircompanytableDetailview"
                    onChange={handlePageChange}
                    count={totalPage}
                    totalCount={totalCount}
                />
            </Row>
        </>
    )
}

export default Registrations
