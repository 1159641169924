import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import queryString from "query-string"

import toastr from "toastr"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  dateConverter,
  getDate,
  jsonToExcel,
  renderTableValue,
  toTop,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"
import { APPLICATION_STATUS, OFFER_LETTER, statusOptions } from "./helper"

import "./styles.scss"
import LoadingButton from "../../../components/Common/LoadingButton"

const MappedReport = props => {
  const todayDate = getDate()

  const formRef = useRef()

  const [tableData, setTableData] = useState([])

  const [companyOptions, setCompanyOptions] = useState([])
  const [internshipOptions, setInternshipOptions] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [loading, setLoading] = useState({ export: false })
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [masterObject]) // eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleInternshipOptions()
  }, []) // eslint-disable-line

  const handleCompanyOptions = async () => {
    try {
      const response = await get(`report/options/mapped/company`)
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleInternshipOptions = async (obj = masterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`report/options/mapped/internship?${params}`)
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(masterObject)}`
      const response = await get(`report/mapped${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        const status = APPLICATION_STATUS[item.status]

        const isRejected =
          item.status === APPLICATION_STATUS.REJECTED_FROM_APPLICATION ||
          item.status === APPLICATION_STATUS.REJECTED_FROM_SHORTLIST

        const candidate = item.candidate || {}
        const company = item.company || {}
        const internship = item.internship || {}

        item.candidate = `${candidate.firstName || ""} ${candidate.lastName || ""}`
        item.candidateId = renderTableValue(candidate.uniqueId)
        item.mobile = renderTableValue(candidate.mobile)
        item.email = renderTableValue(candidate.email)
        item.state = renderTableValue(candidate.state)
        item.district = renderTableValue(candidate.district)

        item.specialization = renderTableValue(item.specialization)
        item.course = renderTableValue(item.course)

        item.inst = renderTableValue(item.college.name)
        item.cgpa = renderTableValue(candidate.cgpa ? candidate.cgpa.toFixed(2) : "0.00")
        item.company = renderTableValue(company?.name)
        item.industry = renderTableValue(company?.industry)

        item.internship = renderTableValue(internship?.name)
        item.role = renderTableValue(internship?.role)

        item.startDate = renderTableValue(dateConverter(internship?.startDate))
        item.endDate = renderTableValue(dateConverter(internship?.endDate))

        if (status) {
          item.status = (
            <Button size="sm" color={status.color} disabled>
              {status.text}
            </Button>
          )
        } else item.status = renderTableValue("")

        item.applied = renderTableValue(dateConverter(item.date))
        item.shortlisted = renderTableValue(
          dateConverter(item.shortlisted?.date),
        )
        item.completed = renderTableValue(dateConverter(item.completed?.date))
        item.withdrawn = renderTableValue(dateConverter(item.withdrawn?.date))
        item.rejected = renderTableValue(dateConverter(item.rejected?.date))
        item.placed = renderTableValue(dateConverter(item.placed?.date))

        item.offerLetter = OFFER_LETTER[item.offerLetter?.status] || ""
        item.remarks = renderTableValue(isRejected ? item.remarks : "")
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTotalCount(count)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleExport = async () => {
    try {
      setLoading(prev => ({ ...prev, export: true }))
      const query = `?limit=${totalCount}&${queryString.stringify(masterObject)}`
      const response = await get(`report/mapped${query}`)

      const { data } = response


      const exports = []
      if (!data.length) {
        toastr.info(`There are no reports to export`)
        return
      }

      data.map(item => {
        const exportData = {}

        const status = APPLICATION_STATUS[item.status]
        const candidate = item.candidate || {}
        const company = item.company || {}
        const internship = item.internship || {}

        exportData.Company = company?.name || ""
        exportData.Industry = company?.industry || ""
        exportData.Internship = internship?.name || ""

        exportData.Candidate = `${candidate.firstName || ""} ${candidate.lastName || ""}`
        exportData["Candidate Id"] = candidate.uniqueId || ""
        exportData.Mobile = candidate.mobile
        exportData.Email = candidate.email
        exportData.State = candidate.state
        exportData.District = candidate.district
        exportData.Institute = item.college.name
        exportData.CGPA = candidate.cgpa ? candidate.cgpa.toFixed(2) : "0.00";
         exportData.Course = item.course
        exportData.Specialization = item.specialization

        exportData.Role = item.role
        exportData["Current Status"] = status?.text || ""

        exportData["Offer Letter"] =
          OFFER_LETTER[item.offerLetter?.status] || ""

        exportData["Start Date"] = dateConverter(internship.startDate)
        exportData["End Date"] = dateConverter(internship.endDate)

        exportData.Applied = dateConverter(item.date)
        exportData.Shortlisted = dateConverter(item.shortlisted?.date)
        exportData.Placed = dateConverter(item.placed?.date)
        exportData.Completed = dateConverter(item.completed?.date)
        exportData.Withdrawn = dateConverter(item.withdrawn?.date)
        exportData.Rejected = dateConverter(item.rejected?.date)
        exportData.Remarks = dateConverter(item.remarks)

        exports.push(exportData)

        return item
      })

      jsonToExcel(exports, `candidate-mapped`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }

  const handleSelectChange = async ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "company") {
      obj.internship = null
    }
    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value

    if (name === "company") {
      obj.internship = null
      handleInternshipOptions(obj)
    }
    setMasterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    formRef.current.reset()

    setTotalPage(1)
    toTop()

    setSelectedFields({})
    setMasterObject({})

    handleCompanyOptions()
    handleInternshipOptions()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Company", field: "company" },
      { label: "Industry", field: "industry" },
      { label: "Internship", field: "internship" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "State", field: "state" },
      { label: "District", field: "district" },
      { label: "Institute", field: "inst" },
      { label: "CGPA", field: "cgpa" },
      { label: "Course", field: "course" },
      { label: "Specialization", field: "specialization" },
      { label: "Role", field: "role" },
      { label: "Current Status", field: "status" },
      { label: "Offer Letter", field: "offerLetter" },
      { label: "startDate", field: "startDate" },
      { label: "endDate", field: "endDate" },
      { label: "Applied", field: "applied" },
      { label: "Shortlisted", field: "shortlisted" },
      { label: "Placed", field: "placed" },
      { label: "Completed", field: "completed" },
      { label: "Withdrawn", field: "withdrawn" },
      { label: "Rejected", field: "rejected" },
      { label: "Remarks", field: "remarks" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Candidate Mapped Reports" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={masterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={masterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={masterObject.from}
                    max={todayDate}
                  />
                </Col>
                <Col md={3}>
                  <Label>Company</Label>
                  <Select
                    menuPosition="fixed"
                    options={companyOptions}
                    value={selectedFields.company || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "company" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>Internship</Label>
                  <Select
                    menuPosition="fixed"
                    options={internshipOptions}
                    value={selectedFields.internship || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "internship" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    menuPosition="fixed"
                    options={statusOptions}
                    value={selectedFields.status || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "status" })
                    }
                  />
                </Col>
                <Col col={3}>
                  <div className="d-flex gap-3 ">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                    <LoadingButton
                      color="warning"
                      onClick={handleExport}
                      loading={loading.export}
                    >
                      Export
                    </LoadingButton>
                  </div>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              tableId="candidateMappedReport"
              page={page}
              onChange={handlePageChange}
              data={data}
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default MappedReport
