import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"
import queryString from "query-string"
import "./Jobfairstyle.scss"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"

import {
    dateConverter,
    renderTableValue,
    setTitle,
    toTop,
} from "../../helpers/functions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import SearchField from "../../components/Common/SearchField"
import "./Jobfairstyle.scss"
import { get, post, put } from "../../helpers/api_helper"
import { useNavigate } from "react-router-dom"


const ManageCompany = () => {
    const navigate = useNavigate()
    const formRef = useRef()
    const [jobFairOptions, setjobFairOptions] = useState([])
    const [selectValues, setselectValues] = useState({})
    const [companyoptions, setcompanyoptions] = useState([])
    const [masterObj, setmasterObj] = useState({})
    const [tableData, settableData] = useState([])

    const limit = 10
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    const [selectFilterValue, setselectFilterValue] = useState({})
    const [filterObj, setfilterObj] = useState({})
    const [filterCompanyOptions, setfilterCompanyOptions] = useState([])
    const [filterJobfairOption, setfilterJobfairOption] = useState([])

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "Job Fair", field: "name" },
            { label: "ID", field: "uniqueId" },
            { label: "Company", field: "companyname" },
            { label: "Source", field: "source" },
            { label: "Status", field: "options" },
            { label: "View", field: "view" },


        ],
        rows: tableData,
    }

    useEffect(() => {
        getAllJobFair()
        getAllFilterJobfair()
        getAllfilterCompany()
    }, [])

    useEffect(() => {
        getAllRegistration()
    }, [page, filterObj])


    function getAllJobFair() {
        get(`job-fair/manage-company/options`).then((res) => {
            setjobFairOptions(res.data)
        }).catch((err) => {

        })
    }

    function handleselectchange(name, select) {
        setselectValues({ ...selectValues, [name]: select })
        if (name == "jobFair") getAllcompanies(select.value)
        if (name == "companies") {
            setmasterObj({ ...masterObj, [name]: select?.map(item => item.value) })
        } else {
            setmasterObj({ ...masterObj, [name]: select.value })
        }
    }


    function getAllcompanies(id) {
        get(`job-fair/manage-company/options/companies?jobFair=${id}`).then((res) => {
            setcompanyoptions(res.data)
        }).catch((err) => {

        })
    }

    function handleSubmit() {
        post(`job-fair/manage-company/register`, masterObj).then((res) => {
            toastr.success(res.message)
            setselectValues({})
            setmasterObj({})
            getAllRegistration()
        }).catch((err) => {
            toastr.error(err.response.data.message)
        })
    }

    function getAllRegistration() {
        get(`job-fair/manage-company?page=${page}&limit=${limit}&jobFair=${filterObj?.jobFair}&company=${filterObj?.company}&status=${filterObj?.status}`).then((res) => {
            const { data, count } = res
            data.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = dateConverter(item.date)
                item.name = item?.jobFair?.name
                item.source = item?.from == "1" ? "CRM" : "WEB"
                item.companyname = item?.company?.name
                item.options = (
                    <>
                        {item?.status == "0" ? (
                            <Select onChange={(selected) => { handlestatusChnage("status", selected.value, item._id) }} options={[{ label: "Approve", value: "1" }, { label: "Reject", value: "2" }]} />
                        ) : item?.status == "1" ? (
                            <Button size="sm" color="success" disabled>
                                Approved
                            </Button>
                        ) : item?.status == "2" ? (
                            <Button size="sm" color="danger" disabled>
                                Rejected
                            </Button>
                        ) : null}
                    </>
                )
                item.view = (
                    <>
                        <div>
                            <Tooltip title="View" arrow placement="top">
                                <i
                                    className="fas fa-eye eye-icon"
                                    onClick={() => {
                                        navigate(`/job-fair/manage-company/details/${item?.uniqueId}`, {
                                            state: { id: item?.uniqueId },
                                        })
                                    }}
                                ></i>
                            </Tooltip>
                        </div>
                    </>
                )
                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            settableData(data)
        }).catch((err) => {

        })
    }

    const handlePageChange = value => {
        setPage(value)
    }

    function handlestatusChnage(name, value, id) {
        put(`job-fair/manage-company/status`, { id: id, status: value }).then((res) => {
            toastr.success(res.message)
            getAllRegistration()

        }).catch((err) => {
            toastr.error(err.response.data.message)
        })
    }

    function getAllFilterJobfair() {
        get(`options/jobfair`).then((res) => {
            setfilterJobfairOption(res.data)
        }).catch((err) => {
        })
    }

    function getAllfilterCompany(id) {
        get(`job-fair/manage-company/filter?jobFair=${id}`).then((res) => {
            setfilterCompanyOptions(res.data)
        }).catch((err) => {

        })
    }

    function handleFilterValueChange(name, select) {
        setselectFilterValue({ ...selectFilterValue, [name]: select })
        setfilterObj({ ...filterObj, [name]: select.value })
        if (name == "jobFair") getAllfilterCompany(select.value)
    }
    function filterReset() {
        setfilterObj({})
        setselectFilterValue({})
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Company" />

                    <Card>
                        <CardBody>
                            <AvForm
                                ref={formRef}
                                onValidSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col md={3}>
                                        <Label>Select Job Fair</Label>
                                        <Select
                                            options={jobFairOptions}
                                            onChange={(selected) => {
                                                handleselectchange("jobFair", selected)
                                            }}
                                            value={selectValues?.jobFair || null}

                                        />
                                    </Col>

                                    <Col md={3}>
                                        <Label>Select Company</Label>
                                        <Select
                                            isMulti
                                            options={companyoptions}
                                            value={selectValues?.companies || null}
                                            onChange={(selected) => {
                                                handleselectchange("companies", selected)
                                            }}
                                        />
                                    </Col>

                                    <Col md={3} style={{ marginTop: "26px" }}>
                                        <Button type="submit" color="primary" >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </AvForm>

                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <Row>
                                <Col md="3">
                                    <Label>Job Fair</Label>
                                    <Select
                                        options={filterJobfairOption}
                                        onChange={(selected) => {
                                            handleFilterValueChange("jobFair", selected)
                                        }}
                                        value={selectFilterValue?.jobFair || null}
                                    />
                                </Col>
                                <Col md="3">
                                    <Label>Company</Label>
                                    <Select
                                        options={filterCompanyOptions}
                                        onChange={(selected) => {
                                            handleFilterValueChange("company", selected)
                                        }}
                                        value={selectFilterValue?.company || null}

                                    />
                                </Col>
                                <Col md="2">
                                    <Label>Status</Label>
                                    <Select
                                        options={[{ label: "Pending", value: 0 }, { label: "Approved", value: 1 }, { label: "Rejected", value: 2 }]}
                                        onChange={(selected) => {
                                            handleFilterValueChange("status", selected)
                                        }}
                                        value={selectFilterValue?.status || null}
                                    />
                                </Col>
                                <Col md="2">
                                    <div className="mt-4" style={{ display: "flex", gap: "5px" }}>
                                        <Button color="danger" type="button" onClick={filterReset}>Reset</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <TablePagination
                                    tableId="jobfaircompanyTable"
                                    page={page}
                                    onChange={handlePageChange}
                                    data={data}
                                    count={totalPage}
                                    totalCount={totalCount}
                                />
                            </Row>

                        </CardBody>
                    </Card>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default ManageCompany
