import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Container, Card, Button } from "reactstrap"
import { Tooltip } from "@mui/material"

import Breadcrumb from "../../../components/Common/Breadcrumb"

import { useParams } from "react-router-dom"
import { typeValues } from "../TestCreation/helper"
import moment from "moment"

import { get } from "../../../helpers/api_helper"
import {
  dateConverter,
  renderTableValue,
  timeConverter,
} from "../../../helpers/functions"

import "./styles.scss"
import TablePagination from "../../../components/Common/TablePagination"
import { FOR_VALUES, STATUS_VALUES } from "../AssignTest/helper"
import { resultValues, testStatusValues } from "../TestResults/helper"

const AssignTestDetails = () => {
  const { id } = useParams()
  const now = moment()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [details, setDetails] = useState({})

  useEffect(() => {
    if (id) handleData()
  }, [id]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`screening-assign/details?id=${id}`)
      const data = response.data

      data.typeText = typeValues[data.screeningTest?.type]
      data.assignForText = FOR_VALUES[data.assignFor]

      data.date = dateConverter(data.date) || "-"
      data.time = timeConverter(data.time) || "-"

      data.startDate = dateConverter(data.startDate) || "-"
      data.endDate = dateConverter(data.endDate) || "-"

      data.startTime = timeConverter(data.startTime) || "-"
      data.endTime = timeConverter(data.endTime) || "-"

      const start = moment(data.startDateAndTime, "YYYY-MM-DD HH:mm", true)
      const end = moment(data.endDateAndTime, "YYYY-MM-DD HH:mm", true)

      if (data.status !== STATUS_VALUES.IN_ACTIVE) {
        if (end.isBefore(now)) {
          data.status = STATUS_VALUES.COMPLETED
        } else if (start.isSameOrBefore(now)) {
          data.status = STATUS_VALUES.ONGOING
        }
      }

      data.status = STATUS_VALUES[data.status]?.text || ""
      setDetails(data)

      handleTableData({ id: data._id })
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async ({
    currentPage = page,
    id = details._id,
  } = {}) => {
    try {
      if (!id) return
      const query = `?page=${currentPage}&limit=${limit}&id=${id}`

      const response = await get(`screening-assign/details/result${query}`)
      const { count, data } = response

      const STATUS_VALUES = {
        0: "Completed",
        1: "Absent",
        2: "Ongoing",
        3: "Not Started",
        COMPLETED: 0,
        ABSENT: 1,
        ONGOING: 2,
        NOT_STARTED: 3,
      }

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.candidateId = renderTableValue(item.candidate?.uniqueId)
        item.candidate = renderTableValue(item.candidate?.name)

     
          item.general = (
            <Tooltip title={`Mark/Total`} arrow placement="top">
              {Number(item?.markObtained?.general || 0).toFixed(0)}/
              {Number(item?.generalMark || 0).toFixed(0)}
            </Tooltip>
          )
       
        item.percentage = `${(item?.markObtained?.percentage || 0).toFixed(2)}%`
        item.specialization = (
          <Tooltip title={`Mark/Total`} arrow placement="top">
            {Number(item?.markObtained?.specialization || 0).toFixed(0)}/
            {Number(item?.maxMark || 0).toFixed(0)}
          </Tooltip>
        )

        let status = STATUS_VALUES.COMPLETED
        if (item.status === testStatusValues.ABSENT)
          status = STATUS_VALUES.ABSENT
        else if (item.status === testStatusValues.ONGOING)
          status = STATUS_VALUES.ONGOING
        else if (item.status === testStatusValues.NOT_STARTED)
          status = STATUS_VALUES.NOT_STARTED

        item.status = renderTableValue(STATUS_VALUES[status])
        item.type = renderTableValue(typeValues[item.type])

        const result = resultValues[item.result]

        if (result) {
          item.result = (
            <Button size="sm" disabled color={result?.color}>
              {result?.text}
            </Button>
          )
        } else item.result = renderTableValue("")
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData({ currentPage: value })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      // { label: "Type", field: "type" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Candidate", field: "candidate" },
      { label: "Status", field: "status" },
      { label: "General", field: "general" },
      { label: "Specialization", field: "specialization" },
      { label: "Percentage", field: "percentage" },
      { label: "Result", field: "result" },
    ],
    rows: tableData,
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Test Result Details" />

        <Row>
          <Col lg={3}>
            <Card className="card-shadow">
              <CardBody>
                <InfoRow label="Assign For" value={details?.assignForText} />
                <InfoRow label="Unique Id" value={details?.uniqueId} />

                <InfoRow
                  label="Screening Test"
                  style={{}}
                  value={details.screeningTest?.name}
                />
                <InfoRow
                  label="Screening id"
                  value={details.screeningTest?.uniqueId}
                />
                <InfoRow label="Type" value={details?.typeText} />
                <InfoRow label="Start Date" value={details.startDate} />
                <InfoRow label="Start Time" value={details.startTime} />
                <InfoRow label="End Date" value={details.endDate} />
                <InfoRow label="End Time" value={details.endTime} />
                <InfoRow label="Status" value={details.status} />
                <InfoRow
                  label="Internship"
                  value={details.internship?.name}
                  hidden={details?.assignFor !== FOR_VALUES.INTERNSHIP}
                />
                <InfoRow
                  label="Specializations"
                  style={{}}
                  hidden={details?.assignFor !== FOR_VALUES.KTU}
                  value={
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {Array.isArray(details?.specializations) &&
                        details?.specializations?.map((item, index) => (
                          <li key={index}>
                            {item.name}{" "}
                            {index !== details.specializations.length - 1
                              ? ", "
                              : ""}
                          </li>
                        ))}
                    </ul>
                  }
                />
                {/* <InfoRow
                  label="Candidates"
                  style={{}}
                  value={
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {Array.isArray(details?.candidates) &&
                        details?.candidates?.map((item, index) => (
                          <li key={index}>
                            {item.name}{" "}
                            {index !== details.candidates.length - 1
                              ? ", "
                              : ""}
                          </li>
                        ))}
                    </ul>
                  }
                /> */}

                <InfoRow label={"Added By"} value={details.addedBy?.name} />
                <InfoRow label={"Date"} value={details.date} />
                <InfoRow label={"Time"} value={details.time} />
              </CardBody>
            </Card>
          </Col>
          <Col lg={9} style={{ marginBottom: "30px" }}>
            <Card>
              <CardBody>
                <TablePagination
                  page={page}
                  tableId="assignTestDetails"
                  data={data}
                  count={totalPage}
                  onChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AssignTestDetails

const InfoRow = ({
  label,
  value,
  hidden,
  style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}) => {
  if (hidden) return null
  return (
    <>
      <div className="mt-2" style={style}>
        <p className="mb-1">{label}:</p>
        <h5
          className="font-size-14 mb-0 text-wrap"
          style={{ wordBreak: "break-word", maxWidth: "100%" }}
        >
          {value || "-"}
        </h5>
      </div>
      <hr style={{ margin: "0", color: "#00afc3" }} />
    </>
  )
}
