import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Container, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import Breadcrumb from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import { get, put } from "../../../helpers/api_helper"
import {
  dateConverter,
  getDate,
  jsonToExcel,
  numberToString,
  renderTableValue,
} from "../../../helpers/functions"

import "./styles.scss"
import { useNavigate } from "react-router-dom"

const RankList = () => {
  const formRef = useRef()
  const todayDate = getDate()

  const navigate = useNavigate()

  const statusOptions = [
    { label: "Published", value: 0 },
    { label: "Not Published", value: 2 },
  ]

  const STATUS_VALUES = {
    PUBLISHED: 0,
    NOT_PUBLISHED: 2,
    0: { text: "Published", color: "success" },
    2: { text: "Not published", color: "danger" },
  }

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [loading, setloading] = useState(false)
  const [totalcount, settotalcount] = useState()

  const [screeningTestOptions, setScreeningTestOptions] = useState([])

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [masterObject]) // eslint-disable-line

  useEffect(() => {
    handleScreeningTestOptions()
  }, []) // eslint-disable-line

  const handleScreeningTestOptions = async () => {
    try {
      const response = await get(`rank-list/options/test`)
      setScreeningTestOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(masterObject)}`

      const response = await get(`rank-list${query}`)
      const { count, data } = response
      settotalcount(count)

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.date = dateConverter(item.date)
        item.ranks = numberToString(item.totalRanks)

        item.test = renderTableValue(item.screeningTest?.name)
        item.staff = renderTableValue(item.addedBy?.name)

        const status = STATUS_VALUES[item.status]

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => handleNavigate(item.uniqueId)}
              ></i>
            </Tooltip>
            {item.status === STATUS_VALUES.NOT_PUBLISHED ? (
              <Tooltip title="Publish" arrow placement="top">
                <i
                  className="fa fa-share-alt share-icon"
                  onClick={() => handlePublish(item._id)}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        )

        if (status) {
          item.status = (
            <Button size="sm" disabled color={status.color}>
              {status.text}
            </Button>
          )
        } else item.status = ""

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }


  const handleExportData = async () => {
    setloading(true)
    try {
      const query = `?page=1&limit=${totalcount}&${queryString.stringify(masterObject)}`

      const response = await get(`rank-list${query}`)
      const { data } = response
      const exports = []
      data.map((item, index) => {
        const exportItem = {}
        exportItem["Sl.no"] = index + 1
        exportItem.Date = dateConverter(item.date)
        exportItem.ID = item.uniqueId
        exportItem.Ranks = numberToString(item.totalRanks)
        exportItem.Test = renderTableValue(item.screeningTest?.name)
        // exportItem.staff = renderTableValue(item.addedBy?.name)
        const status = STATUS_VALUES[item.status]
        if (status) {
          exportItem.status = status.text
        } else item.status = ""
        exports.push(exportItem)
        return item
      })
      jsonToExcel(exports, `Ranklist `)
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }

  const handleNavigate = url => {
    navigate(`/rank-list-results/${url}`)
  }

  const handlePublish = async id => {
    try {
      const response = await put(`rank-list/publish`, { id })
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    formRef.current?.reset()

    setMasterObject({})
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Test", field: "test" },
      { label: "Unique Id", field: "uniqueId" },
      { label: "Ranks", field: "totalRanks" },
      { label: "Status", field: "status" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Rank list" />
        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={masterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={masterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={masterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={3}>
                  <Label>Test</Label>
                  <Select
                    options={screeningTestOptions}
                    value={selectedFields.screeningTest || ""}
                    onChange={selected =>
                      handleSelectValueChange({
                        selected,
                        name: "screeningTest",
                      })
                    }
                    isMulti
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    options={statusOptions}
                    value={selectedFields.status || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "status" })
                    }
                    isClearable
                  />
                </Col>
                <Col md={2} className="form-button  d-flex gap-2">
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                  <Button color="warning"
                    onClick={handleExportData}
                  >
                    {loading ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      "Export"
                    )}
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination 
              page={page}
              tableId="rankList"
              data={data}
              count={totalPage}
              onChange={handlePageChange}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default RankList
