import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"
import queryString from "query-string"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import {
    dateConverter,
    renderTableValue,
    setTitle,
    toTop,
} from "../../helpers/functions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import SearchField from "../../components/Common/SearchField"
import "./Jobfairstyle.scss"
import { del, get, post, put } from "../../helpers/api_helper"
import { workTypeOptions, workModeOptions, genderOptions, scheduleOptions } from "../Job/Post/helper"
import moment from "moment"
import { Link } from "react-router-dom"

const JobfairJobpost = () => {
    const formRef = useRef()
    const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })
    const [tableData, settableData] = useState([])
    const [master, setmaster] = useState({})
    const [jobFairOptions, setjobFairOptions] = useState([])
    const [companyoptions, setcompanyoptions] = useState([])
    const [selectValues, setselectValues] = useState({})
    const [branchOptions, setbranchOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [skillsOptions, setSkillsOptions] = useState([])
    const [courseOptions, setCourseOptions] = useState([])
    const [specialisationOptions, setSpecialisationOptions] = useState([])
    const [stateOptions, setStateOptions] = useState([])
    const [districtOptions, setDistrictOptions] = useState([])

    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    const [selectFilterValue, setselectFilterValue] = useState({})
    const [filterObj, setfilterObj] = useState({})
    const [filterCompanyOptions, setfilterCompanyOptions] = useState([])
    const [filterJobfairOption, setfilterJobfairOption] = useState([])

    useEffect(() => {
        getAllJobFair()
        handleCategoryOptions()
        handleSkillsOptions()
        handleCourseOptions()
        handleSpecialisationOptions()
        handleStateOptions()
        getAllFilterJobfair()
        getAllfilterCompany()
    }, [])

    useEffect(() => {
        fetchAllJobList()
    }, [page, filterObj])

    function getAllJobFair() {
        get(`options/jobfair`).then((res) => {
            setjobFairOptions(res.data)
        }).catch((err) => {

        })
    }

    function getAllcompanies(id) {
        get(`job-fair/job/options/companies?jobFair=${id}`).then((res) => {
            setcompanyoptions(res.data)
        }).catch((err) => {

        })
    }

    function getbranch(id) {
        get(`options/branch?company=${id}`).then((res) => {
            setbranchOptions(res.data)
        }).catch((err) => {

        })
    }

    const handleCategoryOptions = async () => {
        try {
            const response = await get(`options/category`)
            setCategoryOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleSkillsOptions = async (category = "") => {
        try {
            const response = await get(`options/skills?category=${category}`)
            setSkillsOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }
    const handleCourseOptions = async () => {
        try {
            const response = await get(`options/course`)

            setCourseOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleSpecialisationOptions = async () => {
        try {
            const response = await get(`options/specializations`)

            setSpecialisationOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleStateOptions = async () => {
        try {
            const response = await get(`options/states`)
            setStateOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleDistrictOptions = async (state = "") => {
        try {
            const params = queryString.stringify({ state })
            const response = await get(`options/districts?${params}`)
            setDistrictOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "ID", field: "uniqueId" },
            { label: "Job Fair", field: "fair" },
            { label: "Company", field: "company1" },
            { label: "Name", field: "name" },
            // { label: "Added By", field: "staff" },
            { label: "Approval", field: "statusapprove" },
            { label: "Show", field: "statushow" },
            { label: "Action", field: "options" },
        ],
        rows: tableData,
    }

    function handleSelectChange(name, select) {
        if (name == "jobFair") getAllcompanies(select.value)
        if (name == "company") getbranch(select.value)
        setselectValues({ ...selectValues, [name]: select })
        setmaster({ ...master, [name]: select.value })
    }

    function handlevaluechange(e) {
        let { name, value } = e.target
        setmaster({ ...master, [name]: value })
    }

    function handleSelectChangeMulti(name, select) {
        if (name == "state") handleDistrictOptions(select.map(item => item.value))
        setselectValues({ ...selectValues, [name]: select })
        setmaster({ ...master, [name]: select.map(item => item.value) })
    }

    function handleSubmit() {
        if (master.id) {
            put(`job-fair/job`, master).then((res) => {
                toastr.success(res.message)
                formRef.current.reset()
                setmaster({})
                setselectValues({})
                fetchAllJobList()
            }).catch((err) => {

                toastr.error(err.response.data.message)
            })
        } else {
            post(`job-fair/job`, master).then((res) => {
                toastr.success(res.message)
                formRef.current.reset()
                setmaster({})
                setselectValues({})
                fetchAllJobList()
            }).catch((err) => {
                toastr.error(err.response.data.message)
            })
        }
    }

    function handleReset() {
        formRef.current.reset()
        setmaster({})
        setselectValues({})
    }

    function fetchAllJobList() {
        get(`job-fair/job?page=${page}&limit=${limit}&jobFair=${filterObj?.jobFair}&company=${filterObj?.company}&search=${filterObj?.search}`).then((res) => {
            let result = res.data;
            const count = res.count
            result.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"
                item.company1 = item?.company?.name
                item.fair = item?.jobFair?.name
                item.options = (
                    <>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>


                            <Link to={`/job-fair/job/details/${item.uniqueId}`}>
                                <Tooltip title="View" arrow placement="top">
                                    <i
                                        style={{ color: "rgb(52, 55, 71)" }}
                                        className="fas fa-eye eye-icon"
                                    ></i>
                                </Tooltip>
                            </Link>
                            <Tooltip title="Edit" arrow placement="top">
                                <i
                                    className="fas fa-edit edit-icon"
                                    onClick={() => handleUpdate(item._id)}
                                ></i>
                            </Tooltip>
                            <Tooltip title="Delete" arrow placement="top">
                                <i
                                    className="fas fa-trash-alt delete-icon"
                                    onClick={() => handleDelete(item._id)}
                                ></i>
                            </Tooltip>
                        </div>

                    </>
                )
                item.statusapprove = (
                    <>
                        {item?.approvalStatus == "3" ? (
                            <Select onChange={(selected) => { handlestatusChnage("status", selected.value, item._id) }} options={[{ label: "Approve", value: "1" }, { label: "Reject", value: "2" }]} />
                        ) : item?.approvalStatus == "1" ? (
                            <Button size="sm" color="success" disabled>
                                Approved
                            </Button>
                        ) : item?.approvalStatus == "2" ? (
                            <Button size="sm" color="danger" disabled>
                                Rejected
                            </Button>
                        ) : null}
                    </>
                )
                item.statushow = (
                    <>
                        <div>
                            <input onClick={() => {
                                handleShowStatuschange(item._id, item.show)

                            }}
                                type="checkbox" checked={item?.show} />
                        </div>
                    </>
                )
                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            settableData(result)
        })
    }

    const handlePageChange = value => {
        setPage(value)
    }

    const handleDelete = async id => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            })
            if (!result.isConfirmed) return
            const response = await del(`job-fair/job?id=${id}`)
            toastr.success(response.message)
            fetchAllJobList()
        } catch (error) {
            toastr.error(error.message)
        }
    }

    function handleUpdate(id) {
        toTop()
        get(`job-fair/job/single?id=${id}`).then((res) => {
            let result = res.data
            setselectValues({
                branch: result?.branch || [],
                category: result?.category || [],
                company: result?.company || [],
                courses: result?.courses || [],
                district: result?.district || [],
                jobFair: result?.jobFair || [],
                skills: result?.skills || [],
                specializations: result?.specializations || [],
                district: result?.district || [],
                state: result?.state || [],
                workType: workTypeOptions.find(option => option.value === result.workType) || null,
                workMode: workModeOptions.find(option => option.value === result.workMode) || null,
                workType: workTypeOptions.find(option => option.value === result.workType) || null,
                schedule: scheduleOptions.find(option => option.value === result.schedule) || null,
                gender: result.gender?.map(option => genderOptions[option - 1])

            })
            result.id = result._id
            delete result._id
            result.jobFair = result.jobFair.value;
            result.branch = result.branch.value;
            result.company = result.company.value;
            result.category = result.category.value;
            result.skills = result.skills.map(item => item.value);
            result.courses = result.courses.map(item => item.value);
            result.specializations = result.specializations.map(item => item.value);
            result.state = result.state.map(item => item.value);
            result.district = result.district.map(item => item.value);
            setmaster(result)
        })
    }
    function handlestatusChnage(name, value, id) {
        put(`job-fair/job/approval`, { id: id, status: value }).then((res) => {
            toastr.success(res.message)
            fetchAllJobList()
        }).catch((err) => {
            toastr.error(err.response.data.message)
        })
    }
    function handleShowStatuschange(id, status) {
        put(`job-fair/job/show`, { id: id, checked: !status }).then((res) => {
            toastr.success(res.message)
            fetchAllJobList()
        }).catch((err) => {
            toastr.error(err.response.data.message)
        })
    }


    function getAllFilterJobfair() {
        get(`options/jobfair`).then((res) => {
            setfilterJobfairOption(res.data)
        }).catch((err) => {
        })
    }

    function getAllfilterCompany(id) {
        get(`job-fair/manage-company/filter?jobFair=${id}`).then((res) => {
            setfilterCompanyOptions(res.data)
        }).catch((err) => {

        })
    }

    function handleFilterValueChange(name, select) {
        setselectFilterValue({ ...selectFilterValue, [name]: select })
        setfilterObj({ ...filterObj, [name]: select.value })
        if (name == "jobFair") getAllfilterCompany(select.value)
    }
    function filterReset() {
        setfilterObj({})
        setselectFilterValue({})
    }


    console.log(filterObj, "filter");


    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Job Fair Job Post" />
                    <Row>
                        <Card>
                            <CardBody>
                                <AvForm
                                    onValidSubmit={handleSubmit}
                                    ref={formRef}
                                >
                                    <Row>
                                        <Col md={3}>
                                            <Label>Select Job Fair <span className="required">*</span></Label>
                                            <Select
                                                options={jobFairOptions}
                                                onChange={(selected) => {
                                                    handleSelectChange("jobFair", selected)
                                                }}
                                                value={selectValues?.jobFair || null}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Company<span className="required">*</span></Label>
                                                <Select
                                                    // menuPosition="fixed"
                                                    options={companyoptions}
                                                    onChange={(selected) => {
                                                        handleSelectChange("company", selected)
                                                    }}
                                                    isDisabled={!selectValues?.jobFair}
                                                    value={selectValues?.company || null}

                                                />
                                            </div>
                                        </Col>
                                        <Col md={3} className="mb-3">
                                            <Label>
                                                Branch
                                                <span className="required">*</span>
                                            </Label>
                                            <Select
                                                name="branch"
                                                menuPosition="fixed"
                                                options={branchOptions}
                                                isDisabled={!selectValues?.company}
                                                onChange={(selected) => {
                                                    handleSelectChange("branch", selected)
                                                }}
                                                value={selectValues?.branch || null}
                                            />
                                        </Col>

                                        <Col md="3">
                                            <Label>Name<span className="required">*</span></Label>
                                            <AvField
                                                name="name"
                                                type="text"
                                                placeholder="Name"
                                                onChange={handlevaluechange}
                                                value={master?.name || ""}
                                                errorMessage="Enter Job Name"
                                                validate={{ required: { value: true } }}

                                            />
                                        </Col>

                                        <Col md={3} className="mb-3">
                                            <Label>Category</Label>
                                            <Select
                                                name="category"
                                                options={categoryOptions}
                                                onChange={(selected) => {
                                                    handleSelectChange("category", selected)
                                                }}
                                                value={selectValues?.category || null}

                                            />
                                        </Col>
                                        <Col md={3} className="mb-3">
                                            <Label>Skills</Label>
                                            <Select
                                                isMulti
                                                options={skillsOptions}
                                                onChange={(selected) => {
                                                    handleSelectChangeMulti("skills", selected)
                                                }}
                                                value={selectValues?.skills || null}

                                            />
                                        </Col>

                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Work Type<span className="required">*</span></Label>
                                                <Select
                                                    name="workType"
                                                    options={workTypeOptions}
                                                    onChange={(selected) => {
                                                        handleSelectChange("workType", selected)
                                                    }}
                                                    value={selectValues?.workType || null}

                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Work mode<span className="required">*</span></Label>
                                                <Select
                                                    options={workModeOptions}
                                                    onChange={(selected) => {
                                                        handleSelectChange("workMode", selected)
                                                    }}
                                                    value={selectValues?.workMode || null}

                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <Label>Courses<span className="required">*</span></Label>
                                            <Select
                                                isMulti
                                                options={courseOptions}
                                                onChange={(selected) => {
                                                    handleSelectChangeMulti("courses", selected)
                                                }}
                                                value={selectValues?.courses || null}

                                            />
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Specialisation<span className="required">*</span></Label>
                                                <Select
                                                    isMulti
                                                    onChange={(selected) => {
                                                        handleSelectChangeMulti("specializations", selected)
                                                    }}
                                                    options={specialisationOptions}
                                                    value={selectValues?.specializations || null}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>
                                                    Minimum Experience (in years)<span className="required">*</span>
                                                </Label>
                                                <AvField
                                                    name="minExperience"
                                                    type="number"
                                                    placeholder="Minimum Experience"
                                                    onChange={handlevaluechange}
                                                    value={master?.minExperience || ""}


                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>
                                                    Maximum Experience (in years)
                                                </Label>
                                                <AvField
                                                    name="maxExperience"
                                                    type="number"
                                                    placeholder="Maximum Experience"
                                                    onChange={handlevaluechange}
                                                    value={master?.maxExperience || ""}

                                                />
                                            </div>
                                        </Col>

                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Gender</Label>
                                                <Select
                                                    isMulti
                                                    name="gender"
                                                    options={genderOptions}
                                                    onChange={(selected) => {
                                                        handleSelectChangeMulti("gender", selected)
                                                    }}
                                                    value={selectValues?.gender || null}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>No. of Openings<span className="required">*</span></Label>
                                                <AvField
                                                    name="vacancies"
                                                    type="number"
                                                    placeholder="No. of Openings"
                                                    onChange={handlevaluechange}
                                                    value={master?.vacancies || ""}
                                                    errorMessage="Enter No. of Openings "
                                                    validate={{ required: { value: true } }}


                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>
                                                    Salary Range From (&#x20B9; &nbsp;LPA)<span className="required">*</span>
                                                </Label>
                                                <AvField
                                                    name="salaryFrom"
                                                    type="number"

                                                    placeholder="Salary Range From"
                                                    onChange={handlevaluechange}
                                                    value={master?.salaryFrom || ""}

                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>
                                                    Salary Range To (&#x20B9; &nbsp;LPA)
                                                </Label>
                                                <AvField
                                                    name="salaryTo"
                                                    type="number"

                                                    errorMessage="Salary Range To must be greater than or equal to Salary Range From"
                                                    placeholder="Salary Range To"
                                                    onChange={handlevaluechange}
                                                    value={master?.salaryTo || ""}

                                                />
                                            </div>
                                        </Col>

                                        <Col md="3">
                                            <Label>State<span className="required">*</span></Label>
                                            <Select
                                                options={stateOptions}
                                                isMulti
                                                onChange={(selected) => {
                                                    handleSelectChangeMulti("state", selected)
                                                }}
                                                value={selectValues?.state || null}

                                            />
                                        </Col>
                                        <Col md="3">
                                            <Label>District<span className="required">*</span></Label>
                                            <Select
                                                options={districtOptions}
                                                isMulti
                                                onChange={(selected) => {
                                                    handleSelectChangeMulti("district", selected)
                                                }}
                                                value={selectValues?.district || null}

                                            />
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Schedule<span className="required">*</span></Label>
                                                <Select
                                                    options={scheduleOptions}
                                                    onChange={(selected) => {
                                                        handleSelectChange("schedule", selected)
                                                    }}
                                                    value={selectValues?.schedule || null}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="mb-3" style={{ paddingTop: "25px" }}>
                                                <Button
                                                    className="me-2"
                                                    color={master.id ? "warning" : "primary"}
                                                    type="submit"
                                                >
                                                    {master.id ? "Update" : "Submit"}
                                                </Button>
                                                <Button color="danger" type="button" onClick={handleReset} >
                                                    Reset
                                                </Button>
                                            </div>
                                        </Col>

                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <Label>Job Fair</Label>
                                        <Select
                                            options={filterJobfairOption}
                                            onChange={(selected) => {
                                                handleFilterValueChange("jobFair", selected)
                                            }}
                                            value={selectFilterValue?.jobFair || null}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Label>Company</Label>
                                        <Select
                                            options={filterCompanyOptions}
                                            onChange={(selected) => {
                                                handleFilterValueChange("company", selected)
                                            }}
                                            value={selectFilterValue?.company || null}

                                        />
                                    </Col>
                                    <Col md="3">
                                        <Label>Search</Label>
                                        <input
                                            onChange={(e) => {
                                                setfilterObj(prev => ({ ...filterObj, ["search"]: e.target.value }))
                                            }}
                                            className="form-control"
                                            placeholder="Search..."
                                            value={filterObj?.search || ""}
                                        />
                                    </Col>
                                    {/* <Col md="2">
                                        <Label>Status</Label>
                                        <Select
                                            options={[{ label: "Pending", value: 0 }, { label: "Approved", value: 1 }, { label: "Rejected", value: 2 }]}
                                            onChange={(selected) => {
                                                handleFilterValueChange("status", selected)
                                            }}
                                            value={selectFilterValue?.status || null}
                                        />
                                    </Col> */}
                                    <Col md="2">
                                        <div className="mt-4" style={{ display: "flex", gap: "5px" }}>
                                            <Button color="danger" type="button" onClick={filterReset}>Reset</Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <TablePagination
                                        page={page}
                                        data={data}
                                        tableId="jobPostTable"
                                        onChange={handlePageChange}
                                        count={totalPage}
                                        totalCount={totalCount}
                                    />

                                </Row>

                            </CardBody>
                        </Card>
                    </Row>
                </div>
            </div>
        </>

    )
}

export default JobfairJobpost
