import { STATUS } from "../helper"

export const statusOptions = [
  { label: "Shortlist", value: STATUS.SHORTLISTED },
  { label: "Reject", value: STATUS.REJECTED },
]

export const filterStatusOptions = [
  // { label: "Pending", value: STATUS.PENDING },
  { label: "Applied", value: STATUS.APPLIED },
  { label: "Shortlisted", value: STATUS.SHORTLISTED },
  { label: "Placed", value: STATUS.PLACED },
  { label: "Rejected", value: STATUS.REJECTED },
]

export const INTERNSHIP_TYPE = { KTU: 1, GENERAL: 2, PSU: 3 }
export const FORWARD = { FORWARDED: 0, NOT: 1 }

export const assessmentOptions = [
  { label: "Screening", value: 1 },
  // { label: "Interview", value: 2 },
  { label: "Interview", value: 3 },
]
