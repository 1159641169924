import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import { get } from '../../helpers/api_helper'
import TablePagination from "../../components/Common/TablePagination"
import "./Jobfairstyle.scss"
import moment from 'moment'
const RegInterestedJobs = ({ regId }) => {

    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [tableData, settableData] = useState([])

    useEffect(() => {
        fetchAllInterestedJobs()
    }, [regId])

    function fetchAllInterestedJobs() {
        get(`job-fair/candidate/interested-jobs?id=${regId}`).then((res) => {
            let result = res.data;
            const count = res.count;
            result.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"
                item.jobreg=item?.job?.name
                item.company1=item?.company?.name
                item.status=item?.status===1?"APPLIED":item?.status===2?"SHORTLISTED":item?.status===3?"PLACED":item?.status===4?"REJECTED":"---"
                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            settableData(result)

        })
    }

    const handlePageChange = value => {
        setPage(value)
    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "JobID", field: "jobId" },
            { label: "Job ", field: "job"},
        ],
        rows: tableData,
    }




    return (
        <>
            <Row>
                <TablePagination
                    page={page}
                    data={data}
                    tableId="jobregDetailTable"
                    onChange={handlePageChange}
                    count={totalPage}
                    totalCount={totalCount}
                />

            </Row>
        </>
    )
}

export default RegInterestedJobs
