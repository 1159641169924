export const statusOptions = [
  { label: "Shortlist", value: 2 },
  { label: "Reject", value: 4 },
]

export const filterStatusOptions = [
  { label: "Applied", value: 0 },
  { label: "Shortlist", value: 2 },
  { label: "Placed", value: 3 },
  { label: "Rejected", value: 4 },
  { label: "Withdrawn", value: 6 },
  { label: "Withdrawn Pending", value: 7 },
  { label: "Completed", value: 9 },
]

export const typeOptions = [
  { label: "KTU", value: 1 },
  { label: "General", value: 2 },
  { label: "Public Sector", value: 3 },
]

export const STATUS = {
  2: { text: "Shortlisted", color: "warning" },
  3: { text: "Placed", color: "success" },
  4: { text: "Rejected", color: "danger" },
  5: { text: "Rejected", color: "danger" },
  6: { text: "Withdrawn", color: "danger" },
  7: { text: "Withdrawn Pending", color: "warning" },
  9: { text: "Completed", color: "info" },
  10: { text: "Pending", color: "primary" },
  APPLIED: 1,
  PENDING: 10,
  REJECTED_APPLICATION: 4,
  REJECTED_SHORT_LIST: 5,
}

export const INTERNSHIP_TYPE = { KTU: 1, GENERAL: 2, PSU: 3 }
export const FORWARD = { FORWARDED: 0, NOT: 1 }

export const assessmentOptions = [
  { label: "Screening", value: 1 },
  // { label: "Interview", value: 2 },
  { label: "Interview", value: 3 },
]

export const offerLetterOptions = [
  { label: "Company", value: 1 },
  { label: "Asap", value: 2 },
]

export const percentageOptions = [
  { label: ">10", value: 10 },
  { label: ">20", value: 20 },
  { label: ">30", value: 30 },
  { label: ">40", value: 40 },
  { label: ">50", value: 50 },
  { label: ">60", value: 60 },
  { label: ">70", value: 70 },
  { label: ">80", value: 80 },
  { label: ">90", value: 90 },
  { label: "=100", value: 100 },

]
