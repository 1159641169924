import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import toastr from "toastr"

import TablePagination from "../../components/Common/TablePagination"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  dateConverter,
  encrypt,
  genderOptions,
  getDate,
  renderTableValue,
  setTitle,
} from "../../helpers/functions"
import { del, get, post, put } from "../../helpers/api_helper"

import "./styles.scss"
import Swal from "sweetalert2"
import FileUpload from "../../components/Common/FileUpload"

const AddUser = props => {
  setTitle("Add User")

  const navigate = useNavigate()

  const options = props.options

  const formRef = useRef()
  const todayDate = getDate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [moduleOptions, setModuleOptions] = useState([])
  const [privilegeOptions, setPrivilegeOptions] = useState([])

  useEffect(() => {
    handleTableData()
  }, [options]) //eslint-disable-line

  useEffect(() => {
    handleModuleOptions()
    handlePrivilegeOptions()
  }, []) //eslint-disable-line

  const handlePrivilegeOptions = async () => {
    try {
      const response = await get(`options/privileges`)
      setPrivilegeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleModuleOptions = async () => {
    try {
      const response = await get(`options/modules`)
      setModuleOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const response = await get(`user?page=${currentPage}&limit=${limit}`)
      const { count, data } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.name = `${item.firstName || ""} ${item.lastName || ""}`
        item.date = dateConverter(item.date)

        item.privilege = renderTableValue(item.privilege?.name)
        item.staff = renderTableValue(item.addedBy?.name)

        const status = item.status
        const statusColor = item?.status === 0 ? "green" : "red"
        item.status = (
          <>
            <i
              onClick={() => handleStatusChange(item._id, status === 0 ? 2 : 0)}
              style={{
                fontSize: "20px",
                color: statusColor,
                cursor: "pointer",
              }}
              className="uil-lock-alt"
            ></i>
          </>
        )

        item.twoFactor = (
          <div className="form-check form-switch d-flex justify-content-center">
            <input
              className="cursor-pointer form-check-input"
              type="checkbox"
              onChange={e => handleTwoFactor(e, item._id)}
              checked={item?.twoFactor?.enabled || false}
              name="twoFactor"
            />
          </div>
        )
        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => navigate(`/users/${item.uniqueId}`)}
              ></i>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      const fd = new FormData()
      fd.append("image", e.target.files[0])
      const response = await post(`user/image`, fd)
      handleValueChange({ value: response.data.new_filename, name: "image" })
    } catch (error) {
      toastr.error(error.response.data.message)
      e.target.value = ""
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`user`, masterObject)
      else response = await post(`user`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async id => {
    try {
      toTop()
      const response = await get(`user/update/${id}`)
      const data = response.data

      const gender = data.gender
        ? genderOptions.find(option => option.value === data.gender)
        : ""

      setMasterObject({
        ...data,
        id,

        module: data?.module?.value,
        company: data?.company?.value,
        privilege: data?.privilege?.value,

        state: data?.state?.value,
        district: data?.district?.value,
      })
      setSelectedFields({
        gender,

        module: data.module,
        company: data.company,
        privilege: data.privilege,

        state: data.state,
        district: data.district,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`user/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`user/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleTwoFactor = async (e, user) => {
    try {
      const { checked } = e.target

      const data = encrypt({ value: checked, user })
      const response = await put(`user/two-factor`, { data })

      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()
    toTop()
    setMasterObject({})
    setSelectedFields({})
    handleTableData(1)

    setPage(1)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "User ID", field: "uniqueId" },
      { label: "Name", field: "name" },
      { label: "Username", field: "username" },

      { label: "Mobile", field: "mobile" },
      { label: "Privilege", field: "privilege" },

      { label: "Added By", field: "staff" },

      { label: "2 FA", field: "twoFactor" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Users" />

          <Card>
            <CardBody>
              <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                <Row>
                  <Col md={3}>
                    <Label>
                      First name<span className="required">*</span>
                    </Label>
                    <AvField
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      errorMessage="Enter First Name"
                      validate={{ required: { value: true } }}
                      value={masterObject.firstName || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Last name</Label>
                    <AvField
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      value={masterObject.lastName || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>
                      Mobile<span className="required">*</span>
                    </Label>
                    <AvField
                      name="mobile"
                      type="text"
                      placeholder="Mobile"
                      validate={{
                        number: true,
                        required: { value: true },
                        minLength: { value: 10 },
                        maxLength: { value: 10 },
                      }}
                      errorMessage="Your mobile number must be exactly 10 digits"
                      value={masterObject.mobile || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>
                      Email<span className="required">*</span>
                    </Label>
                    <AvField
                      name="email"
                      type="email"
                      placeholder="Email"
                      errorMessage="Enter valid Email"
                      validate={{ required: { value: true } }}
                      value={masterObject.email || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>
                      Username<span className="required">*</span>
                    </Label>
                    <AvField
                      name="username"
                      placeholder="Username"
                      type="text"
                      errorMessage="Enter valid Username"
                      validate={{ required: { value: true } }}
                      value={masterObject.username || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  {!masterObject.id && (
                    <Col md={3}>
                      <Label>Password</Label>
                      <AvField
                        name="password"
                        placeholder="Password"
                        type="password"
                        errorMessage=" Please provide a valid password"
                        validate={{ required: { value: true } }}
                        value={masterObject.password || ""}
                        onChange={e => handleValueChange(e.target)}
                        autoComplete="new-password"
                      />
                    </Col>
                  )}
                  <Col md={3}>
                    <Label>DOB</Label>
                    <AvField
                      type="date"
                      name="dob"
                      max={todayDate}
                      value={masterObject?.dob || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>

                  <Col md={3}>
                    <Label>
                      Privilege<span className="required">*</span>
                    </Label>
                    <Select
                      name="privilage"
                      value={selectedFields.privilege || ""}
                      options={privilegeOptions}
                      onChange={selected =>
                        handleSelectValueChange(selected, "privilege")
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Gender</Label>
                    <Select
                      value={selectedFields.gender || ""}
                      options={genderOptions}
                      onChange={selected =>
                        handleSelectValueChange(selected, "gender")
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <FileUpload
                      label="Image"
                      name="image"
                      fileUrl={masterObject?.image}
                      onFileChange={handleFileUpload}
                      onDelete={() =>
                        handleValueChange({ value: "", name: "image" })
                      }
                      accept="image/*"
                    />
                  </Col>

                  <Col md={3}>
                    <Label>
                      Default Module<span className="required">*</span>
                    </Label>
                    <Select
                      value={selectedFields.module || ""}
                      options={moduleOptions}
                      onChange={selected =>
                        handleSelectValueChange(selected, "module")
                      }
                    />
                  </Col>
                  <Col md={3} style={{ marginTop: "26px" }}>
                    <Button
                      color={`${masterObject.id ? "warning" : "primary"}`}
                      type="submit"
                    >
                      {masterObject.id ? "Update" : "Submit"}
                    </Button>
                    <Button
                      color="danger"
                      type="reset"
                      onClick={reset}
                      style={{ marginLeft: "10px" }}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="usersTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddUser
