import React, { useState, useEffect } from 'react'
import MiniWidget from './mini-widget';
import { Container, Row, Col, Label } from "reactstrap";
// import ReactApexChart from "react-apexcharts";
import ReactECharts from 'echarts-for-react';
import { get } from '../../../helpers/api_helper';
import moment from 'moment';
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

const Jobdashboard = () => {
    const startingYear = 2020;
    const typeOption = [
        { label: "Year", value: 1 },
        { label: "Month", value: 2 },
        // { label: "7 Days", value: 3 },
        { label: "Custom", value: 4 }]

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const [counts, setcounts] = useState([])
    const [jobData, setJobData] = useState([])
    const [filterObject, setfilterObject] = useState({ type: 1 })
    const [monthOptions, setMonthOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [selectedValues, setselectedValues] = useState({ type: { label: "Year", value: 1 }, chart: { label: "Application Chart", value: 1 }, })
   const [jobApplications, setjobApplications] = useState([])

    const reports = [
        { id: 1, title: "Total jobs ", value: counts?.totalJobs },
        { id: 2, title: "Active jobs", value: counts?.activeJobs },
        // { id: 3, title: "Inactive jobs", value: counts?.companyCount },
        { id: 4, title: "Filled jobs ", value: counts?.filledJobs },
        { id: 5, title: "Expired jobs ", value: counts?.expiredJobs },

    ];

    const optionpi1 = {
        title: {
            text: 'Jobs',
            subtext: `${jobData.totalJobs} Total Jobs`,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        color: ['#ff6f61', '#6b8e23', '#4682b4', "rgb(205 223 225)"], // Define custom colors
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: jobData.data || [],
                label: {
                    show: true,
                    formatter: '{b}: {c} ({d}%)', // Displays name, value, and percentage
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    const optionpi2 = {
        title: {
            text: 'Applications',
            // subtext: 'Total Jobs 500',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            data: jobApplications.labels||[],
            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: jobApplications.data||[],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };

    useEffect(() => {
        fetchAlljoncounts()
        fetchAllapproval()
    }, [])

    useEffect(() => {
        fetchAlljobApplications()

    }, [filterObject])
    


    function fetchAlljoncounts() {
        get(`job/dashboard/counts`).then((res) => {
            setcounts(res.data)
        }).catch((err) => {

        })
    }

    function fetchAllapproval() {
        get(`job/dashboard/approval-chart`).then((res) => {
            setJobData(res)
        }).catch((err)=>{

        })
    }

    function fetchAlljobApplications(){
        get(`job/dashboard/applications?form=${filterObject?.startDate}&to=${filterObject?.endDate}`).then((res)=>{
             setjobApplications(res)            
        }).catch((err)=>{

        })
    }

    // ==================date filter=================


    useEffect(() => {
        // Set defaults on page load
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // 0-based index

        // Set year options
        const startingYear = 2023; // Example starting year
        const years = Array.from(
            { length: currentYear - startingYear + 1 },
            (_, i) => ({
                label: (startingYear + i).toString(),
                value: startingYear + i,
            })
        );
        setYearOptions(years);

        // Set month options for the current year
        const monthsToDisplay = months.slice(0, currentMonth + 1);
        const defaultMonthOptions = monthsToDisplay.map((month, index) => ({
            label: `${currentYear}-${month.slice(0, 3)}`,
            value: `${currentYear}-${index + 1}`,
        }));
        setMonthOptions(defaultMonthOptions);

        // Set default filter object
        setselectedValues({ type: { label: "Year", value: 1 }, year: { label: currentYear, value: currentYear }, chart: { label: "Application Chart", value: 1 }, });
        setfilterObject({
            type: 1,
            year: currentYear,
            startDate: `${currentYear}-01-01`,
            endDate: moment().format("YYYY-MM-DD"), // Current date
        });
    }, []);



    const getStartAndEndOfYear = (year) => ({
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`,
    });

    const getStartAndEndOfMonth = (year, month) => {
        const startDate = moment(`${year}-${month}`, "YYYY-M").startOf("month").format("YYYY-MM-DD");
        const endDate = moment(`${year}-${month}`, "YYYY-M").endOf("month").format("YYYY-MM-DD");
        return { startDate, endDate };
    };

    function getAllyears() {
        const currentYear = new Date().getFullYear();
        const years = Array.from(
            { length: currentYear - startingYear + 1 },
            (_, i) => ({
                label: (startingYear + i).toString(),
                value: startingYear + i,
            })
        );
        setYearOptions(years);
    }

    function getcurrentyearMonth() {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // 0-based index
        const monthOptions = months.slice(0, currentMonth + 1).map((month, index) => ({
            label: `${currentYear}-${month.slice(0, 3)}`, // Format YYYY-MMM
            value: `${currentYear}-${index + 1}`, // Format YYYY-MM
        }));
        setYearOptions([]); // Reset year options
        setMonthOptions(monthOptions);

    }

    function handleSelectChange(name, selected) {
        setselectedValues({ ...selectedValues, [name]: selected });
        setfilterObject({ ...filterObject, [name]: selected.value });

        if (name === "type") {
            if (selected.value === 1) {
                getAllyears();
            } else if (selected.value === 2) {
                getcurrentyearMonth();
            }
        } else if (name === "month") {
            const [year, month] = selected.value.split("-");
            const { startDate, endDate } = getStartAndEndOfMonth(year, parseInt(month));

            setfilterObject({
                ...filterObject,
                month: selected.value,
                startDate,
                endDate,
            });
        }
    }




    const handleYearChange = (name, selectedYear) => {
        const selectedYearValue = selectedYear.value;
        const { startDate, endDate } = getStartAndEndOfYear(selectedYearValue);

        // Adjust months for the selected year
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // 0-based index
        const monthsToDisplay =
            selectedYearValue === currentYear
                ? months.slice(0, currentMonth + 1)
                : months;

        const monthOptions = monthsToDisplay.map((month, index) => ({
            label: `${selectedYearValue}-${month.slice(0, 3)}`,
            value: `${selectedYearValue}-${index + 1}`,
        }));

        setMonthOptions(monthOptions);
        setfilterObject({
            ...filterObject,
            [name]: selectedYearValue,
            startDate,
            endDate,
        });
    };



    return (
        <div className='page-content'>
            <div className='container-fluid'>
                <Row>
                    <h6></h6>
                    <MiniWidget reports={reports} />
                </Row>
                <Row className='mt-4'>
                    <Col md="6">
                        <ReactECharts option={optionpi1} style={{ height: 400, width: '100%' }} />
                    </Col>
                    <Col md="6">
                     
                        <Row>
                            <div style={{display:"flex",justifyContent:"flex-end",gap:"8px"}}>
                            <Col md="3">
                                <Select
                                    options={typeOption}
                                    onChange={(selected) => handleSelectChange("type", selected)}
                                    value={selectedValues?.type}
                                />
                            </Col>
                            {selectedValues?.type?.value === 1 ? (
                                <Col md="3">
                                    <Select
                                        options={yearOptions}
                                        onChange={(selected) => handleYearChange("year", selected)}
                                        value={selectedValues?.year || null}
                                    />
                                </Col>
                            ) : null}
                            {selectedValues?.type?.value !== 4 ? (
                                <Col md="3">
                                    <Select
                                        options={monthOptions}
                                        onChange={(selected) => handleSelectChange("month", selected)}
                                        value={selectedValues?.month || null}
                                    />
                                </Col>
                            ) : null}
                            {selectedValues?.type?.value === 4 ? (
                                <Col md="6">
                                    <AvForm>
                                        <Row>
                                            <Col md="6">
                                                <AvField name="startDate" type="date" value={filterObject?.startDate || ""} />
                                            </Col>
                                            <Col md="6">
                                                <AvField name="endDate" type="date" value={filterObject?.endDate || ""} />
                                            </Col>


                                        </Row>
                                    </AvForm>
                                </Col>
                            ) : null}
                            </div>
                           
                          
                        </Row>
                        <ReactECharts className='mt-2' option={optionpi2} style={{ height: 400, width: '100%' }} />
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default Jobdashboard
