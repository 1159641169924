import React, { useEffect, useRef, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Container,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"

import toastr from "toastr"
import Swal from "sweetalert2"
import queryString from "query-string"

import TablePagination from "../../../components/Common/TablePagination"
import SearchSelect from "../../../components/Common/SearchSelect"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import { del, get, post, put } from "../../../helpers/api_helper"
import {
  dateConverter,
  getDate,
  renderTableValue,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import {
  forOptions,
  FOR_VALUES,
  STATUS_VALUES,
  statusOptions,
  filterForOptions,
} from "./helper"

import "./styles.scss"
import { useNavigate } from "react-router-dom"
import Icon from "../../../components/Common/Icon"
import moment from "moment"

const ScreeningAssign = () => {
  const formRef = useRef()
  const filterRef = useRef()
  const navigate = useNavigate()
  const todayDate = getDate()
  const now = moment()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [filterObject, setFilterObject] = useState({})
  const [selectedFilter, setSelectFilter] = useState({})

  const [testOptions, setTestOptions] = useState([])
  const [specializationOptions, setSpecializationOptions] = useState([])
  const [filterTestOptions, setFilterTestOptions] = useState([])

  const [selectedTests, setSelectedTests] = useState([])


  const [filterIntershipOpT, setfilterIntershipOpT] = useState([])
  const [filterjOBOpT, setfilterJOpT] = useState([])
  const [filterSepzOpt, setfilterSepzOpt] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    if (masterObject?.assignFor) {
      handleTestOptions()
    }
  }, [masterObject])



  useEffect(() => {
    handleFilterTestOptions()
    handleSpecializationOptions()
  }, []) // eslint-disable-line

  const handleTestOptions = async () => {
    try {
      const response = await get(`screening-assign/options/test?assignFor=${masterObject?.assignFor}&specialization=${masterObject?.specialization}&internship=${masterObject?.internship}&job=${masterObject?.job}`)
      setTestOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  console.log(masterObject, "master");

  const handleSpecializationOptions = async () => {
    try {
      const response = await get(`options/specializations`)
      setSpecializationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterTestOptions = async () => {
    try {
      const response = await get(`screening-assign/options/filter/test`)
      setFilterTestOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`screening-assign/list?${query}`)

      const { count, data } = response
      const date = new Date()
      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.assignFor = FOR_VALUES[item.assignFor]

        item.screeningTest = renderTableValue(item.screeningTest?.name)
        item.staff = renderTableValue(item.addedBy?.name)

        const start = moment(item.startDateAndTime, "YYYY-MM-DD HH:mm", true)
        const end = moment(item.endDateAndTime, "YYYY-MM-DD HH:mm", true)

        item.date = renderTableValue(dateConverter(item.date))
        item.startDate = renderTableValue(dateConverter(item.startDate))
        item.endDate = renderTableValue(dateConverter(item.endDate))

        item.startTime = renderTableValue(timeConverter(item.startTime))
        item.endTime = renderTableValue(timeConverter(item.endTime))

        const status = {
          ongoing: false,
          completed: false,
          rankListGenerated: item.rankList === 0,
          toUpdate:
            item.status === STATUS_VALUES.ACTIVE
              ? STATUS_VALUES.IN_ACTIVE
              : STATUS_VALUES.ACTIVE,
        }

        if (item.status !== STATUS_VALUES.IN_ACTIVE) {
          if (end.isBefore(now)) {
            item.status = STATUS_VALUES.COMPLETED
            status.completed = true
          } else if (start.isSameOrBefore(now)) {
            item.status = STATUS_VALUES.ONGOING
            status.ongoing = true
          }
        }

        const rankListGenerated = item.rankList === 0

        const statusObj = STATUS_VALUES[item.status]

        item.status = (
          <Button
            {...statusObj}
            onClick={() => handleStatusChange(item._id, status.toUpdate)}
          >
            {statusObj?.text}
          </Button>
        )

        item.options = (
          <div>
            <Icon
              icon="eye"
              title="View"
              onClick={() => handleNavigate(item.uniqueId)}
            />
            <Icon
              icon="edit"
              title="Edit"
              hidden={status.completed || status.ongoing || rankListGenerated}
              onClick={() => handleUpdate(item._id)}
            />

            <Icon
              icon="delete"
              title="Delete"
              hidden={status.completed || status.ongoing || rankListGenerated}
              onClick={() => handleDelete(item._id)}
            />
          </div>
        )

        if (status.completed) {
          const name = `${item._id}-${date}`
          item.checkbox = (
            <Input
              type="checkbox"
              key={name}
              name={name}
              style={{ cursor: "pointer" }}
              defaultChecked={false}
              onClick={e => handleCheckboxChange(e, item)}
            />
          )
        }
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response

      if (masterObject.id)
        response = await put(`screening-assign/update`, masterObject)
      else response = await post(`screening-assign/create`, masterObject)

      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`screening-assign/update/${id}`)
      const data = response.data
      setMasterObject({
        ...data,
        screeningTest: data?.screeningTest?.value,
        candidates: data?.candidateIds,
        internship: data?.internshipIds,
        specializations: data?.specializationIds,
        id,
      })
      setSelectedFields({
        ...data,
        assignFor: { label: FOR_VALUES[data.assignFor], value: data.assignFor },
      })
      toTop()
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`screening-assign/delete/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleGenerateRankList = async () => {
    try {
      const response = await post(`rank-list/generate`, {
        tests: selectedTests,
      })
      toastr.success(response.message)

      setSelectedTests([])
      handleTableData()

      filterRef.current?.reset()
      formRef.current?.reset()
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`screening-assign/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSelectChange = ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    if (name === "type") {
      obj.internship = null
      obj.candidates = null
      obj.job = null
    }
    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ name, value })
  }

  const handleValueChange = ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    if (name === "type") {
      obj.internship = null
      obj.candidates = null
      obj.job = null
    }
    setMasterObject(obj)
  }

  const handleFilterSelectChange = ({ selected, name }) => {
    const obj = { ...selectedFilter }
    obj[name] = selected

    setSelectFilter(obj)

    handleFilterValueChange({ name, value: selected?.value })
  }

  const handleFilterValueChange = ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) setSelectedTests(prevData => [...prevData, item._id])
    else setSelectedTests(prevData => prevData.filter(id => id !== item._id))
  }

  const handleNavigate = url => {
    navigate(`/assign-test/${url}`)
  }

  const isDisabled = () => {
    if (!masterObject.assignFor) return true
    if (masterObject.assignFor === FOR_VALUES.CANDIDATE) return false
    if (
      masterObject.assignFor === FOR_VALUES.KTU &&
      masterObject.specializations?.length
    )
      return false
    if (
      masterObject.assignFor === FOR_VALUES.INTERNSHIP &&
      masterObject.internship
    )
      return false

    if (
      masterObject.assignFor === FOR_VALUES.JOB &&
      masterObject.job
    )
      return false

    return true
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    filterRef.current?.reset()

    setMasterObject({})
    setSelectedFields({})

    setFilterObject({})
    setSelectFilter({})

    setSelectedTests([])
  }
  const data = {
    columns: [
      { label: "", field: "checkbox" },
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Assign For", field: "assignFor" },
      { label: "Test", field: "screeningTest" },
      { label: "Start Date", field: "startDate" },
      { label: "Start Time", field: "startTime" },
      { label: "End Date", field: "endDate" },
      { label: "End Time", field: "endTime" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }



  useEffect(() => {
    getAllFilterInternship()
    getAllFilterJobs()
    getAllFilterspecialization()

  }, [])


  function getAllFilterInternship() {
    get(`screening/filter/internship`).then((res) => {
      setfilterIntershipOpT(res.data)
    }).catch((err) => {
      console.error(err)
    })
  }

  function getAllFilterJobs() {
    get(`screening/filter/job`).then((res) => {
      setfilterJOpT(res.data)
    }).catch((err) => {
      console.error(err)
    })
  }

  function getAllFilterspecialization() {
    get(`screening/filter/specialization`).then((res) => {
      setfilterSepzOpt(res.data)
    }).catch((err) => {
      console.error(err)
    })
  }




  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Assign Test" />
        <Card>
          <CardBody>
            <AvForm
              className="needs-validation"
              ref={formRef}
              onValidSubmit={handleSubmit}
            >
              <Row>
                <Col md={2} className="mb-3">
                  <Label>Assign For</Label>
                  <Select
                    options={forOptions}
                    value={selectedFields.assignFor || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "assignFor" })
                    }
                  />
                </Col>

                {masterObject.assignFor === FOR_VALUES.INTERNSHIP ? (
                  <Col md={3} className="mb-3">
                    <Label>Internship</Label>
                    <SearchSelect
                      api="screening/options/internship"
                      onChange={selected => {
                        handleSelectChange({ selected, name: "internship" })
                      }}
                      value={selectedFields.internship}
                    />
                  </Col>
                ) : masterObject.assignFor === FOR_VALUES.KTU ? (
                  <Col md={3} className="mb-3">
                    <Label>Specialization</Label>
                    <Select
                      // isMulti
                      menuPosition="fixed"
                      options={specializationOptions}
                      value={selectedFields.specializations || ""}
                      onChange={selected =>
                        handleSelectChange({
                          selected,
                          name: "specializations",
                        })
                      }
                    />
                  </Col>
                ) : masterObject.assignFor === FOR_VALUES.JOB ? (

                  <Col md={3} className="mb-3">
                    <Label>JOB</Label>
                    <SearchSelect
                      api="screening/options/job"
                      onChange={selected => {
                        handleSelectChange({ selected, name: "job" })
                      }}
                      value={selectedFields.job}
                    />
                  </Col>
                ) : ("")}

                <Col md={3} className="mb-3">
                  <Label>Candidate</Label>
                  {(() => {
                    const disabled = isDisabled()
                    return (
                      <SearchSelect
                        isMulti
                        isDisabled={disabled}
                        value={selectedFields.candidates}
                        onChange={selected =>
                          handleSelectChange({ selected, name: "candidates" })
                        }
                        api={`screening-assign/options/candidate`}
                        query={queryString.stringify({
                          assignFor: masterObject.assignFor,
                          specialization: masterObject.specializations,
                          internship: masterObject.internship,
                          job: masterObject.job,
                        })}
                        cacheOptions={false}
                      />
                    )
                  })()}
                </Col>

                <Col md={3} className="mb-3">
                  <Label>Screening Test</Label>
                  <Select
                    isDisabled={!masterObject.assignFor}
                    options={testOptions}
                    value={selectedFields.screeningTest || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "screeningTest" })
                    }
                  />
                </Col>

                <Col md={2} className="mb-3">
                  <Label>Start Date</Label>
                  <AvField
                    name="startDate"
                    type="date"
                    value={masterObject.startDate || ""}
                    errorMessage="Enter Start date"
                    validate={{ required: { value: true } }}
                    min={!masterObject.id && todayDate}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>Start Time</Label>
                  <AvField
                    name="startTime"
                    type="time"
                    validate={{ required: { value: true } }}
                    value={masterObject.startTime || ""}
                    errorMessage="Enter Start time"
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>End Date</Label>
                  <AvField
                    name="endDate"
                    type="date"
                    value={masterObject.endDate || ""}
                    errorMessage="Enter Start date"
                    validate={{ required: { value: true } }}
                    min={masterObject.startDate}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>End Time</Label>
                  <AvField
                    name="endTime"
                    type="time"
                    value={masterObject.endTime || ""}
                    validate={{ required: { value: true } }}
                    errorMessage="Enter End time"
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={3}>
                  <div className="d-flex gap-3 form-button">
                    <Button color={masterObject?._id ? "warning" : "primary"}>
                      {masterObject?._id ? "Update" : "Submit"}
                    </Button>
                    <Button type="reset" color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>Assign For</Label>
                  <Select
                    options={filterForOptions}
                    value={selectedFilter.assignFor || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "assignFor" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Screening Test</Label>
                  <Select
                    options={filterTestOptions}
                    value={selectedFilter.test || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "test" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Job</Label>
                  <Select
                    options={filterjOBOpT}
                    value={selectedFilter.job || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "job" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Internship</Label>
                  <Select
                    options={filterIntershipOpT}
                    value={selectedFilter.internship || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "internship" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Specialization</Label>
                  <Select
                    options={filterSepzOpt}
                    value={selectedFilter.specialization || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "specialization" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    options={statusOptions}
                    value={selectedFilter.status || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "status" })
                    }
                  />
                </Col>
                <Col>
                  <div className="d-flex justify-content-between form-button mb-3">
                    <Button type="reset" color="danger" onClick={reset}>
                      Reset
                    </Button>
                    <Button
                      color="primary"
                      disabled={!selectedTests.length}
                      onClick={handleGenerateRankList}
                      type="button"
                    >
                      Generate Rank List
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              tableId="assignTest"
              data={data}
              count={totalPage}
              onChange={handlePageChange}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default ScreeningAssign
