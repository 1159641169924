import React, { useEffect, useState } from "react"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider, IconButton } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import { Link, useParams } from "react-router-dom"
import JobApplied from "./Tabs/JobApplied"
import MarkAssessment from "./Tabs/MarkAssessment"
import EnrolledCourse from "./Tabs/EnrolledCourse"
import Certification from "./Tabs/Certifications"
import WorkIcon from "@mui/icons-material/Work"
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import AssessmentIcon from "@mui/icons-material/Assessment"
import { get } from "../../helpers/api_helper"
import Avatar from "@mui/material/Avatar"
import Stack from "@mui/material/Stack"
import Profile from "./Tabs/Profile"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import Jobs from "./Tabs/Jobs"
import Fee from "./Tabs/Fees"
import moment from "moment";


const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
const CandidateProfile = props => {
  // eslint-disable-next-line
  const { id } = useParams()

  const [studentDetail, setStudentDetail] = useState(false)

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [id])
  const [tab3, setTab3] = React.useState("8")

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  function handleTableData() {
    get(`candidate/view?id=${id}`)
      .then(res => {
        const result = res?.data

        setStudentDetail(result)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }


  const getGenderText = gender => {
    switch (gender) {
      case 1:
        return "Male"
      case 2:
        return "Female"
      case 3:
        return "Other"
      default:
        return ""
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">
                  {studentDetail?.firstname || ""}{" "}
                  {studentDetail?.lastname && studentDetail.lastname}
                </h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/student-dashboard">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/student-details">Students</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">
                        {studentDetail?.firstname || ""}{" "}
                        {studentDetail?.lastname && studentDetail.lastname}
                      </Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <div>
                <div className="row">
                  <div className="col-xl-4 mb-4">
                    <div
                      className="card"
                      style={{
                        width: "100%",
                        maxWidth: "400px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        margin: "0 auto",
                        padding: "20px",
                      }}
                    >
                      <div className="card-body">
                        {/* Logo Section */}
                        <div
                          style={{ position: "absolute", top: 10, right: 10 }}
                        >
                          <Stack direction="row" spacing={1}>
                            {studentDetail && studentDetail.isAsap && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  src="/asapsm.png"
                                  alt="Logo 1"
                                  style={{
                                    width: "28px",
                                    height: "28px",
                                    cursor: "pointer",
                                    border: "1px solid #e0e0e0",
                                    padding: "4px",
                                    borderRadius: "50%",
                                    boxSizing: "border-box",
                                    objectFit: "contain",
                                    overflow: "hidden",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                                <IconButton style={{ padding: "2px" }} />
                              </div>
                            )}
                            {studentDetail && studentDetail.isKtu && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  src="/ktu.svg"
                                  alt="Logo 2"
                                  style={{
                                    width: "28px",
                                    height: "28px",
                                    cursor: "pointer",
                                    border: "1px solid #e0e0e0",
                                    padding: "4px",
                                    borderRadius: "50%",
                                    boxSizing: "border-box",
                                    objectFit: "contain",
                                    overflow: "hidden",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                                <IconButton style={{ padding: "2px" }} />
                              </div>
                            )}
                            {studentDetail && studentDetail.isGroomed && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  src="/logo-person.png"
                                  alt="Logo 3"
                                  style={{
                                    width: "28px",
                                    height: "28px",
                                    cursor: "pointer",
                                    border: "1px solid #e0e0e0",
                                    padding: "4px",
                                    borderRadius: "50%",
                                    boxSizing: "border-box",
                                    objectFit: "contain",
                                    overflow: "hidden",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                                <IconButton style={{ padding: "2px" }} />
                              </div>
                            )}
                          </Stack>
                        </div>

                        {/* Profile Section */}
                        <Stack
                          className="mt-5"
                          direction="row"
                          spacing={2}
                          style={{ justifyContent: "center" }}
                        >
                          <Avatar
                            alt={studentDetail?.firstName}
                            src={`${API_URL}${studentDetail.assets?.profile}`}
                            sx={{ width: 56, height: 56 }}
                            style={{
                              width: "6rem",
                              height: "6rem",
                              marginBottom: "auto",
                            }}
                          />
                        </Stack>

                        <h5
                          className="mt-2 mb-2 text-center"
                          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
                        >
                          {studentDetail?.firstName || ""}{" "}
                          {studentDetail?.lastName || ""}
                        </h5>
                        <h6
                          className="mt-2 mb-2 text-center"
                          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
                        >
                          {studentDetail.email}{" "}
                          {studentDetail.verification?.email && (
                            <CheckCircleIcon
                              style={{ color: "green", width: "14px" }}
                            />
                          )}
                        </h6>
                        <h6
                          className="mt-2 mb-2 text-center"
                          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
                        >
                          {studentDetail.mobile}{" "}
                          {studentDetail.verification?.mobile && (
                            <CheckCircleIcon
                              style={{ color: "green", width: "14px" }}
                            />
                          )}
                        </h6>
                        <Divider />

                        {/* Details Section */}
                        <div className="row">
                          {[
                            {
                              label: "Candidate Id",
                              field: "uniqueId",
                              value: studentDetail.uniqueId,
                            },
                            {
                              label: "Candidate Name",
                              value:
                                `${studentDetail.firstName} ${studentDetail.lastName || ""}`.trim(),
                            },
                            {
                              label: "Aadhaar No",
                              value: studentDetail.aadhar || "",
                            },
                            {
                              label: "Gender",
                              value: getGenderText(studentDetail.gender) || "",
                            },
                            { label: "DOB", value: studentDetail.dob ? moment(studentDetail.dob).format('DD-MM-YYYY'):"" },
                            {
                              label: "Address",
                              value: studentDetail.address || "",
                            },
                            {
                              label: "Parent Name",
                              value: studentDetail.parentName || "",
                            },
                            {
                              label: "Parent Mobile No",
                              value: studentDetail.parentMobile || "",
                            },
                            {
                              label: "Pincode",
                              value: studentDetail.pin || "",
                            },
                            { label: "State", value: studentDetail.state },
                            {
                              label: "District",
                              value: studentDetail.district || "",
                            },
                            {
                              label: "University",
                              value: studentDetail.university || "",
                            },
                            {
                              label: "College",
                              value: studentDetail.college || "",
                            },
                            {
                              label: "Joined Date",
                              value: studentDetail.date? moment(studentDetail.date).format('DD-MM-YYYY'):"",
                            },
                            {
                              label: "Joined Time",
                              value: studentDetail.time || "",
                            },
                          ].map((item, index) => (
                            <div className="col-12" key={index}>
                              <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                                <strong className="mr-2">{item.label}:</strong>
                                <span style={{ wordBreak: "break-word" }}>
                                  {item.value}
                                  {item.label === "Aadhaar No" &&
                                    studentDetail.verification?.aadhar && (
                                      <CheckCircleIcon
                                        className="ms-1"
                                        style={{
                                          color: "green",
                                          width: "14px",
                                        }}
                                      />
                                    )}
                                </span>
                              </div>
                              <Divider />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-8 " style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              variant="scrollable"
                              scrollButtons="auto"
                              indicatorColor="primary"
                              textColor="primary"
                              // variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={<PersonIcon />}
                                label="Profile"
                                value="8"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1,
                                }}
                              />
                              <Tab
                                icon={
                                  <CurrencyRupeeIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Fees"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1,
                                }}
                              />
                              <Tab
                                icon={<WorkIcon style={{ fontSize: "20px" }} />}
                                label="Internship"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1,
                                }}
                              />

                              <Tab
                                icon={<WorkIcon style={{ fontSize: "20px" }} />}
                                label="Jobs"
                                value="9"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1,
                                }}
                              />
                              {/* <Tab
                                icon={<PersonIcon style={{ fontSize: "" }} />}
                                label="Courses"
                                value="10"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1
                                }}
                              /> */}

                              <Tab
                                icon={
                                  <AssessmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Assessments"
                                value="6"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1,
                                }}
                              />
                              <Tab
                                icon={
                                  <WorkspacePremiumIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Certifications"
                                value="7"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1,
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="5"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <JobApplied candidateId={studentDetail._id} />
                          </TabPanel>
                          <TabPanel
                            value="9"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Jobs candidateId={studentDetail._id} />
                          </TabPanel>
                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Fee candidateId={studentDetail._id} />
                          </TabPanel>
                          {/* <TabPanel
                            value="10"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <EnrolledCourse />
                          </TabPanel> */}

                          <TabPanel
                            value="6"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <MarkAssessment candidateId={studentDetail._id}/>
                          </TabPanel>
                          <TabPanel
                            value="7"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Certification />
                          </TabPanel>
                          <TabPanel
                            value="8"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Profile />
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CandidateProfile
