import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import TablePagination from "../../../../components/Common/TablePagination"
import LoadingButton from "../../../../components/Common/LoadingButton"
import SearchSelect from "../../../../components/Common/SearchSelect"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import Icon from "../../../../components/Common/Icon"
import PaymentDetails from "../../PaymentDetails"

import queryString from "query-string"
import toastr from "toastr"
import moment from "moment"

import {
  dateConverter,
  getDate,
  numberToCurrency,
  renderTableValue,
} from "../../../../helpers/functions"
import {
  METHODS,
  PAYMENT_FOR,
  paymentForOptions as filterPaymentFor,
} from "../helper"
import { get, post } from "../../../../helpers/api_helper"
import { URLS } from "./helper"

const ManualPayment = () => {
  const filterRef = useRef()
  const formRef = useRef()
  const todayDate = getDate()

  const initialState = {
    date: todayDate,
    time: moment().format("HH:mm"),
  }

  const [masterObject, setMasterObject] = useState({ ...initialState })
  const [selectedFields, setSelectedFields] = useState({})

  const [internshipOptions, setInternshipOptions] = useState([])
  const [jobOptions, setJobOptions] = useState([])
  const [jobFairOptions, setJobFairOptions] = useState([])
  const [paymentForOptions, setPaymentForOptions] = useState([])
  const [fundSourceOptions, setFundSourceOptions] = useState([])

  const [filterObject, setFilterObject] = useState({ method: METHODS.CASH })
  const [selectFilter, setSelectedFilter] = useState({})

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [detailsModal, setDetailsModal] = useState({ show: false, id: null })

  const [loading, setLoading] = useState({ reset: 0, export: false })
  const [totalCount, setTotalCount] = useState(0)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    handleFundSourceOptions()
  }, []) // eslint-disable-line

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  const handleFundSourceOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)
      const response = await get(`${URLS.FUND_SOURCE}?${query}`)
      setFundSourceOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInternshipOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)
      const response = await get(`${URLS.INTERNSHIP}?${query}`)
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)
      const response = await get(`${URLS.JOB}?${query}`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobFairOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)
      const response = await get(`${URLS.JOB_FAIR}?${query}`)
      setJobFairOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`

      const response = await get(`${URLS.LIST}${query}`)
      const { count, data } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.date = dateConverter(item.date)

        item.uniqueId = renderTableValue(item?.uniqueId)
        item.candidateId = renderTableValue(item?.candidate?.uniqueId)
        item.candidate = renderTableValue(item?.candidate?.name)

        item.feeAmount = numberToCurrency(item?.feeAmount)
        item.amount = numberToCurrency(item?.amount)

        item.paymentFor = renderTableValue(PAYMENT_FOR[item?.paymentFor])

        item.staff = renderTableValue(item?.addedBy?.name)

        item.options = (
          <div className="d-flex justify-content-center align-items-center">
            <Icon
              icon="eye"
              title="View"
              onClick={() => handleToggleModal({ show: true, id: item._id })}
            />
            <Icon
              title="Receipt"
              icon="downloadFile"
              href={item.receipt}
              hidden={!item.receipt}
            />
          </div>
        )

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTotalCount(count)
      setTableData(data)
    } catch (error) {}
  }

  const handleSubmit = async () => {
    try {
      const response = await post(URLS.CREATE, masterObject)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error?.response?.data?.message || error)
    }
  }

  const handleToggleModal = ({ show, id }) => {
    setDetailsModal({ show, id })
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "candidate") {
      obj.paymentFor = ""
      obj.internship = ""
      obj.job = ""
      obj.jobFair = ""
    }

    if (name === "paymentFor") {
      obj.internship = ""
      obj.job = ""
      obj.jobFair = ""
    }

    setSelectedFields(obj)

    handleValueChange({ value: selected?.value, name, selected })
  }

  const handleValueChange = async ({ value, name, selected }) => {
    const obj = { ...masterObject }
    obj[name] = value

    if (name === "candidate") {
      obj.paymentFor = ""
      obj.internship = ""
      obj.job = ""
      obj.jobFair = ""
      obj.amount = ""

      let options = selected?.pending || []
      if (Array.isArray(options)) {
        options = options.map(option => {
          return {
            label: PAYMENT_FOR[option.for] || "",
            value: option.for,
            due: option.due,
          }
        })
      } else {
        options = []
      }
      setPaymentForOptions(options)
    }

    if (name === "paymentFor") {
      obj.internship = ""
      obj.job = ""
      obj.jobFair = ""
      obj.amount = ""

      if (
        [
          PAYMENT_FOR.KTU_INTERNSHIP_COMPLETION,
          PAYMENT_FOR.PSU_APPLICATION,
        ].includes(value)
      ) {
        handleInternshipOptions(obj)
      } else if (value === PAYMENT_FOR.JOB_REGISTRATION) {
        handleJobOptions(obj)
      } else if (value === PAYMENT_FOR.JOB_FAIR_REGISTRATION) {
        handleJobFairOptions(obj)
      } else {
        // For registration fee and general application fee
        obj.amount = selected.due
      }
    }

    if (
      [
        PAYMENT_FOR.KTU_INTERNSHIP_COMPLETION,
        PAYMENT_FOR.PSU_APPLICATION,
        PAYMENT_FOR.JOB_REGISTRATION,
        PAYMENT_FOR.JOB_FAIR_REGISTRATION,
      ].includes(obj.paymentFor) &&
      ["internship", "job", "jobFair"].includes(name)
    ) {
      obj.amount = selected.due
    }

    setMasterObject(obj)
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected

    setSelectedFilter(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleFilterValueChange({ value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    filterRef.current.reset()
    formRef.current.reset()
    setLoading(prev => ({ export: false, reset: prev.reset++ }))

    setFilterObject({ method: METHODS.CASH })
    setSelectedFilter({})

    setMasterObject({ ...initialState })
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Receipt No", field: "uniqueId" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Payment For", field: "paymentFor" },
      { label: "Fee", field: "feeAmount" },
      { label: "Amount", field: "amount" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Manual Payments" />

        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    value={selectedFields.candidate}
                    api={URLS.CANDIDATE}
                    dependency={[loading.reset]}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "candidate" })
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Payment For</Label>
                  <Select
                    isDisabled={!masterObject.candidate}
                    options={paymentForOptions}
                    value={selectedFields.paymentFor || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "paymentFor" })
                    }
                  />
                </Col>
                <Col
                  md={3}
                  className="mb-3"
                  hidden={
                    ![
                      PAYMENT_FOR.KTU_INTERNSHIP_COMPLETION,
                      PAYMENT_FOR.PSU_APPLICATION,
                    ].includes(masterObject.paymentFor)
                  }
                >
                  <Label>Internship</Label>
                  <Select
                    value={selectedFields.internship || ""}
                    options={internshipOptions}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "internship" })
                    }
                  />
                </Col>
                <Col
                  md={3}
                  className="mb-3"
                  hidden={
                    masterObject.paymentFor !== PAYMENT_FOR.JOB_REGISTRATION
                  }
                >
                  <Label>Job</Label>
                  <Select
                    value={selectedFields.job || ""}
                    options={jobOptions}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "job" })
                    }
                  />
                </Col>
                <Col
                  md={3}
                  className="mb-3"
                  hidden={
                    masterObject.paymentFor !==
                    PAYMENT_FOR.JOB_FAIR_REGISTRATION
                  }
                >
                  <Label>Job Fair</Label>
                  <Select
                    value={selectedFields.jobFair || ""}
                    options={jobFairOptions}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "jobFair" })
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Fund Source</Label>
                  <Select
                    value={selectedFields.fundSource || ""}
                    options={fundSourceOptions}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "fundSource" })
                    }
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>Amount</Label>
                  <AvField
                    disabled
                    type="number"
                    name="amount"
                    placeholder="Amount"
                    value={masterObject.amount || ""}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>Date</Label>
                  <AvField
                    type="date"
                    name="date"
                    defaultValue={masterObject.date || ""}
                    value={masterObject.date || ""}
                    onChange={e => handleValueChange(e.target)}
                    validate={{ required: { value: true } }}
                    errorMessage="Enter date"
                    max={todayDate}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>Time</Label>
                  <AvField
                    type="time"
                    name="time"
                    defaultValue={masterObject.time || ""}
                    value={masterObject.time || ""}
                    onChange={e => handleValueChange(e.target)}
                    validate={{ required: { value: true } }}
                    errorMessage="Enter time"
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>Remarks</Label>
                  <AvField
                    rows={1}
                    type="textarea"
                    name="remarks"
                    placeholder="Remarks"
                    value={masterObject.remarks || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <div className="d-flex form-button gap-3">
                    <Button color="primary">Submit</Button>
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>
                <Col md={3}>
                  <Label>Payment For</Label>
                  <Select
                    options={filterPaymentFor}
                    value={selectFilter.paymentFor || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "paymentFor" })
                    }
                    isMulti
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    api={`payments/options/candidate`}
                    onChange={selected => {
                      handleFilterSelectChange({ selected, name: "candidate" })
                    }}
                    value={selectFilter.candidate}
                    isMulti
                    master={filterObject}
                  />
                </Col>
                <Col md={2}>
                  <div className="d-flex form-button gap-3">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                    <LoadingButton
                      color="warning"
                      // onClick={handleExport}
                      loading={loading.export}
                    >
                      Export
                    </LoadingButton>
                  </div>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="manualPayments"
              count={totalPage}
              totalCount={totalCount}
            />
          </CardBody>
        </Card>
      </Container>
      <PaymentDetails
        isOpen={detailsModal.show}
        onToggle={() => handleToggleModal({})}
        id={detailsModal.id}
      />
    </div>
  )
}

export default ManualPayment
