import React, { useState, useRef, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import toastr from "toastr"
import moment from "moment"

import {
  dateConverter,
  getDate,
  numberToCurrency,
  reactSelectCustomStyle,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import { get, post, put } from "../../../helpers/api_helper"

import "./styles.scss"

const FundTransfer = ({ options }) => {
  const formRef = useRef()
  const todayDate = getDate()
  const time = moment().format("HH:mm")

  const statusOptions = [
    { label: "Approve", value: 0 },
    { label: "Rejected", value: 1 },
  ]

  const STATUS_VALUES = {
    APPROVED: 0,
    REJECTED: 1,
    PENDING: 2,
    0: { text: "Approved", color: "success" },
    1: { text: "Rejected", color: "danger" },
    2: { text: "Pending", color: "warning" },
  }

  const initialState = { date: todayDate, time }

  const limit = 100
  const [page, setPage] = useState(1)
  const [balance, setBalance] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({ ...initialState })
  const [selectedFields, setSelectedFields] = useState({})

  useEffect(() => {
    handleTableData()
    handleAccountsOptions()
    // eslint-disable-next-line
  }, [])

  const [accountOptions, setChartOfAccountOptions] = useState([])

  const handleAccountsOptions = async () => {
    try {
      const response = await get(`options/chartOfAccount`)
      setChartOfAccountOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const response = await get(
        `/fund-transfer/list?page=${currentPage}&limit=${limit}`,
      )
      const result = response.data
      const count = response.count

      result.map((item, index) => {
        item.id = index + 1 + (currentPage - 1) * limit

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)
        item.amount = numberToCurrency(item.amount)

        item.from = item.from.name
        item.to = item.to.name
        item.staff = item.addedBy?.name

        if (item.status === STATUS_VALUES.PENDING) {
          item.status = (
            <Select
              styles={reactSelectCustomStyle}
              options={statusOptions}
              value={""}
              onChange={selected =>
                handleStatusChange(item._id, selected?.value)
              }
              menuPosition="fixed"
            />
          )
        } else {
          const status = STATUS_VALUES[item.status]
          item.status = (
            <Button size="sm" color={status.color} disabled>
              {status.text}
            </Button>
          )
        }

        return item
      })

      setTableData(result)
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      const response = await post("/fund-transfer/create", masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put("/fund-transfer/status", { id, status })
      handleTableData()
      handleAccountsOptions()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }

    if (
      (name === "from" && selected.value === masterObject.to) ||
      (name === "to" && selected.value === masterObject.from)
    )
      return

    obj[name] = selected
    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name, selected })
  }

  const handleValueChange = async ({ value, name, selected }) => {
    const obj = { ...masterObject }

    obj[name] = value

    if (name === "from") setBalance(selected.balance)

    setMasterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    formRef.current.reset()

    setMasterObject({ ...initialState })
    setSelectedFields({})

    toTop()
    setPage(1)
    handleTableData(1)

    handleAccountsOptions()
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "No.", field: "uniqueId" },
      { label: "From ", field: "from" },
      { label: "To", field: "to" },
      { label: "Amount", field: "amount" },
      { label: "Remarks", field: "remarks" },
      { label: "Added By", field: "staff" },
      { label: "Status", field: "status" },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Fund Transfer" />
          {options.edit ? (
            <Card>
              <CardBody>
                <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                  <Row>
                    <Col md={2}>
                      <div className="mb-3">
                        <AvField
                          name="date"
                          label="Date"
                          type="date"
                          value={masterObject.date || ""}
                          defaultValue={masterObject.date}
                          max={todayDate}
                          validate={{ required: { value: true } }}
                          errorMessage="Enter Date"
                          onChange={e => handleValueChange(e.target)}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <AvField
                          name="time"
                          label="Time"
                          type="time"
                          defaultValue={masterObject.time}
                          value={masterObject.time}
                          onChange={e => handleValueChange(e.target)}
                          validate={{ required: { value: true } }}
                          errorMessage="Enter Time"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <Label>From Fund Source</Label>
                      <Select
                        name="from"
                        options={accountOptions.filter(
                          item => item.value !== masterObject.to,
                        )}
                        value={selectedFields.from || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "from")
                        }}
                      />
                      {masterObject.from ? (
                        <span>
                          <b>Balance :</b> {numberToCurrency(balance)}
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>To Fund Source</Label>
                        <Select
                          name="to"
                          options={accountOptions.filter(
                            item => item.value !== masterObject.from,
                          )}
                          value={selectedFields.to || ""}
                          onChange={selected => {
                            handleSelectValueChange(selected, "to")
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={2}>
                      <div className="mb-3">
                        <Label>Amount</Label>
                        <AvField
                          name="amount"
                          className="form-control"
                          type="number"
                          value={masterObject.amount || ""}
                          onChange={e => {
                            handleValueChange(e.target)
                          }}
                          validate={{
                            required: { value: true },
                            min: { value: 1 },
                          }}
                          placeholder="Amount"
                          errorMessage="Enter Amount"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Remarks</Label>
                        <AvField
                          name="remarks"
                          type="textarea"
                          value={masterObject.remarks || ""}
                          onChange={e => {
                            handleValueChange(e.target)
                          }}
                          placeholder="Remarks"
                          rows={1}
                        />
                      </div>
                    </Col>

                    <Col className="form-button">
                      <div className="d-flex gap-2">
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                        <Button color="danger" onClick={reset}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="fundTransferTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default FundTransfer
