import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    BreadcrumbItem,
    CardHeader,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import AssignmentIcon from "@mui/icons-material/Assignment"
import { Box, Divider } from "@mui/material"
import { Link, useLocation, useParams } from "react-router-dom"
import { Button } from "reactstrap"
// Tabs
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { get } from "../../helpers/api_helper"
import { API_URL } from "../../config"
import moment from "moment"
import GroupIcon from '@mui/icons-material/Group';
import RegistrationApplications from "./RegistrationApplications"
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RegInterestedJobs from "./RegInterestedJobs"
const RegistrationDetails = props => {
    const { state } = useLocation()
    // const uniqID = state.id
    const { regId } = useParams();
    const [details, setDetails] = useState({})
    const [tab3, setTab3] = React.useState("1")
    const [regDetails, setregDetails] = useState([])
    const toggle = (start = true) => {
        var body = document.body
        if (start) {
            body.classList.remove("vertical-collpsed")
            body.classList.remove("sidebar-enable")
            body.setAttribute("data-sidebar-size", "sm")
        } else {
            body.classList.add("vertical-collpsed")
            body.classList.add("sidebar-enable")
            body.setAttribute("data-sidebar-size", "lg")
        }
    }
    useEffect(() => {
        toggle()

        return () => {
            toggle(false)
        }
        // eslint-disable-next-line
    }, [])

    const handleChangeTab = (event, newValue) => {
        setTab3(newValue)
    }

    useEffect(() => {
        getAllRegistrationDetails()
    }, [regId])

    function getAllRegistrationDetails() {
        get(`job-fair/candidate-registration/detail?id=${regId}`).then((res) => {
            setregDetails(res.data)
        }).catch((err) => {

        })
    }



    const generateTooltip = name => {
        let displayText = name
        if (name && name.length > 20) {
            displayText = `${name.substring(0, 20)}...`
        }

        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                    >
                        {name}
                    </Tooltip>
                }
            >
                <span>{displayText}</span>
            </OverlayTrigger>
        )
    }





    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">
                                    Registered Candidate Details
                                </h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <BreadcrumbItem>
                                            <Link to="#">Home</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="/">Job fair</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="#">{generateTooltip(regDetails?.candidate?.name)}</Link>
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            {/* First Dialogue */}
                            <div>
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div
                                            className="card"
                                            style={{
                                                // width: "fit-content",
                                                // width: "351px",
                                                height: "max-content",
                                                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                                            }}
                                        >
                                            <div className="card-body">
                                                <div className="float-end dropdown"></div>
                                                <h5
                                                    className="mt-2 mb-2"
                                                    style={{
                                                        textAlign: "center",
                                                        fontFamily: "IBM Plex Sans,sans-serif",
                                                    }}
                                                >
                                                    {regDetails.candidate?.name}
                                                </h5>
                                                <div
                                                    className="text-muted"
                                                    style={{
                                                        textAlign: "center",
                                                        color: "green !important",
                                                        fontFamily: "IBM Plex Sans,sans-serif",
                                                        fontSize: "14px",
                                                        marginBottom: "3px",
                                                    }}
                                                ></div>

                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        marginBottom: "3px",
                                                        color: "#0f13c1",
                                                    }}
                                                >
                                                    {regDetails?.candidate?.email}
                                                </p>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        marginBottom: "3px",
                                                        color: "#0f13c1",
                                                    }}
                                                >
                                                    {regDetails?.candidate?.mobile}
                                                </p>

                                                <Divider />
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}>
                                                    <p className="mb-1">Job Fair:</p>
                                                    <h5 className="font-size-14">{regDetails?.jobFair?.name}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Reg.Id:</p>
                                                    <h5 className="font-size-14">{regDetails?.uniqueId}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />

                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Attendance:</p>
                                                    <h5 className="font-size-14">{regDetails?.attendance === 1 ? "Not Attended" : regDetails?.attendance === 2 ? "Attended" : "---"}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Fee:</p>
                                                    <h5 className="font-size-14">{regDetails?.feePaid === 0 ? "Pending" : regDetails?.feePaid === 1 ? "Paid" : ""}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Source:</p>
                                                    <h5 className="font-size-14">{regDetails?.source===1?"Website":regDetails?.source===2?"Spot":regDetails?.source===3?"CRM":"---"}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                                        <div
                                            className="mb-0 card"
                                            style={{
                                                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                                                width: "100%",
                                            }}
                                        >
                                            <Box sx={{ width: "100%", typography: "body1" }}>
                                                <TabContext value={tab3}>
                                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                        <TabList
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                            variant="fullWidth"
                                                            onChange={handleChangeTab}
                                                            aria-label="lab API tabs example"
                                                        >
                                                            <Tab
                                                                icon={
                                                                    <GroupIcon style={{ fontSize: "20px" }} />
                                                                }
                                                                label="Applications"
                                                                value="1"
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    fontFamily: "IBM Plex Sans,sans-serif",
                                                                }}
                                                            />
                                                            <Tab
                                                                icon={
                                                                    <ThumbUpIcon
                                                                        style={{ fontSize: "20px" }}
                                                                    />
                                                                }
                                                                label=" Interested Jobs"
                                                                value="2"
                                                                size="small"
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    fontFamily: "IBM Plex Sans,sans-serif",
                                                                }}
                                                            />

                                                        </TabList>
                                                    </Box>
                                                    <TabPanel
                                                        value="1"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        <RegistrationApplications regId={regId} />
                                                    </TabPanel>
                                                    <TabPanel
                                                        value="2"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        <RegInterestedJobs regId={regId}/>

                                                    </TabPanel>



                                                </TabContext>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RegistrationDetails
