import React, { useEffect, useState } from "react"

import { Modal, Card, CardBody, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"

import ListItem from "../../../components/Common/ListItem"

import {
  dateConverter,
  genderValue,
  jsonToExcel,
  numberToString,
  renderTableValue,
  timeConverter,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"

const BulkuploadDetails = ({ isOpen, onToggle, id }) => {
  const statusValue = {
    0: { text: "Success", color: "success" },
    1: { text: "Failed", color: "danger" },
  }
  const [tableData, setTableData] = useState([])
  const [masterData, setMasterData] = useState({})

  useEffect(() => {
    if (isOpen && id) handleData()
  }, [isOpen && id]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`interview/score/bulkupload/details?id=${id}`)
      const data = response.data
      const uploadedInterview = response?.data?.uploadedInterview || []
      
      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.type = data.type===1?"Internship":data.type===2?"Job":"--"
      data.intershipjob = data.internship?data.internship.name:data?.job?data.job.name:"---"
      data.interview = data.interview?.name
      data.staff = data.addedBy?.name

      data.total = numberToString(data.count?.total)
      data.failed = numberToString(data.count?.failed)
      data.success = numberToString(data.count?.success)

      setMasterData(data)

      uploadedInterview.map((item, index) => {
        item.id = index + 1
        item.status=(
            <span style={{color:item.status===1?"red":"green"}}>{item.status===0?"Success":item.status===1?"Failed":""}</span>
        )
        return item
      })

      setTableData(uploadedInterview)
    } catch (error) {
      console.error(error)
    }
  }

  console.log(tableData,"datatable");
  

  const handleExport = () => {
    const exports = []
    tableData.map(item => {
      const exportData = {}

      const status = statusValue[item.status]

      exportData["Name"] = item.name
      exportData["Mobile"] = item.mobile
      exportData["Email"] = item.email
      exportData["Register No"] = item.registerNo
      exportData["CGPA"] = item.cgpa
      exportData["Gender"] = item.gender
      exportData["Specialization"] = item.specialization
      exportData["Status"] = status?.text
      exportData["Remarks"] = item.remarks

      exports.push(exportData)

      return item
    })

    jsonToExcel(exports, `uploaded_candidates`)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Name", field: "name" },
      { label: "Reg.Id", field: "regId" },
      { label: "Total Mark", field: "totalMark"},
      { label: "Qualified", field: "qualified" },
      { label: "Status", field: "status" },
      { label: "Remarks", field: "remarks" },
      { label: "System Remark", field: "systemRemarks"},
    ],
    rows: tableData,
  }
  return (
    <>
      <Modal isOpen={isOpen} toggle={onToggle} size="xl" centered={true}>
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>View Details</h5>
          </div>
          <button
            type="button"
            onClick={onToggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ul className="list-details mb-3">
            <ListItem label="Date" value={masterData.date} />
            <ListItem label="Time" value={masterData.time} />
            <ListItem label="Type" value={masterData.type} />
            <ListItem label="Internship/Job" value={masterData.intershipjob} />
            <ListItem label="Interview" value={masterData.interview} />
            <ListItem label="Total" value={masterData.total} />
            <ListItem label="Success" value={masterData.success} />
            <ListItem label="Failed" value={masterData.failed} />
            <ListItem label="Added By" value={masterData.staff} />
            <ListItem
              label="File"
              value={
                <a href={`${API_URL}` + masterData?.fileName} download>
                  <i className="fa fa-file-excel excel-icon"></i>
                </a>
              }
            />
            <ListItem label="Remarks" value={masterData.remarks} />
          </ul>

          <Card>
            <CardBody>
              {/* {tableData.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Button onClick={handleExport} color="warning">
                    Export
                  </Button>
                </div>
              )} */}
              <MDBDataTable
                id="bulkUploadDetailsTable"
                responsive
                bordered
                data={data}
                info={false}
                paging={false}
                sortable={false}
                searching={false}
                disableRetreatAfterSorting={true}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  )
}

export default BulkuploadDetails
