import React, { useState, useEffect } from 'react'
import MiniWidget from './mini-widget';
import { Container, Row, Col, Label } from "reactstrap";
// import ReactApexChart from "react-apexcharts";
import ReactECharts from 'echarts-for-react';
import { get } from '../../../helpers/api_helper';
import moment from 'moment';
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

const JobfairDashboard = () => {
    const startingYear = 2020;
    const typeOption = [
        { label: "Year", value: 1 },
        { label: "Month", value: 2 },
        // { label: "7 Days", value: 3 },
        { label: "Custom", value: 4 }]

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const [counts, setcounts] = useState([])
    const [jobData, setJobData] = useState([])
    const [filterObject, setfilterObject] = useState({ type: 1 })
    const [monthOptions, setMonthOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [selectedValues, setselectedValues] = useState({ type: { label: "Year", value: 1 }, chart: { label: "Application Chart", value: 1 }, })
    const [jobApplications, setjobApplications] = useState([])

    const reports = [
        { id: 1, title: "Total JobFairs ", value: counts?.totalJobFairs},
        { id: 2, title: " Company Registrations", value: counts?. companyRegistrations },
        { id: 3, title: "Candidate Registrations", value: counts?.candidateRegistrations },
        { id: 4, title: "Total Jobs ", value: counts?.totalJobs },
        { id: 5, title: "Total JobApplications ", value: counts?.totalJobApplications },
        { id: 6, title: "Total Attendance ", value: counts?.totalAttendance },
        { id: 6, title: "Paid Candidates ", value: counts?.paidCandidates },
        { id: 6, title: "Total Payments ", value: counts?.totalPayments },
    ];

    const optionpi1 = { 
        title: {
            text: 'Jobfair Payments',
            // subtext: `${jobData.totalJobs} Total Jobs`,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        color: ['#ff6f61', '#6b8e23', '#4682b4', "rgb(205 223 225)"], // Define custom colors
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: jobData.data || [],
                label: {
                    show: true,
                    formatter: '{b}: {c} ({d}%)', // Displays name, value, and percentage
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    const optionpi2 = {
        title: {
            text: 'Top Hiring Companies',
            // subtext: 'Total Jobs 500',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            data: jobApplications.labels || [],
            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: jobApplications.data || [],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };

    useEffect(() => {
        fetchAlljoncounts()
        fetachallpieData()
    }, [])

    useEffect(() => {
        fetchallTophiring()
    }, [filterObject])



    function fetchAlljoncounts() {
        get(`jobfair-dashboard/counts`).then((res) => {
            setcounts(res.data)
        }).catch((err) => {

        })
    }

    

    function fetachallpieData() {
        get(`jobfair-dashboard/payment-pie`).then((res) => {
            setJobData(res)
        }).catch((err) => {

        })
    }

    

    function fetchallTophiring() {
        get(`jobfair-dashboard/top-hiring`).then((res) => {
            setjobApplications(res)
        }).catch((err) => {

        })
    }

    // ==================date filter=================





    return (
        <div className='page-content'>
            <div className='container-fluid'>
                <Row>
                    <h6></h6>
                    <MiniWidget reports={reports} />
                </Row>
                <Row className='mt-4'>
                    <Col md="6">
                        <ReactECharts option={optionpi1} style={{ height: 400, width: '100%' }} />
                    </Col>
                    <Col md="6">
                        <ReactECharts className='mt-2' option={optionpi2} style={{ height: 400, width: '100%' }} />
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default JobfairDashboard
