import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"
import { useNavigate } from "react-router-dom"

import Swal from "sweetalert2"
import toastr from "toastr"
import queryString from "query-string"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"

import {
  dateConverter,
  getDate,
  handleImageValidation,
  jsonToExcel,
  renderTableValue,
  setTitle,
} from "../../helpers/functions"
import { del, get, post, put } from "../../helpers/api_helper"
import "./styles.scss"
import FileUpload from "../../components/Common/FileUpload"
import SearchField from "../../components/Common/SearchField"
import moment from "moment"

const Company = props => {
  setTitle("Company")
  const todayDate = getDate()

  const statusValues = {
    0: { text: "Active", color: "success" },
    2: { text: "In Active", color: "warning" },
    4: { text: "Rejected", color: "danger" },
  }

  const statusOptions = [
    { label: "Active", value: 0 },
    { label: "In Active", value: 2 },
  ]

  const navigate = useNavigate()
  const formRef = useRef()
  const filterRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])

  const [industryOptions, setIndustryOptions] = useState([])
  const [designationOptions, setDesignationOptions] = useState([])

  const [filterStateOptions, setFilterStateOptions] = useState([])
  const [filterDistrictOptions, setFilterDistrictOptions] = useState([])
  const [filterIndustryOptions, setFilterIndustryOptions] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [loading, setLoading] = useState({ export: false })
  const [totalCount, setTotalCount] = useState(0)

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleStateOptions()
    handleDesignationOptions()
    handleIndustryOptions()

    handleFilterStateOptions()
    handleFilterDistrictOptions()
    handleFilterIndustryOptions()
  }, []) // eslint-disable-line

  const handleStateOptions = async () => {
    try {
      const response = await get(`options/states`)
      setStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDistrictOptions = async (state = "") => {
    try {
      const response = await get(`options/districts?state=${state}`)
      setDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDesignationOptions = async () => {
    try {
      const response = await get(`options/designation`)
      setDesignationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleIndustryOptions = async () => {
    try {
      const response = await get(`options/industry`)
      setIndustryOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterStateOptions = async () => {
    try {
      const response = await get(`company/options/filter/state`)
      setFilterStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterDistrictOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`company/options/filter/district?${params}`)
      setFilterDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleFilterIndustryOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`company/options/filter/industry?${params}`)
      setFilterIndustryOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`/company${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.district = renderTableValue(item.district?.name)
        item.staff = renderTableValue(item.updatedBy?.name)
        item.status = getStatus(item._id, item.status)

        item.shortCode = renderTableValue(item.shortCode)
        item.contactPerson = renderTableValue(item.contactPerson)
        item.mobile = renderTableValue(item.mobile)

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => {
                  navigate(`/company/${item?._id}`)
                }}
              ></i>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTotalCount(count)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleExport = async () => {
    try {
      setLoading(prev => ({ ...prev, export: true }))
      const query = `?limit=${totalCount}&${queryString.stringify(filterObject)}`
      const response = await get(`company${query}`)

      const { data } = response

      const exports = []
      if (!data.length) {
        toastr.info(`There are no reports to export`)
        return
      }

      data.map((item, index) => {
        const exportData = {}
        exportData["#"] = index + 1
        exportData["Date"] = dateConverter(item.date)
        exportData["Company Id"] = item.uniqueId
        exportData["Name"] = item.name
        exportData["Short Code"] = item.shortCode
        exportData["Contact Person"] = item.contactPerson
        exportData["Mobile"] = item.mobile
        exportData["District"] = item.district?.name
        exportData["Status"] = statusValues[item.status]?.text || ""
        exportData["Staff"] = item.updatedBy?.name
        exports.push(exportData)
        return item
      })
      jsonToExcel(exports, `Companies-${moment().format("DD-MM-YY")}`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`company`, masterObject)
      else response = await post(`company`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async id => {
    try {
      toTop()
      const response = await get(`/company/update/${id}`)

      const data = response.data

      setMasterObject({
        ...data,
        id,
        state: data?.state?.value,
        district: data?.district?.value,
        designation: data?.designation?.value,
        industry: data?.industry?.value,
      })
      setSelectedFields({
        state: data.state,
        district: data.district,
        industry: data?.industry,
        designation: data?.designation,
      })

      handleDistrictOptions(data?.state?.value)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`company/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`company/status`, { id, status })
      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      const fd = new FormData()
      if (e.target?.name === "logo") await handleImageValidation(selectedFile)
      fd.append("file", e.target.files[0])
      const response = await post(`company/file`, fd)
      handleValueChange({
        value: response.data.new_filename,
        name: e.target.name,
      })
    } catch (error) {
      toastr.error(error?.response?.data?.message || error)
      e.target.value = ""
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "state") {
      obj.district = null
    }
    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value
    if (name === "state") {
      obj.district = null
      handleDistrictOptions(value)
    }

    setMasterObject(obj)
  }
  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected

    if (name === "state") {
      obj.district = null
    }
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    obj[name] = value
    if (name === "state") {
      obj.district = null
      handleFilterDistrictOptions(value)
    }

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const getStatus = (id, status) => {
    if (status === 3) {
      return (
        <Select
          menuPosition="fixed"
          options={[
            { label: "Approve", value: 0 },
            { label: "Reject", value: 4 },
          ]}
          onChange={selected => handleStatusChange(id, selected.value)}
        />
      )
    } else {
      const statusObj = statusValues[status]
      return (
        <Button
          size="sm"
          color={statusObj.color}
          onClick={() => handleStatusChange(id, status === 0 ? 2 : 0)}
          disabled={status === 4}
        >
          {statusObj.text}
        </Button>
      )
    }
  }
  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()
    filterRef.current.reset()
    setMasterObject({})
    setSelectedFields({})

    setTotalPage(1)
    toTop()

    setSelectFilter({})
    setFilterObject({})
    handleFilterDistrictOptions()
    handleFilterStateOptions()
    handleFilterIndustryOptions()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Company ID", field: "uniqueId" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Contact Person", field: "contactPerson" },
      { label: "Mobile", field: "mobile" },
      { label: "District", field: "district" },
      { label: "Status", field: "status" },
      { label: "Staff", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Company" />

          <Card>
            <CardBody>
              <AvForm
                ref={formRef}
                onValidSubmit={handleSubmit}
                id="companyForm"
              >
                <Row>
                  <Col md={3}>
                    <Label>Industry</Label>
                    <Select
                      menuPosition="fixed"
                      options={industryOptions}
                      value={selectedFields.industry || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "industry")
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>
                        Name<span className="required">*</span>
                      </Label>
                      <AvField
                        name="name"
                        value={masterObject.name || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Name"
                        type="text"
                        errorMessage="Enter Name"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Short Code</Label>
                      <AvField
                        name="shortCode"
                        value={masterObject.shortCode || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Short Code"
                        type="text"
                        errorMessage="Enter Short code"
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>
                        Contact Person<span className="required">*</span>
                      </Label>
                      <AvField
                        name="contactPerson"
                        value={masterObject.contactPerson || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Contact Person"
                        type="text"
                        errorMessage="Enter Contact Person"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>
                        Designation<span className="required">*</span>
                      </Label>
                      <Select
                        menuPosition="fixed"
                        options={designationOptions}
                        value={selectedFields.designation || ""}
                        onChange={selected =>
                          handleSelectValueChange(selected, "designation")
                        }
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>
                        Mobile No.<span className="required">*</span>
                      </Label>
                      <AvField
                        name="mobile"
                        value={masterObject.mobile || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Mobile No."
                        type="text"
                        errorMessage="Enter valid mobile no"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "(0|91)?[6-9][0-9]{9}",
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>
                        Email<span className="required">*</span>
                      </Label>
                      <AvField
                        name="email"
                        value={masterObject.email || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Email"
                        type="email"
                        errorMessage=" Please provide a valid Email."
                        validate={{ email: true, required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Landline No.</Label>
                      <AvField
                        name="landLine"
                        value={masterObject.landLine || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Landline No."
                        type="text"
                        errorMessage="Enter valid 11 digit number"
                        validate={{ pattern: { value: "^[0-9]{11}$" } }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Website</Label>
                      <AvField
                        name="website"
                        placeholder="Website"
                        type="text"
                        value={masterObject?.website || ""}
                        onChange={e => handleValueChange(e.target)}
                        validate={{
                          pattern: {
                            value: /^(https?:\/\/[^\s$.?#].[^\s]*)$/,
                            errorMessage:
                              "Enter a valid URL (e.g. https://example.com)",
                          },
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <Label>
                      State<span className="required">*</span>
                    </Label>
                    <Select
                      menuPosition="fixed"
                      options={stateOptions}
                      value={selectedFields.state || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "state")
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>
                      District<span className="required">*</span>
                    </Label>
                    <Select
                      menuPosition="fixed"
                      options={districtOptions}
                      isDisabled={!masterObject.state}
                      value={selectedFields.district || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "district")
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>
                        Corporate Address<span className="required">*</span>
                      </Label>
                      <AvField
                        name="address"
                        placeholder="Corporate Address"
                        type="text"
                        value={masterObject?.address || ""}
                        validate={{ required: { value: true } }}
                        onChange={e => handleValueChange(e.target)}
                        errorMessage="Enter Corporate Address"
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Year of Establishment</Label>
                      <AvField
                        name="startYear"
                        type="date"
                        placeholder="Year of Establishment"
                        value={masterObject?.startYear || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Organization Size</Label>
                      <AvField
                        name="size"
                        placeholder="Organization Size"
                        type="text"
                        value={masterObject?.size || ""}
                        onChange={e => handleValueChange(e.target)}
                        validate={{ number: true, min: { value: 0 } }}
                        errorMessage="Enter a valid size"
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Service Offered</Label>
                      <AvField
                        name="serviceOffered"
                        placeholder="Service Offered"
                        type="text"
                        value={masterObject?.serviceOffered || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Pin Code</Label>
                      <AvField
                        name="pinCode"
                        placeholder="Pin code"
                        type="text"
                        validate={{
                          pattern: {
                            value: "^[0-9]{6}$",
                            errorMessage: "Enter a valid pin code",
                          },
                        }}
                        value={masterObject?.pinCode || ""}
                        onChange={e => handleValueChange(e.target)}
                        min={0}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>GST IN</Label>
                      <AvField
                        name="gstin"
                        placeholder="GST IN"
                        type="text"
                        validate={{
                          pattern: {
                            value:
                              "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$",
                            errorMessage: "Enter a valid GST IN",
                          },
                        }}
                        value={masterObject?.gstin || ""}
                        onChange={e => handleValueChange(e.target)}
                        min={0}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>PAN No.</Label>
                      <AvField
                        name="panNo"
                        placeholder="PAN No"
                        type="text"
                        validate={{
                          pattern: {
                            value: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                            errorMessage: "Enter a valid PAN No.",
                          },
                        }}
                        value={masterObject?.panNo || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>CIN/Reg No.</Label>
                      <AvField
                        type="text"
                        name="cinNo"
                        placeholder="CIN/Reg No."
                        value={masterObject?.cinNo || ""}
                        errorMessage="Enter CIN/Reg No."
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>TDS No.</Label>
                      <AvField
                        name="tdsNo"
                        placeholder="TDS No"
                        type="text"
                        validate={{
                          pattern: {
                            value: "^[A-Z]{4}[0-9]{5}[A-Z]{1}$",
                            errorMessage: "Enter a valid TDS No.",
                          },
                        }}
                        value={masterObject?.tdsNo || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Latitude</Label>
                      <AvField
                        name="latitude"
                        placeholder="Latitude"
                        type="number"
                        value={masterObject?.latitude || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Longitude</Label>
                      <AvField
                        name="longitude"
                        placeholder="Longitude"
                        type="number"
                        value={masterObject?.longitude || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <FileUpload
                      label="Logo"
                      imageSize="250x140"
                      name="logo"
                      fileUrl={masterObject?.logo}
                      onFileChange={handleFileUpload}
                      onDelete={() =>
                        handleValueChange({ value: "", name: "logo" })
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <FileUpload
                      label="GST Certificate"
                      name="gstCertificate"
                      fileUrl={masterObject?.gstCertificate}
                      onFileChange={handleFileUpload}
                      onDelete={() =>
                        handleValueChange({ value: "", name: "gstCertificate" })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <FileUpload
                      label="Pan Card"
                      name="panCard"
                      fileUrl={masterObject?.panCard}
                      onFileChange={handleFileUpload}
                      onDelete={() =>
                        handleValueChange({ value: "", name: "panCard" })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <FileUpload
                      label="Incorporation Certificate"
                      name="incorporationCertificate"
                      fileUrl={masterObject?.incorporationCertificate}
                      onFileChange={handleFileUpload}
                      onDelete={() =>
                        handleValueChange({
                          value: "",
                          name: "incorporationCertificate",
                        })
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label>
                        About Us
                      </Label>
                      <AvField
                        name="about"
                        placeholder="Aboutus"
                        type="textarea"
                        value={masterObject?.about || ""}
                        validate={{ required: { value: true } }}
                        onChange={e => handleValueChange(e.target)}
                        errorMessage="Enter aboutus"
                      />
                    </div>
                  </Col>

                  <Col md={2} className="d-flex align-items-center mt-2 pt-2">
                    <Button
                      color={masterObject?.id ? "warning" : "primary"}
                      className="me-2"
                      type="submit"
                    >
                      {masterObject?.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" type="button" onClick={reset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                  <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      type="date"
                      name="from"
                      value={filterObject.from || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      max={todayDate}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      type="date"
                      name="to"
                      value={filterObject.to || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      min={filterObject.from}
                      max={todayDate}
                    />
                  </Col>

                  <Col md={3}>
                    <Label>Industry</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterIndustryOptions}
                      value={selectFilter.industry || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "industry" })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>State</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterStateOptions}
                      value={selectFilter.state || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "state" })
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Label>District</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterDistrictOptions}
                      value={selectFilter.district || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "district" })
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Status</Label>
                    <Select
                      menuPosition="fixed"
                      options={statusOptions}
                      value={selectFilter.status || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "status" })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={filterObject.search || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      placeholder="Search name, short code, mobile"
                    />
                  </Col>
                  <Col col={3}>
                    <div className="d-flex gap-3 form-button">
                      <Button color="danger" onClick={reset}>
                        Reset
                      </Button>
                      <Button color="warning" onClick={handleExport}>
                        {loading.export ? (
                          <i className="fas fa-spinner fa-spin" />
                        ) : (
                          "Export"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                tableId="companyTable"
                page={page}
                onChange={handlePageChange}
                data={data}
                count={totalPage}
                totalCount={totalCount}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Company
