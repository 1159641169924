import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Container, Card } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { Divider, Tooltip } from "@mui/material"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import moment from "moment"

import { useParams } from "react-router-dom"
import {
  resultValues,
  statusValues,
  testStatusValues,
} from "../TestResults/helper"
import { typeValues } from "../TestCreation/helper"

import { get } from "../../../helpers/api_helper"
import {
  dateConverter,
  durationFormateSecond,
  numberToString,
  timeConverter,
} from "../../../helpers/functions"

import "./styles.scss"

const ResultDetails = () => {
  const [questions, setQuestions] = useState([])
  const [details, setDetails] = useState({})

  const { id } = useParams()

  useEffect(() => {
    if (id) handleData()
  }, [id]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`screening/result/details?id=${id}`)
      const data = response.data
      const questions = data.questions

      data.typeText = typeValues[data.type]
      data.assignTest = {
        startDate: data.assignTest?.startDate
          ? dateConverter(data.assignTest?.startDate)
          : "",
        endDate: data.assignTest?.endDate
          ? dateConverter(data.assignTest?.endDate)
          : "",
      }
      data.date = dateConverter(data.date)

      let status = 0
      if (data.status === testStatusValues.ABSENT) status = statusValues.ABSENT
      else if (data.status === testStatusValues.ONGOING)
        status = statusValues.ONGOING

      data.status = statusValues[status]
      data.result = resultValues[data.result]?.text

      data.startTime = timeConverter(data.startTime)
      data.endTime = timeConverter(data.endTime)

      data.markObtained = {
        total: numberToString(data.markObtained.total),
        general: numberToString(data.markObtained.general),
        specialization: numberToString(data.markObtained.specialization),
        percentage: data.markObtained.percentage,
      }

      data.maxMark = numberToString(data.maxMark)
      data.passMark = numberToString(data.passMark)
      data.duration = numberToString(data.duration)
      data.generalMark = numberToString(data.generalMark)
      data.generalPassMark = numberToString(data.generalPassMark)

      const counts = {
        questions: 0,
        attended: 0,
        correctAnswer: 0,
        wrongAnswer: 0,
        specializationQuestions: 0,
        specializationAttended: 0,
        specializationCorrectAnswer: 0,
        specializationWrongAnswer: 0,
        generalQuestions: 0,
        generalAttended: 0,
        generalCorrectAnswer: 0,
        generalWrongAnswer: 0,
      }

      if (Array.isArray(questions)) {
        questions.map((question, index) => {
          question.id = index + 1

          question.question = (
            <div>
              <Tooltip title={question.question} placement="top" arrow>
                <p>{question.question}</p>
              </Tooltip>
            </div>
          )

          question.selected = (
            <div>
              <Tooltip
                title={question[`option${question.answer}`]}
                placement="top"
                arrow
              >
                <p>{question[`option${question.answer}`]}</p>
              </Tooltip>
            </div>
          )

          question.answer = (
            <div>
              <Tooltip
                title={question[`option${question.rightAnswer}`]}
                placement="top"
                arrow
              >
                <p>{question[`option${question.rightAnswer}`]}</p>
              </Tooltip>
            </div>
          )

          question.status = <StatusIcon isChecked={question.isCorrect} />
          counts.questions++

          if (question.type === 1) {
            counts.generalQuestions++

            if (question.isMarked || question.isSkipAndMarked) {
              counts.generalAttended++
              counts.attended++
            }

            if (question.isCorrect) {
              counts.generalCorrectAnswer++
              counts.correctAnswer++
            } else {
              counts.generalWrongAnswer++
              counts.wrongAnswer++
            }
          } else if (question.type === 2) {
            counts.specializationQuestions++

            if (question.isMarked || question.isSkipAndMarked) {
              counts.specializationAttended++
              counts.attended++
            }
            if (question.isCorrect) {
              counts.specializationCorrectAnswer++
              counts.correctAnswer++
            } else {
              counts.specializationWrongAnswer++
              counts.wrongAnswer++
            }
          }

          return question
        })
        Object.assign(data, counts)
      } else {
        data.questions = 0
      }

      setDetails(data)
      setQuestions(questions || [])
    } catch (error) {
      console.error(error)
    }
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Question", field: "question" },
      { label: "Answer", field: "answer" },
      { label: "Answered", field: "selected" },
      { label: "Status", field: "status" },
    ],
    rows: questions,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Test Result Details" />

        <Row>
          <Col lg={3}>
            <Card className="card-shadow">
              <CardBody>
                <SectionHeading title="Candidate" />
                <InfoRow label="Name" value={details.candidate?.name} />
                <InfoRow label="Id" value={details.candidate?.uniqueId} />
                <InfoRow label="Mobile" value={details.candidate?.mobile} />
                <InfoRow label="Email" value={details.candidate?.email} />

                <SectionHeading title="Test" />
                <InfoRow label="Name" value={details.screeningTest?.name} />
                <InfoRow label="Id" value={details.screeningTest?.uniqueId} />
                <InfoRow label="Type" value={details.typeText} />
                <InfoRow
                  label="Start Date"
                  value={details.assignTest?.startDate}
                />
                <InfoRow label="End Date" value={details.assignTest?.endDate} />

                <Divider className="my-3" />
                <SectionHeading title="Result" />
                <InfoRow label={"Date"} value={details.date} />
                <InfoRow label={"Start Time"} value={details.startTime} />
                <InfoRow label={"End Time"} value={details.endTime} />
                <InfoRow
                  label={"Time Taken"}
                  value={durationFormateSecond(details.elapsedTime)}
                />
                <InfoRow label={"Status"} value={details.status} />
                <InfoRow label={"Result"} value={details.result} />
                <InfoRow
                  label={"Questions"}
                  value={numberToString(details.questions)}
                />
                <InfoRow
                  label={"Attended"}
                  value={numberToString(details.attended)}
                />
                <InfoRow
                  label={"Correct Answer"}
                  value={numberToString(details.correctAnswer)}
                />
                <InfoRow
                  label={"Wrong Answer"}
                  value={numberToString(details.wrongAnswer)}
                />
                <InfoRow
                  label={"Total Score"}
                  value={numberToString(details.markObtained?.total)}
                />
                <InfoRow
                  label={"Percentage"}
                  value={`${(details.markObtained?.percentage || 0).toFixed(2)}%`}
                />

                {details.type === typeValues.KTU && (
                  <>
                    <Divider className="my-3" />
                    <SectionHeading title="General" />
                    <InfoRow
                      label="Mark"
                      value={numberToString(details.generalMark)}
                    />
                    <InfoRow
                      label="Pass Mark"
                      value={numberToString(details.generalPassMark)}
                    />
                    <InfoRow
                      label="Questions"
                      value={numberToString(details.generalQuestions)}
                    />
                    <InfoRow
                      label="Attended"
                      value={numberToString(details.generalAttended)}
                    />
                    <InfoRow
                      label="Correct"
                      value={numberToString(details.generalCorrectAnswer)}
                    />
                    <InfoRow
                      label="Wrong"
                      value={numberToString(details.generalWrongAnswer)}
                    />
                    <InfoRow
                      label="Score"
                      value={numberToString(details.markObtained?.general)}
                    />

                    <Divider className="my-3" />
                    <SectionHeading title="Specialization" />
                    <InfoRow
                      label="Mark"
                      value={numberToString(details.maxMark)}
                    />
                    <InfoRow
                      label="Pass Mark"
                      value={numberToString(details.passMark)}
                    />
                    <InfoRow
                      label="Questions"
                      value={numberToString(details.specializationQuestions)}
                    />
                    <InfoRow
                      label="Attended"
                      value={numberToString(details.specializationAttended)}
                    />
                    <InfoRow
                      label="Correct"
                      value={numberToString(
                        details.specializationCorrectAnswer,
                      )}
                    />
                    <InfoRow
                      label="Wrong"
                      value={numberToString(details.specializationWrongAnswer)}
                    />
                    <InfoRow
                      label="Score"
                      value={numberToString(
                        details.markObtained?.specialization,
                      )}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>

          <Col lg={9} style={{ marginBottom: "30px" }}>
            <div className="mb-0 card card-shadow">
              <CardBody>
                <Row>
                  <Col className="col-12">
                    <MDBDataTable
                      id="resultDetails"
                      responsive
                      bordered
                      data={data}
                      searching={false}
                      paging={false}
                      info={false}
                      sortable={false}
                      disableRetreatAfterSorting={true}
                    />
                  </Col>
                </Row>
              </CardBody>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ResultDetails

const InfoRow = ({ label, value, hidden }) => {
  if (hidden) return null
  return (
    <>
      <div
        className="mt-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="mb-1">{label}:</p>
        <h5
          className="font-size-14 mb-0 text-wrap"
          style={{ wordBreak: "break-word", maxWidth: "100%" }}
        >
          {value || ""}
        </h5>
      </div>
      <hr style={{ margin: "0", color: "#00afc3" }} />
    </>
  )
}

const SectionHeading = ({ title }) => (
  <h5 className="font-size-16 text-uppercase text-muted mt-3 mb-2">{title}</h5>
)

const StatusIcon = ({ isChecked }) => (
  <i
    className={`fas ${isChecked ? "fa-check-circle" : "fa-times-circle"} ${isChecked ? "check-icon" : "un-check-icon"}`}
  />
)
