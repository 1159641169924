import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import { get } from '../../helpers/api_helper'
import TablePagination from "../../components/Common/TablePagination"
import "./Jobfairstyle.scss"
import moment from 'moment'
const JobfairJobs = ({ jobfairId }) => {

    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [tableData, settableData] = useState([])

    useEffect(() => {
        fetchAlljobs()
    }, [jobfairId])

    function fetchAlljobs() {
        get(`job-fair/details/jobs?id=${jobfairId}`).then((res) => {
            let result = res.data;
            const count = res.count;
            result.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"
                item.topcompany = item.topCompany === true ? (
                    <i className="fas fa-check text-success"></i>
                ) : <i className="fas fa-times text-danger"></i>;
                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            settableData(result)

        })
    }

    const handlePageChange = value => {
        setPage(value)
    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Job", field: "name" },
            { label: "ID", field: "uniqueId" },
            { label: "Company", field: "companyName" },
            { label: "Vacancy", field: "availableVacancy" },
            { label: "Applied", field: "totalApplied" },
            { label: "Placed", field: "totalPlaced" },
            { label: "Rejected", field: "totalRejected" },
            { label: "Interested", field: "interestedCandidates" },

        ],
        rows: tableData,
    }
    return (
        <>
            <Row>
                <TablePagination
                    page={page}
                    data={data}
                    tableId="jobfairJobtableDetailview"
                    onChange={handlePageChange}
                    count={totalPage}
                    totalCount={totalCount}
                />

            </Row>
        </>
    )
}

export default JobfairJobs
