import React, { useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"

import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import { get, put } from "../../../helpers/api_helper"
// import { qualificationOptions, QUALIFIED, typeOptions, TYPES } from "../helper"
import {qualificationOptions, QUALIFIED, typeOptions, TYPES  } from "../../Assessment/SME/helper"
import { MDBDataTable } from "mdbreact"

import "./style.scss"
import {
  numberToString,
  reactSelectCustomStyle,
  renderTableValue,
} from "../../../helpers/functions"

const InterviewDetail = () => {
  const formRef = useRef()

  // const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [internshipOptions, setInternshipOptions] = useState([])
  const [jobOptions, setJobOptions] = useState([])
  const [smeOptions, setSmeOptions] = useState([])

  const [candidates, setCandidates] = useState([])

  const handleInternshipOptions = async () => {
    try {
      const response = await get(`interview/score/options/internship`)
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobOptions = async () => {
    try {
      const response = await get(`interview/score/options/job`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSmeOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)

      const response = await get(`interview/score/options/interview?${query}`)
      setSmeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }


  const handleCandidateOptions = async (obj = masterObject) => {
    try {
      if (!obj.interview) return

      formRef.current?.reset()

      const query = queryString.stringify(obj)
      const response = await get(`interview/score/candidates?${query}`)
      const { data } = response

      const now = new Date()
      data.map((item, index) => {
        item.id = index + 1

        if (!item.updated) {
          item.totalMarkField = (
            <div className="sme-input"> 
              <AvField
                className="small-input"
                type="number"
                name={`totalMark-${now}-${index}`}
                max={item?.maxMark || 0}
                errorMessage={`Max mark :${item?.maxMark || 0}`}
                onChange={e =>
                  handleScoreChange({
                    value: e.target.value,
                    name: "totalMark",
                    index,
                  })
                }
              />
            </div>
          )
      
          item.remarksField = (
            <div className="remarks-input">
              <AvField
                placeholder="remarks"
                className="small-input"
                name={`remarks-${now}-${index}`}
                onChange={e =>
                  handleScoreChange({
                    value: e.target.value,
                    name: "remarks",
                    index,
                  })
                }
              />
            </div>
          )

          item.qualifiedField = (
            <div>
              <Select
                menuPosition="fixed"
                options={qualificationOptions}
                styles={reactSelectCustomStyle}
                onChange={selected =>
                  handleScoreChange({
                    value: selected?.value,
                    name: "qualified",
                    index,
                  })
                }
              />
            </div>
          )
        } else {
          item.technicalCompetenceField = numberToString(
            item.technicalCompetence,
          )
          item.totalMarkField = numberToString(item.totalMark)
          item.remarksField = renderTableValue(item.remarks)

          const qualification = QUALIFIED[item.qualified]

          if (qualification) {
            item.qualifiedField = (
              <Button {...qualification}>{qualification.text}</Button>
            )
          }
        }

        return item
      })
      setCandidates(data)
    } catch (error) {
      console.error(error)
      setCandidates([])
    }
  }

  const handleSubmit = async () => {
    try {
      const updated = []

      for (let candidate of candidates) {
        if (
          !candidate.qualified ||
          (!candidate.totalMark && candidate.qualified === QUALIFIED.QUALIFIED)
        ) {
          continue
        }

        const isTotalMarkValid =
          !candidate.totalMark ||
          candidate.maxMark >= candidate.totalMark

        if (
          candidate.qualified === QUALIFIED.QUALIFIED &&
          !isTotalMarkValid
        ) {
          continue
        }

        updated.push({
          id: candidate.candidate,
          totalMark: candidate.totalMark || 0,
          qualified: candidate.qualified,
          remarks: candidate.remarks,
        })
      }

      if (!updated.length) {
        toastr.error("Enter valid details")
        return
      }
      const response = await put(`interview/score/update`, {
        ...masterObject,
        candidates: updated,
      })
      toastr.success(response.message)
      reset()
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data?.message || error)
    }
  }

  const handleSelectValueChange = ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "type") {
      obj.job = null
      obj.interview = null
      obj.internship = null
    }
    if (name === "internship" || name === "job") {
      obj.interview = ""
    }

    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value

    if (name === "type") {
      if (value === TYPES.JOB && !jobOptions.length) {
        handleJobOptions()
      } else if (!internshipOptions.length) handleInternshipOptions()

      obj.job = null
      obj.interview = null
      obj.internship = null

      setCandidates([])
      formRef.current?.reset()
    }

    if (name === "internship" || name === "job") {
      obj.interview = null
      handleSmeOptions(obj)
      setCandidates([])
      formRef.current?.reset()
    }

    if (name === "interview") {
      handleCandidateOptions(obj)
    }
    setMasterObject(obj)
  }

  const handleScoreChange = ({ value, name, index }) => {
    setCandidates(prev =>
      prev.map((item, i) => (i === index ? { ...item, [name]: value } : item)),
    )
  }

  const reset = () => {
    formRef.current?.reset()

    setMasterObject({})
    setSelectedFields({})

    setSmeOptions([])
    setCandidates([])
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Register Id", field: "uniqueId" }, 
      { label: "Name", field: "name" },
     
      {
        label: (
          <Tooltip title="Total Mark" arrow placement="top">
            Mark.
          </Tooltip>
        ),
        field: "totalMarkField",
      },
      
     

      { label: "Qualified", field: "qualifiedField" },
      { label: "Remarks", field: "remarksField" },
    ],
    rows: candidates,
  }



  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md={3}>
                  <Label>Type</Label>
                  <Select
                    menuPosition="fixed"
                    options={typeOptions}
                    value={selectedFields.type || ""}
                    onChange={selected =>
                      handleSelectValueChange({ name: "type", selected })
                    }
                  />
                </Col>

                {masterObject.type === TYPES.INTERNSHIP && (
                  <Col md={3}>
                    <Label>Internship</Label>
                    <Select
                      menuPosition="fixed"
                      options={internshipOptions}
                      value={selectedFields.internship || ""}
                      onChange={selected =>
                        handleSelectValueChange({
                          name: "internship",
                          selected,
                        })
                      }
                    />
                  </Col>
                )}

                {masterObject.type === TYPES.JOB && (
                  <Col md={3}>
                    <Label>Job</Label>
                    <Select
                      menuPosition="fixed"
                      options={jobOptions}
                      value={selectedFields.job || ""}
                      onChange={selected =>
                        handleSelectValueChange({ name: "job", selected })
                      }
                    />
                  </Col>
                )}

                <Col md={3}>
                  <Label>Interview</Label>
                  <Select
                    options={smeOptions}
                    value={selectedFields.interview || ""}
                    isDisabled={!masterObject.internship && !masterObject.job}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "interview" })
                    }
                  />
                </Col>
                <Col>
                  <div className="d-flex gap-2 form-button">
                    <Button color="primary">Submit</Button>
                    <Button type="reset" onClick={reset} color="danger">
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
              <MDBDataTable
                id="smeUpdateScoreTable"
                data={data}
                bordered
                responsive
                searching={false}
                entries={20}
                paging={false}
                info={false}
                sortable={false}
                disableRetreatAfterSorting={true}
              />
            </AvForm>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default InterviewDetail
