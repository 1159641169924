import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  FormGroup,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"

import toastr from "toastr"

// Redux
import { Link, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
// Formik validation
// import * as Yup from "yup"
// import { useFormik } from "formik"

//Social Media Imports
// import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { apiError, loginSuccess, loginUser } from "../../store/actions"

// import images
import logo from "../../assets/images/asaplogo.svg"
// import logolight from "../../assets/images/logo-light.png"

import Cookies from "js-cookie"
import { APP_NAME } from "../../config"
import { post, put } from "../../helpers/api_helper"
import { decrypt, encrypt } from "../../helpers/functions"

import OtpInput from "react-otp-input"

//Import config
// import { facebook, google } from "../../config"

const Login = props => {
  document.title = `Login | ${APP_NAME}`

  const [passShow, setpassShow] = useState(false)

  const [master, setMaster] = useState({
    password: null,
    username: null,
    rememberMe: Boolean(Cookies.get("rememberMe")) || false,
  })

  const [details, setDetails] = useState({})

  const [twoStep, setTwoStep] = useState(false)
  const [OTP, setOTP] = useState(null)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const formRef = useRef()

  function handlePasswordReset() {
    put("user/password-reset", {
      data: encrypt(details),
    })
      .then(res => {
        dispatch(loginSuccess({ data: { passwordChanged: true, id: null } }))
        toastr.success(res.message)
        navigate("/logout")
      })
      .catch(err => {
        console.log(err.response.data)
      })
  }

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     email: "admin@themesbrand.com" || "",
  //     password: "123456" || "",
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string().required("Please Enter Your Email"),
  //     password: Yup.string().required("Please Enter Your Password"),
  //   }),
  //   onSubmit: values => {
  //     dispatch(loginUser(values, props.router.navigate))
  //   },
  // })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  const state = useSelector(state => state.Login.data)

  useEffect(() => {
    setDetails(pre => ({ ...pre, id: state._id }))
  }, [state])

  const verifyOtp = () => {
    post(`auth/two-step`, { token: OTP })
      .then(res => {
        const data = JSON.parse(decrypt(res?.data))
        if (!data.verified) {
          toastr.error(`OTP verification failed`)
        } else {
          if (state.rememberMe) {
            Cookies.set("rememberMe", true, { expires: 30 })
            Cookies.set("_token", state.refreshToken, { expires: 30 })
          } else {
            Cookies.set("_token", state.refreshToken)
            Cookies.remove("rememberMe")
          }
          if (state.module) {
            sessionStorage.setItem("module", state.module.moduleName)
            sessionStorage.setItem("module_code", state.module.shortCode)
            sessionStorage.setItem("module_id", state.module._id)
            sessionStorage.setItem("module_url", state.module.redirect_url)
          }

          delete state.twoFactor

          localStorage.setItem("authUser", JSON.stringify(state))

          navigate(state?.module?.redirect_url || "/dashboard")
        }
      })
      .catch(err => {
        console.log(err)
        toastr.error(err?.response?.data?.message)
      })
  }

  // const signIn = (res, type) => {

  //   if (type === "google" && res) {

  //     const postData = {
  //       name: res.profileObj.name,
  //       email: res.profileObj.email,
  //       token: res.tokenObj.access_token,
  //       idToken: res.tokenId,
  //     }
  //     dispatch(socialLogin(postData, props.router.navigate, type));
  //   } else if (type === "facebook" && res) {

  //     const postData = {
  //       name: res.name,
  //       email: res.email,
  //       token: res.accessToken,
  //       idToken: res.tokenId,
  //     }
  //     dispatch(socialLogin(postData, props.router.navigate, type))
  //   }
  // }

  //handleGoogleLoginResponse
  // const googleResponse = response => {
  //   signIn(response, "google")
  // }

  //handleFacebookLoginResponse
  // const facebookResponse = response => {
  //   signIn(response, "facebook")
  // }

  useEffect(() => {
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })

  const handleChange = e => {
    let { name, value, checked, type } = e.target
    value = type === "checkbox" ? JSON.parse(checked) : value
    setMaster(pre => ({ ...pre, [name]: value }))
    dispatch(apiError(""))
  }

  return (
    <React.Fragment>
      <div className="account-pages ">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center logoimg">
                        <Link to="/" className="mb-3 d-block auth-logo">
                          <img
                            src={logo}
                            alt=""
                            height="50"
                            className="logo logo-dark"
                          />
                          <img
                            src={logo}
                            alt=""
                            height="50"
                            className="logo logo-light"
                          />
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>

                    {state.passwordChanged ? (
                      state?.twoFactor?.qrCode || state?.twoFactor?.used ? (
                        twoStep || state?.twoFactor?.used ? (
                          <p className="text-muted">
                            Please enter your MFA code
                          </p>
                        ) : (
                          <p className="text-muted">
                            Please scan the QR Code using any authenticator app
                          </p>
                        )
                      ) : (
                        <p className="text-muted">
                          Sign in to continue to {APP_NAME}.
                        </p>
                      )
                    ) : (
                      <h6 className="text-muted">Change password.</h6>
                    )}
                  </div>

                  {state.passwordChanged ? (
                    state?.twoFactor?.qrCode || state?.twoFactor?.used ? (
                      twoStep || state?.twoFactor?.used ? (
                        <AvForm
                          className="needs-validation"
                          ref={formRef}
                          onValidSubmit={(e, v) => {
                            verifyOtp()
                          }}
                        >
                          <Row>
                            <div className="mb-3 d-flex justify-content-center py-3">
                              <OtpInput
                                value={OTP}
                                onChange={e => setOTP(e)}
                                inputStyle={{
                                  marginRight: ".7rem",
                                  border: "1px solid #80808087",
                                  borderRadius: "5px",
                                  width: "48px",
                                  height: "40px",
                                  fontSize: "26px",
                                  color: "#808080",
                                  fontWeight: "400",
                                }}
                                numInputs={6}
                                renderSeparator={<span>&nbsp;</span>}
                                renderInput={props => <input {...props} />}
                              />
                            </div>
                          </Row>
                          <Row className="justify-content-center">
                            <Col xs={8}>
                              <div className="mt-3">
                                <button
                                  className="btn btn-primary w-100 waves-effect waves-light"
                                  type="submit"
                                >
                                  Verify
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      ) : (
                        <AvForm
                          className="needs-validation"
                          ref={formRef}
                          onSubmit={(e, v) => {
                            setTwoStep(true)
                            // handlePasswordReset()
                          }}
                        >
                          <div className="modal-body pt-1">
                            <Row>
                              <Col md="12">
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    alt="qr-code"
                                    src={state?.twoFactor?.qrCode}
                                    width={200}
                                    height={200}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs={8}>
                                <div className="mt-3">
                                  <button
                                    autoFocus
                                    className="btn btn-primary w-100 waves-effect waves-light"
                                    type="submit"
                                  >
                                    Continue
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </AvForm>
                      )
                    ) : (
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={() => {
                          dispatch(loginUser(master, props.router.navigate))
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <AvField
                            name="username"
                            label="Username"
                            value={master.username}
                            className="form-control"
                            placeholder="Enter Username/Mobile/Email"
                            type="text"
                            required
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            value={master.password}
                            type="password"
                            required
                            placeholder="Enter Password"
                            onChange={handleChange}
                          />
                        </div>

                        <Row>
                          <Col xs={8}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                                name="rememberMe"
                                onClick={handleChange}
                                defaultChecked={master.rememberMe}
                                value={!master.rememberMe}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="float-start mt-4">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="mt-3">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    )
                  ) : (
                    <AvForm
                      className="needs-validation"
                      ref={formRef}
                      onValidSubmit={(e, v) => {
                        handlePasswordReset()
                      }}
                    >
                      <div className="modal-body pt-1">
                        <Row>
                          <Col md="12">
                            <FormGroup
                              className="my-3 position-relative"
                              id="confirm-pass"
                            >
                              <AvField
                                name="password"
                                placeholder="Password"
                                type={`${passShow ? "text" : "password"}`}
                                errorMessage=" Please provide a valid password"
                                validate={{ required: { value: true } }}
                                id="validationCustom05"
                                value={details.password || ""}
                                onChange={e =>
                                  setDetails(pre => ({
                                    ...pre,
                                    password: e.target.value,
                                  }))
                                }
                              />
                              {passShow ? (
                                <i
                                  className="fas fa-eye-slash position-absolute"
                                  onClick={() => setpassShow(pre => !pre)}
                                  style={{
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="fas fa-eye position-absolute"
                                  onClick={() => setpassShow(pre => !pre)}
                                  style={{
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <div className="mb-3">
                              <AvField
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                type={`${passShow ? "text" : "password"}`}
                                errorMessage="Please confirm the password"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  match: {
                                    value: "password",
                                    errorMessage: "Password not match",
                                  },
                                }}
                                id="validationCustom05"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="modal-footer">
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </div>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  ©{new Date().getFullYear()} {APP_NAME}. All Rights Reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
