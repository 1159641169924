import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import toastr from "toastr"
import Swal from "sweetalert2"
import queryString from "query-string"

import TablePagination from "../../../components/Common/TablePagination/index.jsx"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import {
  answerOptions,
  answerValues,
  statusValues,
  typeOptions,
  typeValues,
  forOptions,
  forValues,
} from "./helper.js"
import { renderTableValue, toTop } from "../../../helpers/functions.js"
import { del, get, post, put } from "../../../helpers/api_helper.js"

import { Tooltip } from "@mui/material"
import "./styles.scss"
import QuestionDetails from "./details.jsx"
import FileUpload from "../../../components/Common/FileUpload.jsx"
import { API_URL } from "../../../config.js"

const Questions = () => {
  const formRef = useRef()
  const filterRef = useRef()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({ questions: [{}] })
  const [selectedFields, setSelectedFields] = useState({ questions: [{}] })

  const [filterObject, setFilterObject] = useState({})
  const [selectedFilter, setSelectFilter] = useState({})

  const [internshipOptions, setinternshipOptions] = useState([])
  const [joboptions, setjoboptions] = useState([])

  const [filterInternshipOptions, setfilterInternshipOptions] = useState([])
  const [filterJoboptions, setfilterJoboptions] = useState([])
  const [specializationOptions, setSpecializationOptions] = useState([])
  const [filterSpecializationOptions, setFilterSpecializationOptions] =
    useState([])

  const [modal, setModal] = useState({ show: false, id: null })

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleSpecializationOptions()
    handleFilterSpecializationOptions()
    fetchFilterInternshipOptions()
    fetchFilterJoboptions()
  }, [])

  useEffect(() => {
    if (selectedFields?.for?.value === 2) {
      fetchallInternship()
    }
    if (selectedFields?.for?.value === 3) {
      fetchAlljobs()
    }


  }, [selectedFields.for])


  const handleSpecializationOptions = async () => {
    try {
      const response = await get(`options/specializations`)
      setSpecializationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterSpecializationOptions = async () => {
    try {
      const response = await get(
        `screening-questions/options/filter/specialization`,
      )
      setFilterSpecializationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  function fetchFilterJoboptions() {
    get(`screening-questions/options/filter/job`).then((res) => {
      setfilterJoboptions(res.data)
    }).catch((err) => {

    })
  }

  function fetchFilterInternshipOptions() {
    get(`screening-questions/options/filter/internship`).then((res) => {
      setfilterInternshipOptions(res.data)
    }).catch((err) => {

    })
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`screening-questions/list?${query}`)

      const { count, data } = response
      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.type = renderTableValue(typeValues[item.type])
        // item.specialization = renderTableValue(item.specialization?.name)
        item.for = renderTableValue(forValues[item.for])
        item.category = item.specialization?.name || item.internship?.name || item.job?.name
        item.category = (
          <div>
            <Tooltip title={item.category} placement="top" arrow>
              <p>{renderTableValue(item.category)}</p>
            </Tooltip>
          </div>
        )

        item.question = (
          <div>
            <Tooltip title={item.question} placement="top" arrow>
              <p>{item.question}</p>
            </Tooltip>
          </div>
        )
        item.answer = (
          <div>
            <Tooltip title={item[`option${item.answer}`]} placement="top" arrow>
              <p>{item[`option${item.answer}`]}</p>
            </Tooltip>
          </div>
        )
        item.mark = item?.mark

        const status = Number(item.status)
        const statusObj = statusValues[status]
        item.status = (
          <Button
            size="sm"
            color={statusObj.color}
            onClick={() => handleStatusChange(item._id, status === 0 ? 2 : 0)}
          >
            {statusObj.text}
          </Button>
        )

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => handleToggleModal({ show: true, id: item._id })}
              ></i>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              />
            </Tooltip>
            <Tooltip title="delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              />
            </Tooltip>
          </div>
        )

        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response

      if (masterObject.id)
        response = await put(`screening-questions/update`, masterObject)
      else response = await post(`screening-questions/create`, masterObject)

      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`screening-questions/update/${id}`)
      const data = response.data
      setMasterObject({
        ...data,
        name: data.question,
        specialization: data?.specialization?.value,
        id,
      })
      setSelectedFields({
        ...data,
        type: { label: typeValues[data.type], value: data.type },
        answer: { label: answerValues[data.answer], value: data.answer },
        for: { label: forValues[data.for], value: data.for }

      })
      toTop()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`screening-questions/delete/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      const fd = new FormData()
      fd.append("questions", e.target.files[0])
      const response = await post(`screening-questions/upload`, fd)
      const data = response.data

      const questions = []
      const questionsSelect = []


      if (data && Array.isArray(data) && data.length) {
        data.map(question => {
          questions.push({
            answer: question.Answer,
            option1: question.Option_1,
            option2: question.Option_2,
            option3: question.Option_3,
            option4: question.Option_4,
            mark: question.Mark,
            name: question.Question,
          })

          questionsSelect.push({
            answer: {
              label: answerValues[question.Answer],
              value: question.Answer,
            },
          })
          return question
        })
      }
      setMasterObject(prev => ({ ...prev, questions: questions }))
      setSelectedFields(prev => ({ ...prev, questions: questionsSelect }))
    } catch (error) {
      toastr.error(error?.response?.data?.message || error)
      e.target.value = null
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`screening-questions/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSelectChange = ({ selected, name, field, index }) => {
    const obj = { ...selectedFields }

    if (!field) obj[name] = selected
    else obj[field][index][name] = selected

    setSelectedFields(obj)

    handleValueChange({ name, value: selected?.value, field, index })
  }

  const handleValueChange = ({ value, name, field, index }) => {
    const obj = { ...masterObject }
    if (!field) obj[name] = value
    else {
      obj[field][index][name] = value
    }

    setMasterObject(obj)
  }

  const addMore = ({ index, add }) => {
    if (add) {
      setMasterObject(prev => ({
        ...prev,
        questions: [...(prev.questions || []), {}],
      }))

      setSelectedFields(prev => ({
        ...prev,
        questions: [...(prev.questions || []), {}],
      }))
    } else {
      setMasterObject(prev => ({
        ...prev,
        questions: prev.questions?.filter((_, i) => i !== index) || [],
      }))

      setSelectedFields(prev => ({
        ...prev,
        questions: prev.questions?.filter((_, i) => i !== index) || [],
      }))
    }
  }

  const handleFilterSelectChange = ({ selected, name }) => {
    const obj = { ...selectedFilter }
    obj[name] = selected

    setSelectFilter(obj)

    handleFilterValueChange({ name, value: selected?.value })
  }

  const handleFilterValueChange = ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value

    setFilterObject(obj)
  }

  const handleToggleModal = ({ show = false, id = null }) => {
    setModal({ show, id })
    if (!show) handleTableData()
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    filterRef.current?.reset()

    setMasterObject({ questions: [{}] })
    setSelectedFields({ questions: [{}] })

    setFilterObject({})
    setSelectFilter({})

    handleFilterSpecializationOptions({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "For", field: "for" },
      { label: "Type", field: "type" },
      { label: "Internship/Job/Spec", field: "category" },
      // { label: "Specialization", field: "specialization" },
      { label: "Question", field: "question" },
      { label: "Answer", field: "answer" },
      { label: "Mark", field: "mark" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  function fetchallInternship() {
    get(`screening/options/internship`).then((res) => {
      setinternshipOptions(res.data)
    }).catch((err) => {
    })
  }

  function fetchAlljobs() {
    get(`screening/options/job`).then((res) => {
      setjoboptions(res.data)
    }).catch((err) => {
    })
  }



  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Add Questions" />
        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={() => handleSubmit()}>
              <Row>
                <Col md={2} className="mb-2">
                  <Label>For</Label>
                  <Select
                    options={forOptions}
                    value={selectedFields.for || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "for" })
                    }
                  />
                </Col>
                {selectedFields?.for?.value === 2 ? (
                  <>
                    <Col className="mb-2" md={4}>
                      <Label>Internship</Label>
                      <Select
                        options={internshipOptions}
                        value={selectedFields.internship || ""}
                        onChange={selected =>
                          handleSelectChange({ selected, name: "internship" })
                        }
                      />
                    </Col>
                  </>
                ) : null}

                {selectedFields?.for?.value === 3 ? (
                  <>
                    <Col className="mb-2" md={4}>
                      <Label>Job</Label>
                      <Select
                        options={joboptions}
                        value={selectedFields.job || ""}
                        onChange={selected =>
                          handleSelectChange({ selected, name: "job" })
                        }
                      />
                    </Col>
                  </>
                ) : null}

                <Col md={2} className="mb-2">
                  <Label>Type</Label>
                  <Select
                    options={typeOptions}
                    value={selectedFields.type || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "type" })
                    }
                  />
                </Col>

                {masterObject.type === 2 && masterObject.for === 1 ? (
                  <Col className="mb-2" md={4}>
                    <Label>Specialization</Label>
                    <Select
                      options={specializationOptions}
                      value={selectedFields.specialization || ""}
                      onChange={selected =>
                        handleSelectChange({ selected, name: "specialization" })
                      }
                    />
                  </Col>
                ) : (
                  ""
                )}

                {!masterObject.id && (
                  <>
                    <Col md={3}>
                      <FileUpload
                        label="Import Questions"
                        name="import"
                        onFileChange={handleFileUpload}
                        accept=".xls, .xlsx"
                        fileUrl={""}
                        value={""}
                      />
                    </Col>
                    <Col md={3} style={{ marginTop: "26px" }}>
                      <Tooltip title="Download sample file">
                        <a
                          href={`${API_URL}sample/bulk_upload_questions_sample.xlsx`}
                          download
                          style={{ fontSize: "1rem", width: "fit-content" }}
                          className="d-flex align-items-center btn btn-success "
                        >
                          <i
                            style={{ fontSize: "13px" }}
                            className="fa fa-solid fa-download "
                          />
                          <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                            Download Sample Sheet
                          </span>
                        </a>
                      </Tooltip>
                    </Col>
                  </>
                )}
              </Row>
              {masterObject.id ? (
                <>
                  <Row className="mt-3">
                    <Col className="mb-2" md={6}>
                      <Label>Question</Label>
                      <AvField
                        rows={1}
                        type="textarea"
                        name="name"
                        placeholder="Question"
                        errorMessage="Enter Question"
                        value={masterObject.name || ""}
                        onChange={e => handleValueChange(e.target)}
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                    <Col className="mb-2" md={2}>
                      <Label>Answer</Label>
                      <Select
                        options={answerOptions}
                        value={selectedFields.answer || ""}
                        onChange={selected =>
                          handleSelectChange({ selected, name: "answer" })
                        }
                      />
                    </Col>
                    <Col className="mb-2" md={2}>
                      <Label>Mark</Label>
                      <AvField
                        name="mark"
                        type="number"
                        placeholder="Mark"
                        errorMessage="Enter Mark"
                        value={masterObject.mark || ""}
                        onChange={e => handleValueChange(e.target)}
                        validate={{ required: { value: true }, number: true }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {[1, 2, 3, 4].map(option => {
                      const name = `option${option}`
                      return (
                        <Col key={option} className="mt-2" md={3}>
                          <Label>Option {option}</Label>
                          <AvField
                            name={name}
                            placeholder={`Option ${option}`}
                            errorMessage="Enter Option"
                            value={masterObject[name] || ""}
                            onChange={e => handleValueChange(e.target)}
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </>
              ) : (
                <>
                  {masterObject.questions &&
                    masterObject.questions?.map((questions, index) => {
                      return (
                        <>
                          <Row className="mt-3">
                            <Col className="mb-2" md={6}>
                              <Label>Question {index + 1}</Label>
                              <AvField
                                rows={1}
                                type="textarea"
                                name={`name-${index}`}
                                placeholder="Question"
                                errorMessage="Enter Question"
                                value={questions.name || ""}
                                onChange={e =>
                                  handleValueChange({
                                    name: "name",
                                    value: e.target.value,
                                    field: "questions",
                                    index,
                                  })
                                }
                                validate={{ required: { value: true } }}
                              />
                            </Col>
                            <Col className="mb-2" md={2}>
                              <Label>Answer</Label>
                              <Select
                                options={answerOptions}
                                value={
                                  selectedFields.questions[index]?.answer || ""
                                }
                                onChange={selected =>
                                  handleSelectChange({
                                    selected,
                                    name: "answer",
                                    field: "questions",
                                    index,
                                  })
                                }
                              />
                            </Col>
                            <Col className="mb-2" md={2}>
                              <Label>Mark</Label>
                              <AvField
                                name={"mark" + index}
                                type="number"
                                placeholder="Mark"
                                errorMessage="Enter Mark"
                                value={questions.mark || ""}
                                onChange={e =>
                                  handleValueChange({
                                    name: "mark",
                                    value: e.target.value,
                                    field: "questions",
                                    index,
                                  })
                                }
                                validate={{
                                  required: { value: true },
                                  number: true,
                                }}
                              />
                            </Col>
                            {masterObject.questions?.length > 1 ? (
                              <Col
                                className="mb-2 form-button"
                                style={{ float: "right" }}
                                md={2}
                              >
                                <Button
                                  color="danger"
                                  type="reset"
                                  onClick={() => addMore({ index, add: false })}
                                >
                                  Remove
                                </Button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                          <Row>
                            {[1, 2, 3, 4].map(option => {
                              const name = `option${option}`
                              return (
                                <Col key={option} className="mt-2" md={3}>
                                  <Label>Option {option}</Label>
                                  <AvField
                                    name={name + index}
                                    placeholder={`Option ${option}`}
                                    errorMessage="Enter Option"
                                    value={questions[name] || ""}
                                    onChange={e =>
                                      handleValueChange({
                                        name: name,
                                        value: e.target.value,
                                        field: "questions",
                                        index,
                                      })
                                    }
                                    validate={{ required: { value: true } }}
                                  />
                                </Col>
                              )
                            })}
                          </Row>
                        </>
                      )
                    })}

                  <span
                    style={{ cursor: "pointer", textAlign: "center" }}
                    onClick={() => addMore({ add: true })}
                  >
                    + Add New
                  </span>
                </>
              )}
              <Col className="d-flex justify-content-end gap-3">
                <Button color={masterObject.id ? "warning" : "primary"}>
                  {masterObject.id ? "Update" : "Save"}
                </Button>
                <Button color="danger" type="reset" onClick={() => reset()}>
                  Reset
                </Button>
              </Col>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>For</Label>
                  <Select
                    options={forOptions}
                    value={selectedFilter.for || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "for" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Type</Label>
                  <Select
                    options={typeOptions}
                    value={selectedFilter.type || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "type" })
                    }
                  />
                </Col>
                <Col className="mb-2" md={4}>
                  <Label>Specialization</Label>
                  <Select
                    options={filterSpecializationOptions}
                    value={selectedFilter.specialization || ""}
                    onChange={selected =>
                      handleFilterSelectChange({
                        selected,
                        name: "specialization",
                      })
                    }
                  />
                </Col>
                <Col className="mb-2" md={3}>
                  <Label>Job</Label>
                  <Select
                    options={filterJoboptions}
                    value={selectedFilter.job || ""}
                    onChange={selected =>
                      handleFilterSelectChange({
                        selected,
                        name: "job",
                      })
                    }
                  />
                </Col>
                <Col className="mb-2" md={3}>
                  <Label>Internship</Label>
                  <Select
                    options={filterInternshipOptions}
                    value={selectedFilter.internship || ""}
                    onChange={selected =>
                      handleFilterSelectChange({
                        selected,
                        name: "internship",
                      })
                    }
                  />
                </Col>
                <Col className="form-button">
                  <Button color="danger" type="reset" onClick={() => reset()}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>

            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="questions"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
      <QuestionDetails
        isOpen={modal.show}
        onToggle={() => handleToggleModal({})}
        id={modal.id}
      />
    </div>
  )
}

export default Questions
