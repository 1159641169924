import React, { useState, useRef, useEffect, useTransition } from "react"
import Select from "react-select"
import { ratingOptions, AssignedForOptions, assignedForValues, categoryOptions, categoryValues, statgeOptions, stageValues } from "./helpers"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import cryingface from "../../assets/fotos/cryingface.svg"
import confusedface from "../../assets/fotos/confusedface.svg"
import Neutralface from "../../assets/fotos/neutralface.svg"
import slightlysmile from "../../assets/fotos/slightlysmileface.svg"
import grinningface from "../../assets/fotos/grinningface.svg"
import PieChart from "./PieChart"
import { get } from "../../helpers/api_helper"

const FeedbackReports = () => {
    const [formOptions, setformOptions] = useState([])
    const [masterObj, setmasterObj] = useState({})
    const [formData, setformData] = useState([])


    useEffect(() => {
        // getallReport()
        getallForm()
    }, [])


    function getallForm() {
        get(`feedback/options/form`).then((res) => {
            setformOptions(res.data)
        }).catch((err) => {

        })
    }

    function getallReport(id) {
        get(`/feedback/report?form=${id}`).then((res) => {
            console.log(res, "res report");
            setformData(res.data)

        })
    }

    function handleSelectChange(name, select) {
        setmasterObj({ ...masterObj, [name]: select.value })
        getallReport(select.value)

    }
    console.log(formData, "formdata");

    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Card style={{ minHeight: "50vh" }}>
                        <CardBody>
                            <Row>
                                <Col md="3">
                                    <Label>Select Form</Label>
                                    <Select
                                        options={formOptions}
                                        onChange={(selected) => {
                                            handleSelectChange("form", selected)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                {formData.length > 0 ? (
                                    <>
                                        {formData?.map((item, index) => (
                                            <>
                                                {item.type === 3 ? (
                                                    <>
                                                        <Col md="5">
                                                            <p style={{ fontSize: ".9rem" }}>
                                                                {item.question}
                                                            </p>
                                                            <table
                                                                style={{
                                                                    borderRadius: "10px",
                                                                    borderColor: "#80808017",
                                                                }}
                                                                className="table table-bordered"
                                                            >
                                                                <tbody>
                                                                    {item.answers.map((el, index) => (
                                                                        <>
                                                                            <tr>
                                                                                <td
                                                                                    style={{ textAlign: "start" }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "18px",
                                                                                        }}
                                                                                    >

                                                                                        <img
                                                                                            src={el.answer == 5 ? grinningface : el.answer == 4 ? slightlysmile : el.answer == 3 ? Neutralface : el.answer == 2 ? confusedface : el.answer == 1 ? cryingface : "---"}
                                                                                            alt="Grinning face"
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                {el.count} votes ({el.percentage}%)
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}



                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col md="7">
                                                            <PieChart answers={item?.answers} type={item?.type} />
                                                        </Col>
                                                    </>
                                                ) : null}
                                                {item.type === 1 ? (
                                                    <>
                                                        <Col md="5">
                                                            <p style={{ fontSize: ".9rem" }}>
                                                                {item.question}
                                                            </p>
                                                            <table
                                                                style={{
                                                                    borderRadius: "10px",
                                                                    borderColor: "#80808017",
                                                                }}
                                                                className="table table-bordered"
                                                            >
                                                                <tbody>
                                                                    {item?.answers?.map((el, id) => (
                                                                        <>
                                                                            <tr>
                                                                                <td
                                                                                    style={{ textAlign: "start" }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "18px",
                                                                                        }}
                                                                                    >
                                                                                        {el.answer == 1 ? "Yes" : el.answer == 2 ? "No" : "--"}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                {el.count} votes ({el.percentage}%)
                                                                                </td>
                                                                            </tr>
                                                                        </>

                                                                    ))}


                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col md="7">
                                                            <PieChart answers={item?.answers} type={item?.type} />
                                                        </Col>
                                                    </>
                                                ) : null}
                                                {item.type === 2 ? (
                                                    <>
                                                        <Col md="5">
                                                            <p style={{ fontSize: ".9rem" }}>
                                                                {item.question}
                                                            </p>
                                                            <table
                                                                style={{
                                                                    borderRadius: "10px",
                                                                    borderColor: "#80808017",
                                                                }}
                                                                className="table table-bordered"
                                                            >
                                                                <tbody>
                                                                    {item.answers.map((el, index) => (
                                                                        <>
                                                                            <tr>
                                                                                <td
                                                                                    style={{ textAlign: "start" }}
                                                                                >

                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "18px",
                                                                                        }}
                                                                                    >
                                                                                        {"⭐".repeat(Number(el.answer))}
                                                                                    </span>

                                                                                </td>
                                                                                <td>
                                                                                    {el.count} votes ({el.percentage}%)
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}


                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col md="7">
                                                            <PieChart answers={item?.answers} type={item?.type} />
                                                        </Col>

                                                    </>
                                                ) : null}



                                            </>
                                        ))}

                                    </>

                                ) : null}
                            </Row>
                        </CardBody>
                    </Card>

                </div>
            </div>

        </>
    )
}

export default FeedbackReports
