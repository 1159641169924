import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import TablePagination from "../../components/Common/TablePagination"
import { get } from '../../helpers/api_helper'
import moment from 'moment'


const JobsCompany = ({ uniqueId }) => {
    const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })
    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [tableData, setTableData] = useState([])
    const [totalCount, setTotalCount] = useState(0)



    useEffect(() => {
    fetchAllcompanyJobs()

    }, [])




    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "Job", field: "name" },
            { label: "UniqueID", field: "uniqueId" },
            { label: "Vacancy", field: "vacancies" },
            { label: "Available", field: "availableVacancy" },
            { label: "Approval", field: "approve" },
            { label: "Status", field: "status" },
        ],
        rows: tableData,
    }

    const handlePageChange = value => {
        setPage(value)
        toTop()
    }


    function fetchAllcompanyJobs() {
        get(`job-fair/manage-company/jobs?id=${uniqueId}`).then((res) => {
            const { data, count } = res;            
            data.map((item, index) => {
                item.id = (page - 1) * limit + index + 1;
                item.date=item?.date?moment(item.date).format("DD-MM-YYYY"):"---";
                item.status=item.status===0?"Ongoing":item.status===1?"Deleted":item.status===2?"Inactive":item.status===3?"Expired":item.status===4?"Filled":"---";
                item.approve=item.approvalStatus===1?"Approved":item.approvalStatus===2?"Rejected":item.approvalStatus===3?"Pending":"---"

                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            setTableData(data)

        })
    }

    return (
        <>
            <Row className='mt-2'>
                <TablePagination
                    page={page}
                    onChange={handlePageChange}
                    data={data}
                    tableId="jobfairapplicationtable"
                    count={totalPage}
                    totalCount={totalCount}

                />

            </Row>


        </>
    )
}

export default JobsCompany
