import React, { useEffect, useState } from 'react'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Container, Row, } from "reactstrap"
import Chartapex from './chatapex';
import { Card, CardBody, CardTitle, } from "reactstrap"
import "./campaignDashboard.scss"
import { get } from '../../../helpers/api_helper';
import MiniWidget from './mini-widget';
import { useNavigate } from 'react-router-dom';
function CampaignDashboard() {


  const [counts,setCounts] = useState(null)
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const navigate = useNavigate()

  useEffect(()=>{
    fetchCounts()
    fetchList()
  },[])

  const fetchCounts = async()=>{
    try {
      const res = await get('/campaign/getCampaignStatistics')
      setCounts(res?.data)
    } catch (error) {
      
    }
  }

  const fetchList = async()=>{
    try {
      const res = await get('/campaign/getTopCampaigns')
      setList(res?.data)
      setTotal(res?.total || 0)
    } catch (error) {
      
    }
  }
 
    const series1 = [50]

    const options1 = {
      fill: {
        colors: ["#34c38f"],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "60%",
          },
          track: {
            margin: 0,
          },
          dataLabels: {
            show: !1,
          },
        },
      },
    }
  
    const series2 = [70]
  
    const options2 = {
      fill: {
        colors: ["#34c38f"],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "60%",
          },
          track: {
            margin: 0,
          },
          dataLabels: {
            show: !1,
          },
        },
      },
    }
  
    const series3 = [55]
  
    const options3 = {
      fill: {
        colors: ["#34c38f"],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "60%",
          },
          track: {
            margin: 0,
          },
          dataLabels: {
            show: !1,
          },
        },
      },
    }
  
    const series4 = [33]
  
    const options4 = {
      fill: {
        colors: ["#34c38f"],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "60%",
          },
          track: {
            margin: 0,
          },
          dataLabels: {
            show: !1,
          },
        },
      },
    }
  
    const reports = [
          {
        id: 2,
        icon: "mdi mdi-arrow-up-bold",
        title: "Total Clicks",
        value: counts?.totalClicks || 0,
        iconClass: "fas fa-mouse-pointer me-2",
        decimal: 0,
        charttype: "radialBar",
        chartheight: 45,
        chartwidth: 45,
        prefix: "",
        suffix: "",
        badgeValue: "0%",
        color: "success",
        desc: "Total",
        series: series2,
        options: options2,
      },
  
    //   {
    //     id: 1,
    //     icon: "mdi mdi-arrow-up-bold",
    //     title: "Total Leads",
    //     value: counts?.totalLeads || 0,
    //     iconClass: "fas fa-search me-2",
    //     decimal: 0,
    //     charttype: "radialBar",
    //     chartheight: 45,
    //     chartwidth: 45,
    //     prefix: "",
    //     suffix: "",
    //     badgeValue: "0%",
    //     color: "success",
    //     desc: "Total",
    //     series: series1,
    //     options: options1,
    //   },

      {
        id: 3,
        icon: "mdi mdi-arrow-down-bold",
        title: "Total Students",
        value: counts?.totalStudents || 0,
        iconClass: "fas fa-user-graduate me-2",
        decimal: 0,
        charttype: "radialBar",
        chartheight: 45,
        chartwidth: 45,
        prefix: "",
        suffix: "",
        badgeValue: "0%",
        color: "danger",
        desc: "Total",
        series: series3,
        options: options3,
      },
      {
        id: 4,
        icon: "mdi mdi-arrow-up-bold",
        title: "Total amount",
        value: counts?.totalBudget || 0,
         iconClass: "fas fa-rupee-sign me-2",
        decimal: 0,
        charttype: "radialBar",
        chartheight: 45,
        chartwidth: 45,
        prefix: "",
        suffix: "",
        badgeValue: "0",
        color: "success",
        desc: "Total",
        series: series4,
        options: options4,
      },
    ]
    return (
      <div>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Campaign Dashboard" />
            <Row>
              <MiniWidget reports={reports} />
            </Row>
            <Row>
            <Col md="12" >
              <table className="table table-bordered campaign-dashboard ">
                <thead>
                  <tr>
                    <th className='campaign1stth' colspan="2"><p><i className="fas fa-users"></i> </p></th>
                    <th className='headfont_size'>Clicks</th>
                    {/* <th className='headfont_size'>Leads</th> */}
                    <th className='headfont_size'>Students</th>
                    <th className='headfont_size'>Budget</th>
                  </tr>
                </thead>
                <tbody className='headfontsize'>
                {list?.map((item, index) => (
                    <tr key={index}>
                      {index == 0 && (
                        <td
                          className="rowtd"
                          rowSpan="10"
                          style={{ height: "60px" }}
                        >
                          <p>Campaign</p>
                        </td>
                      )}
                      <th>{item?.name}</th>
                      <td>{item?.totalClicks}</td>
                      {/* <td>{item?.lead}</td> */}
                      <td>{item?.registeredStudent}</td>
                      <td>{item?.budget}</td>
                    </tr>
                  ))}
                  {total > 6 &&
                  <tr>
                    <td colSpan={5}><p onClick={()=>navigate('/campaign')} className='cursor-pointer'>Show More</p></td>
                  </tr>
                 }
                </tbody>
              </table>
            </Col> 
            </Row>
            <Row>
              <Col md="12" >
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Campaign @Asap Kerala</CardTitle>
                    <Chartapex />
                  </CardBody>
                </Card>
              </Col> 
            </Row>
          </Container>
        </div>
      </div>
    )
}

export default CampaignDashboard