export const paymentForOptions = [
  { label: "Registration Fee for Engineering Students", value: 1 },
  { label: "Completion Fee for Engineering Students", value: 2 },
  { label: "General Internship Application Fee", value: 3 },
  { label: "PSU Internship Application Fee", value: 4 },
  { label: "Job Application Fee", value: 5 },
  { label: "Job Fair Application Fee", value: 6 },
]

export const statusOptions = [
  { label: "Success", value: 0 },
  { label: "Failed", value: 1 },
]

export const paymentMethodOptions = [
  { label: "Online", value: 1 },
  { label: "Cash", value: 2 },
]

export const statusValues = {
  0: { text: "Success", color: "success" },
  1: { text: "Failed", color: "danger" },
}

export const paymentForValues = {
  1: "Registration Fee for Engineering students",
  2: "Completion Fee for Engineering students",
  3: "General Internship Application Fee",
  4: "PSU Internship Application Fee",
  5: "Job Application Fee",
  6: "Job Fair Application Fee",
}

export const METHODS = { 1: "Online", 2: "Cash", ONLINE: 1, CASH: 2 }
export const STATUS = {
  0: { text: "Success", color: "success", size: "sm" },
  1: { text: "Failed", color: "danger", size: "sm" },
}

export const PAYMENT_FOR = {
  1: "Registration Fee for Engineering students",
  2: "Completion Fee for Engineering students",
  3: "General Internship Application Fee",
  4: "PSU Internship Application Fee",
  5: "Job Application Fee",
  6: "Job Fair Application Fee",
  KTU_REGISTRATION: 1,
  KTU_INTERNSHIP_COMPLETION: 2,
  GENERAL_APPLICATION: 3,
  PSU_APPLICATION: 4,
  JOB_REGISTRATION: 5,
  JOB_FAIR_REGISTRATION: 6,
}
