import React, { useState, useEffect } from 'react'
import MiniWidget from './mini-widget';
import { Container, Row, Col, Label } from "reactstrap";
// import ReactApexChart from "react-apexcharts";
import ReactECharts from 'echarts-for-react';
import { get } from '../../../helpers/api_helper';
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from 'moment';


const Dashboard = () => {
    const startingYear = 2020;
    const typeOption = [
        { label: "Year", value: 1 },
        { label: "Month", value: 2 },
        // { label: "7 Days", value: 3 },
        { label: "Custom", value: 4 }]

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    useEffect(() => {
        fetchAllCardcount()
        fetchallPiechart()
        approvedIntershipCounts()
        getAllyears()
    }, [])

    const [countValues, setcountValues] = useState([])
    const [intershipPieData, setintershipPieData] = useState([])
    const [approvedCount, setapprovedCount] = useState([])
    const [selectedValues, setselectedValues] = useState({ type: { label: "Year", value: 1 }, chart: { label: "Application Chart", value: 1 }, })
    const [yearOptions, setYearOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([]);
    const [filterObject, setfilterObject] = useState({ type: 1 })
    const [applicationsChart, setapplicationsChart] = useState([])
    const [ktuApplicationChart, setktuApplicationChart] = useState([])
    const [psuApplicationChart, setpsuApplicationChart] = useState([])
    const [gerenralApplicationChart, setgerenralApplicationChart] = useState([])

    function fetchAllCardcount() {
        get(`internship/dashboard/counts`).then((res) => {
            setcountValues(res.data)
        }).catch((err) => {
        })
    }
    function fetchallPiechart() {
        get(`internship/dashboard/pie-chart`).then((res) => {
            setintershipPieData(res.data)

        })
    }

    function approvedIntershipCounts() {
        get(`internship/dashboard/approval-counts`).then((res) => {
            setapprovedCount(res)
        })
    }


    const reports = [
        { id: 1, title: "Total Internships", value: countValues.totalInternships },
        { id: 2, title: "Approved Internships", value: countValues.approvedInternships },
        { id: 3, title: "Active Internships", value: countValues.activeInternships },
        { id: 4, title: "Filled Internships", value: countValues.filledInternships },
        { id: 5, title: "InActive Internships", value: countValues.inActiveInternships },
        { id: 6, title: "Rejected Internships", value: countValues.rejectedInternships },
        { id: 7, title: "pendingInternships", value: countValues.pendingInternships },
    ];

    const optionpi1 = {
        title: {
            //   text: 'Main ware house 1',
            subtext: 'Internships',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        color: ['#ff6f61', '#6b8e23', '#4682b4'], // Define custom colors
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: intershipPieData || [],
                label: {
                    show: true,
                    formatter: '{b}: {c} ({d}%)', // Displays name, value, and percentage
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    const optionpi2 = {
        title: {
            subtext: 'Applications',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            // data: ['Applications', 'Completed', 'Leave/absent', 'Rejected'],
            data: applicationsChart.labels || [],

            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: applicationsChart.data || [],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };

    const ktuApplicationBar = {
        title: {
            subtext: 'KTU Applications',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            // data: ['Applications', 'Completed', 'Leave/absent', 'Rejected'],
            data: ktuApplicationChart.labels || [],

            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: ktuApplicationChart.data || [],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };

    const generalApplicationBar = {
        title: {
            subtext: 'General Applications',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            // data: ['Applications', 'Completed', 'Leave/absent', 'Rejected'],
            data: gerenralApplicationChart.labels || [],

            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: gerenralApplicationChart.data || [],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };

    const psuapplicationbar = {
        title: {
            subtext: 'PSU Applications',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            // data: ['Applications', 'Completed', 'Leave/absent', 'Rejected'],
            data: psuApplicationChart.labels || [],

            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: psuApplicationChart.data || [],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };

    const optionpi3 = {
        title: {
            subtext: 'Applications',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            data: ['Accepted', 'Rejected', 'In-Progress', 'Withdrawn'],
            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    200,
                    150,
                    80,
                    20
                ],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };

    const optionDG = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '',
            left: 'left'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 10,
                        fontWeight: ''
                    }
                },
                labelLine: {
                    show: false
                },
                data: approvedCount.ktu || []
            }
        ]
    };

    const optionDGeneral = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '',
            left: 'left'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 10,
                        fontWeight: ''
                    }
                },
                labelLine: {
                    show: false
                },
                data: approvedCount.general || []
            }
        ]
    };

    const optionPsu = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '',
            left: 'left'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 10,
                        fontWeight: ''
                    }
                },
                labelLine: {
                    show: false
                },
                data: approvedCount.psu || []
            }
        ]
    };


    // ===================FILTERDATE FUNCTIONS=================
    useEffect(() => {
        // Set defaults on page load
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // 0-based index

        // Set year options
        const startingYear = 2023; // Example starting year
        const years = Array.from(
            { length: currentYear - startingYear + 1 },
            (_, i) => ({
                label: (startingYear + i).toString(),
                value: startingYear + i,
            })
        );
        setYearOptions(years);

        // Set month options for the current year
        const monthsToDisplay = months.slice(0, currentMonth + 1);
        const defaultMonthOptions = monthsToDisplay.map((month, index) => ({
            label: `${currentYear}-${month.slice(0, 3)}`,
            value: `${currentYear}-${index + 1}`,
        }));
        setMonthOptions(defaultMonthOptions);

        // Set default filter object
        setselectedValues({ type: { label: "Year", value: 1 }, year: { label: currentYear, value: currentYear }, chart: { label: "Application Chart", value: 1 }, });
        setfilterObject({
            type: 1,
            year: currentYear,
            startDate: `${currentYear}-01-01`,
            endDate: moment().format("YYYY-MM-DD"), // Current date
        });
    }, []);



    const getStartAndEndOfYear = (year) => ({
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`,
    });

    const getStartAndEndOfMonth = (year, month) => {
        const startDate = moment(`${year}-${month}`, "YYYY-M").startOf("month").format("YYYY-MM-DD");
        const endDate = moment(`${year}-${month}`, "YYYY-M").endOf("month").format("YYYY-MM-DD");
        return { startDate, endDate };
    };

    function getAllyears() {
        const currentYear = new Date().getFullYear();
        const years = Array.from(
            { length: currentYear - startingYear + 1 },
            (_, i) => ({
                label: (startingYear + i).toString(),
                value: startingYear + i,
            })
        );
        setYearOptions(years);
    }

    function getcurrentyearMonth() {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // 0-based index
        const monthOptions = months.slice(0, currentMonth + 1).map((month, index) => ({
            label: `${currentYear}-${month.slice(0, 3)}`, // Format YYYY-MMM
            value: `${currentYear}-${index + 1}`, // Format YYYY-MM
        }));
        setYearOptions([]); // Reset year options
        setMonthOptions(monthOptions);

    }

    function handleSelectChange(name, selected) {
        setselectedValues({ ...selectedValues, [name]: selected });
        setfilterObject({ ...filterObject, [name]: selected.value });

        if (name === "type") {
            if (selected.value === 1) {
                getAllyears();
            } else if (selected.value === 2) {
                getcurrentyearMonth();
            }
        } else if (name === "month") {
            const [year, month] = selected.value.split("-");
            const { startDate, endDate } = getStartAndEndOfMonth(year, parseInt(month));

            setfilterObject({
                ...filterObject,
                month: selected.value,
                startDate,
                endDate,
            });
        }
    }




    const handleYearChange = (name, selectedYear) => {
        const selectedYearValue = selectedYear.value;
        const { startDate, endDate } = getStartAndEndOfYear(selectedYearValue);

        // Adjust months for the selected year
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // 0-based index
        const monthsToDisplay =
            selectedYearValue === currentYear
                ? months.slice(0, currentMonth + 1)
                : months;

        const monthOptions = monthsToDisplay.map((month, index) => ({
            label: `${selectedYearValue}-${month.slice(0, 3)}`,
            value: `${selectedYearValue}-${index + 1}`,
        }));

        setMonthOptions(monthOptions);
        setfilterObject({
            ...filterObject,
            [name]: selectedYearValue,
            startDate,
            endDate,
        });
    };


    function fetchAllapplications() {
        get(`internship/dashboard/applications-bar-chart?from=${filterObject?.startDate}&to=${filterObject?.endDate}`).then((res) => {
            setapplicationsChart(res)
        }).catch((res) => {

        })
    }

    function fetchAllktuApplications(){
        get(`internship/dashboard/ktu-applications-bar-chart?from=${filterObject?.startDate}&to=${filterObject?.endDate}`).then((res)=>{
            setktuApplicationChart(res)   
        }).catch((err)=>{

        })
    }

    function fetchAllgerneralApplication(){
        get(`internship/dashboard/general-applications-bar-chart?from=${filterObject?.startDate}&to=${filterObject?.endDate}`).then((res)=>{
            setgerenralApplicationChart(res)
        }).catch((res)=>{

        })
    }

    function fetchAllpsuApplication(){
        get(`internship/dashboard/psu-applications-bar-chart?from=${filterObject?.startDate}&to=${filterObject?.endDate}`).then((res)=>{
            setpsuApplicationChart(res)
        }).catch((err)=>{

        })
    }

    useEffect(() => {
        if (selectedValues?.chart.value === 1) {
            fetchAllapplications()
        }
        if(selectedValues.chart.value===2){
            fetchAllktuApplications()
        }
        if(selectedValues.chart.value===3){
            fetchAllgerneralApplication()
        }
        if(selectedValues.chart.value===4){
            fetchAllpsuApplication()
        }

    }, [selectedValues.chart,filterObject])

    return (
        <div className='page-content'>
            <div className='container-fluid'>
                <Row>
                    <h6></h6>
                    <MiniWidget reports={reports} />
                </Row>
                <Row className='mt-4'>
                    <Col md="6">

                        <ReactECharts option={optionpi1} style={{ height: 400, width: '100%' }} />
                    </Col>
                    <Col md="6">
                        <Row>
                            <Col md="3">
                                <Select
                                    options={typeOption}
                                    onChange={(selected) => handleSelectChange("type", selected)}
                                    value={selectedValues?.type}
                                />
                            </Col>
                            {selectedValues?.type?.value === 1 ? (
                                <Col md="3">
                                    <Select
                                        options={yearOptions}
                                        onChange={(selected) => handleYearChange("year", selected)}
                                        value={selectedValues?.year || null}
                                    />
                                </Col>
                            ) : null}
                            {selectedValues?.type?.value !== 4 ? (
                                <Col md="3">
                                    <Select
                                        options={monthOptions}
                                        onChange={(selected) => handleSelectChange("month", selected)}
                                        value={selectedValues?.month || null}
                                    />
                                </Col>
                            ) : null}
                            {selectedValues?.type?.value === 4 ? (
                                <Col md="6">
                                    <AvForm>
                                        <Row>
                                            <Col md="6">
                                                <AvField name="startDate" type="date" value={filterObject?.startDate || ""} />
                                            </Col>
                                            <Col md="6">
                                                <AvField name="endDate" type="date" value={filterObject?.endDate || ""} />
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </Col>
                            ) : null}
                            <Col md="3">
                                <Select
                                    options={[
                                        { label: "Application Chart", value: 1 },
                                        { label: "KTU Application Chart", value: 2 },
                                        { label: "General Application Chart", value: 3 },
                                        { label: "PSU Application Chart", value: 4 },
                                    ]}
                                    value={selectedValues?.chart || null}
                                    onChange={(selected) => handleSelectChange("chart", selected)}
                                />
                            </Col>
                        </Row>
                        {selectedValues?.chart.value === 1 ? (
                            <>                        
                            <ReactECharts key={selectedValues.chart.value} option={optionpi2} style={{ height: 400, width: '100%' }} />
                            </>
                        ):null}
                        {selectedValues?.chart.value === 2 ? (
                            <>                        
                            <ReactECharts key={selectedValues.chart.value} option={ktuApplicationBar} style={{ height: 400, width: '100%' }} />
                            </>
                        ):null}
                         {selectedValues?.chart.value === 3 ? (
                            <>                        
                            <ReactECharts key={selectedValues.chart.value} option={generalApplicationBar} style={{ height: 400, width: '100%' }} />
                            </>
                        ):null}
                        {selectedValues?.chart.value === 4 ? (
                            <>                        
                            <ReactECharts key={selectedValues.chart.value} option={psuapplicationbar} style={{ height: 400, width: '100%' }} />
                            </>
                        ):null}
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Row>
                            <Col md="6">
                                <h6>KTU Internships</h6>
                                <ReactECharts option={optionDG} style={{ height: 200, width: '100%' }} />
                            </Col>
                            <Col md="6">
                                <h6>GENERAL Internships</h6>
                                <ReactECharts option={optionDGeneral} style={{ height: 200, width: '100%' }} />
                            </Col>
                            <Col md="6">
                                <h6>PSU Internships</h6>
                                <ReactECharts option={optionPsu} style={{ height: 200, width: '100%' }} />
                            </Col>

                        </Row>
                    </Col>

                </Row>
            </div>
        </div>
    )
}

export default Dashboard
