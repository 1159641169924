export const STATUS = {
  2: { text: "Shortlisted", color: "warning" },
  3: { text: "Placed", color: "success" },
  4: { text: "Rejected", color: "danger" },
  5: { text: "Rejected", color: "danger" },

  PENDING: 0,
  APPLIED: 1,
  SHORTLISTED: 2,
  PLACED: 3,
  REJECTED: 4,
  REJECTED: 5,
}

export const FORWARD = { FORWARDED: 0, NOT: 1 }
