import React, { useEffect, useRef, useState } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"
import Swal from "sweetalert2"
import toastr from "toastr"

import TablePagination from "../../../components/Common/TablePagination"
import SearchSelect from "../../../components/Common/SearchSelect"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import Icon from "../../../components/Common/Icon"

import {
  dateConverter,
  getDate,
  reactSelectCustomStyle,
  renderTableValue,
  toTop,
  jsonToExcel,
} from "../../../helpers/functions"

import { filterStatusOptions, statusOptions, assessmentOptions } from "./helper"
import { FORWARD, STATUS } from "../helper"

import { get, put } from "../../../helpers/api_helper"

import "./styles.scss"
import Reject from "../Reject"
import Icon from "../../../components/Common/Icon"
import Details from "../Details"
import { percentageOptions } from "../../Internship/Applications/helper"

const JobApplications = () => {

 
  
  const formRef = useRef()
  const todayDate = getDate()
  const [loading, setLoading] = useState({ export: false })

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [totalCount, setTotalCount] = useState(1)


  const [filterObject, setFilterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])

  const [companyOptions, setCompanyOptions] = useState([])
  const [jobsOptions, setJobOptions] = useState([])

  const [viewModal, setViewModal] = useState({ show: false, id: null })
  const [modal, setModal] = useState({ show: false, id: null })

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleJobOptions()
  }, [filterObject]) //eslint-disable-line

  const handleCompanyOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`job/applications/options/company?${params}`)
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`job/applications/options/job?${params}`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`job/applications/list?${query}`)
    const { count, data } = response

    data.map((item, index) => {
      item.id = index + 1 + (currentPage - 1) * limit
      item.date = dateConverter(item.date)

      item.job = renderTableValue(item.job?.name)
      item.company = renderTableValue(item.company?.name)

      const candidate = item.candidate
      item.course = renderTableValue(candidate.course)
      item.candidateId = renderTableValue(candidate?.uniqueId)
      item.candidate = renderTableValue(
        `${candidate?.firstName || ""} ${candidate?.lastName || ""}`.trim(),
      )

      item.skills = `${item.skills?.matched}/${item.skills?.total}`
      item.screening = renderTableValue(item.screening.percentage)
      // item.interview = <StatusIcon value={item.interview} />
      item.sme = renderTableValue(item.sme.percentage)
      item.resume = item.resume?.uploaded

      const isForwarded = item.forwarded?.status === FORWARD.FORWARDED
      const isRejected = item.status === 4 || item.status === 5
      item.groom = <StatusIcon value={candidate.isGroomed} />

      const status = STATUS[item.status]
      if (status) {
        item.status = (
          <Button size="sm" color={status.color} disabled>
            {status.text}
          </Button>
        )
      } else
        item.status = (
          <Select
            styles={reactSelectCustomStyle}
            menuPosition="fixed"
            options={statusOptions}
            value={""}
            // isDisabled={!isForwarded}
            onChange={selected =>
              handleStatusChange({ id: item._id, status: selected?.value })
            }
          />
        )

      item.options = (
        <div className="d-flex align-items-center justify-content-center">
          <Icon
            icon="eye"
            title="View"
            onClick={() => handleViewModal(item._id, true)}
          />
          <Icon
            icon="share"
            title="Forward"
            onClick={() => handleForward(item._id)}
            hidden={isForwarded || isRejected}
          />
          <Icon
            icon="pdf"
            title="Uploaded Resume"
            href={item.resume}
            hidden={!item.resume}
          />
        </div>
      )

      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
    setTotalCount(count)

  }

  const handleExport = async () => {
    console.log("j");

    try {
      setLoading(prev => ({ ...prev, export: true }))

      const query = `limit=${totalCount}&${queryString.stringify(filterObject)}`
      const response = await get(`job/applications/export?${query}`)
      const { data } = response

      if (!data.length) {
        toastr.info(`There are no Candidates to export`)
        return
      }

      const exports = []
      const ASSESSMENT = { 1: "Qualified", 2: "Not Qualified" }

      data.map((item, index) => {
        const exportData = {}
        const candidate = item.candidate
        const status = STATUS[item.status]

        exportData.Date = dateConverter(item.date)

        exportData.Company = item.company?.name
        // exportData.Type = TYPE_VALUES[item.type]
        exportData.Internship = item.internship?.name

        exportData.Candidate =
          `${candidate?.firstName || ""} ${candidate?.lastName || ""}`.trim()
        exportData["Candidate Id"] = candidate?.uniqueId
        exportData.Groomed=candidate.isGroomed?"Yes":"No"
        exportData.Education = candidate.course
        exportData.Skills = `${item.skills?.matched}/${item.skills?.total}`
        exportData.Screening = ASSESSMENT[item.screening] || ""
        exportData.Interview = ASSESSMENT[item.interview] || ""
        exportData.Sme = ASSESSMENT[item.sme] || ""
        exportData.Status = status?.text || ""

        exportData.Applied = item.date
        exportData.Shortlisted = item?.shortlisted?.date;
        exportData.Placed = item?.placed?.date;
        exportData.Rejected = item?.rejected?.date;
        exports.push(exportData)

        return item
      })

      jsonToExcel(exports, `Job Applications`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }




  const handleStatusChange = async ({ id, status }) => {
    try {
      if (!id || !status) return

      if (status === STATUS.REJECTED) {
        handleToggle({ show: true, id })
        return
      }
      const response = await put(`job/applications/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error.response?.data?.message)
    }
  }

  const handleForward = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Forward",
      })
      if (!result.isConfirmed) return
      const response = await put(`job/applications/forward`, { id })
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error?.response?.data?.message || error)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value: value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleViewModal = (id, show = false) => {
    setViewModal({ id, show })
  }

  const handleToggle = ({ show = false, id = null } = {}) => {
    setModal({ show, id })
    handleTableData()
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    setFilterObject({})
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Company", field: "company" },
      { label: "Job", field: "job" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Education", field: "course" },
      { label: "Skills", field: "skills" },
      { label: "Screening(%)", field: "screening" },
      // { label: "Interview", field: "interview" },
      { label: "Interview(%)", field: "sme" },
      { label: "Groomed", field: "groom" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }



  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Job - Applications" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    options={companyOptions}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "company" })
                    }
                    value={selectedFields.company || ""}
                    isClearable
                  />
                </Col>

                <Col md={3}>
                  <Label>Job</Label>
                  <Select
                    options={jobsOptions}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "job" })
                    }
                    value={selectedFields.job || ""}
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "candidate" })
                    }}
                    value={selectedFields.candidate || ""}
                    isMulti
                    master={filterObject}
                    api="job/applications/options/candidate"
                  />
                </Col>
                <Col md={2}>
                  <Label>Assessment</Label>
                  <Select
                    isMulti
                    isClearable
                    menuPosition="fixed"
                    options={assessmentOptions}
                    value={selectedFields.assessment || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "assessment" })
                    }
                  />
                </Col>

                <Col md={3}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    options={filterStatusOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "status" })
                    }}
                    value={selectedFields.status || ""}
                    isClearable
                    isMulti
                  />
                </Col>
                <Col md={2}>
                  <Label>Interview(%)</Label>
                  <Select
                    isClearable
                    menuPosition="fixed"
                    options={percentageOptions}
                    value={selectedFields.sme || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "sme" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Screening(%)</Label>
                  <Select
                    isClearable
                    menuPosition="fixed"
                    options={percentageOptions}
                    value={selectedFields.screening || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "screening" })
                    }
                  />
                </Col>

                <Col style={{flexDirection:"row",alignItems:"center"}} className="d-flex gap-2 mt-4">
                  <Button onClick={reset} color="danger" type="button"> 
                    Reset
                  </Button>
                  <Button color="warning" onClick={handleExport}>
                    {loading.export ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      "Export"
                    )}
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="jobApplicationsTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
      <Details
        id={viewModal.id}
        isOpen={viewModal.show}
        onToggle={() => handleViewModal()}
      />
      <Reject
        id={modal.id}
        isOpen={modal.show}
        onToggle={() => handleToggle({})}
        status={modal.status}
      />
    </div>
  )
}

export default JobApplications

const StatusIcon = ({ value }) => {
  const isChecked = value
  const data = {
    icon: isChecked ? "check" : "unCheck",
    title: isChecked ? "Groomed" : "Not Groomed",
  }

  return <Icon icon={data.icon} title={data.title} />
}