export const ratingOptions = [
    {
        label: "Yes/No",
        value: 1,
    },
    {
        label: "Rating",
        value: 2,
    },
    {
        label: "Emoji Rating",
        value: 3,
    },
]
export const AssignedForOptions = [
    {
        label: "Candidate",
        value: 1,
    },
    {
        label: "College",
        value: 2,
    },
    {
        label: "Company",
        value: 3,
    },
]
export const assignedForValues = {
    1: "Candidate",
    2: "College",
    3: "Company",
    CANDIDATE: 1,
    COLLEGE: 2,
    COMPANY: 3,
}

export const categoryOptions = [
    { label: "Internships", value: 1 },
    { label: "Job", value: 2 },
    { label: "Job Fair", value: 3 },
    { label: "Other", value: 4 },
]

export const categoryValues = {
    1: "Internship",
    2: "Job",
    3: "Job Fair",
    4: "Other",
    INTERNSHIP: 1,
    JOB: 2,
    JOBFAIR: 3,
    OTHER: 4
}

export const statgeOptions = [
    { label: "Placed", value: 1 },
    { label: "Reject", value: 2 },
    { label: "Complete", value: 3 },
    { label: "Vacancy Filled", value: 4 },
    { label: "Completion Letter", value: 5 },
    { label: "Jobfair Completion", value: 6 },
]


 export const stageValues = {
    1: "Placed",
    2: "Reject",
    3: "Complete",
    4: "Vacancy Filled",
    5: "Completion Letter",
    6: "Jobfair Completion",
}