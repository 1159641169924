import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import { get } from '../../helpers/api_helper'
import TablePagination from "../../components/Common/TablePagination"
import "./Jobfairstyle.scss"
import moment from 'moment'
const Jobfiarcompany = ({jobfairId}) => {

    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [tableData, settableData] = useState([])

    useEffect(() => {
        fetchAllcompany()
    }, [jobfairId])

    function fetchAllcompany() {
        get(`job-fair/details/companies?id=${jobfairId}`).then((res) => {
            let result = res.data;
            const count = res.count;
            result.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"
                item.topcompany = item.topCompany===true ? (
                    <i className="fas fa-check text-success"></i> 
                ) :   <i className="fas fa-times text-danger"></i>; 
                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            settableData(result)

        })
    }

    const handlePageChange = value => {
        setPage(value)
    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "ID", field: "registrationId"},
            { label: "Company", field: "name"},
            { label: "Mobile", field: "mobile" },
            { label: "Jobs", field: "totalJobs" },
            { label: "Top Company", field: "topcompany" },

        ],
        rows: tableData,
    }
    return (
        <>
            <Row>
                <TablePagination
                    page={page}
                    data={data}
                    tableId="jobfaircompanytableDetailview"
                    onChange={handlePageChange}
                    count={totalPage}
                    totalCount={totalCount}
                />

            </Row>
        </>
    )
}

export default Jobfiarcompany
