export const forOptions = [
  { label: "Candidate", value: 1 },
  { label: "Internship", value: 2 },
  { label: "KTU", value: 3 },
  { label: "JOB", value: 4 },

]

export const filterForOptions = [
  { label: "Candidate", value: 1 },
  { label: "Internship", value: 2 },
  { label: "KTU", value: 3 },
  { label: "JOB", value: 4 },

]

export const FOR_VALUES = {
  1: "Candidate",
  2: "Internship",
  3: "KTU",
  4: "JOB",
  CANDIDATE: 1,
  INTERNSHIP: 2,
  KTU: 3,
  JOB: 4,
}

export const STATUS_VALUES = {
  ACTIVE: 0,
  IN_ACTIVE: 2,
  ONGOING: 3,
  COMPLETED: 4,
  0: { text: "Active", color: "success", size: "sm" },
  2: { text: "In Active", color: "warning", size: "sm" },
  3: { text: "Ongoing", color: "info", size: "sm", disabled: true },
  4: { text: "Completed", color: "primary", size: "sm", disabled: true },
}

export const statusOptions = [
  { label: "Active", value: STATUS_VALUES.ACTIVE },
  { label: "In Active", value: STATUS_VALUES.IN_ACTIVE },
  { label: "Ongoing", value: STATUS_VALUES.ONGOING },
  { label: "Completed", value: STATUS_VALUES.COMPLETED },
]
