import React, { useEffect, useState } from "react"
import { Button, Modal } from "reactstrap"
import { get } from "../../../helpers/api_helper"
import {
  dateConverter,
  numberToCurrency,
  timeConverter,
} from "../../../helpers/functions"
import { METHODS, PAYMENT_FOR } from "../Payments/helper"

export default function PaymentDetails({ id, isOpen, onToggle }) {
  const [data, setData] = useState({})

  useEffect(() => {
    if (isOpen && id) handleData()
  }, [id, isOpen]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`payments/details?id=${id}`)
      const data = response.data
      data.name = data.candidate?.name || "-"
      data.candidateId = data.candidate?.uniqueId || "-"

      data.university = data.university?.name || "-"
      data.college = data.college?.name || "-"
      data.paymentFor = PAYMENT_FOR[data?.paymentFor] || "-"
      data.method = METHODS[data?.from] || "-"

      data.uniqueId = data.uniqueId || "-"

      data.feeAmount = numberToCurrency(data.feeAmount)
      data.amount = numberToCurrency(data.amount)

      data.mobile = data?.candidate?.mobile || "-"
      data.email = data?.candidate?.email || "-"

      data.state = data?.state?.name || "-"
      data.district = data?.district?.name || "-"
      data.course = data?.course?.name || "-"
      data.specialization = data?.specialization?.name || "-"

      data.status = (
        <Button
          size="sm"
          disabled
          style={{ padding: "0.25rem", color: "white" }}
          color={data.status === 0 ? "success" : "danger"}
        >
          {data.status === 0 ? "Success" : "Failed"}
        </Button>
      )

      data.paymentId = data.razorpayDetails?.paymentId || "-"
      data.orderId = data.razorpayDetails?.orderId || "-"

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.addedDate = dateConverter(data.addedDate)
      data.addedTime = timeConverter(data.addedTime)

      setData(data)
    } catch (error) {
      console.error(error)
      onToggle()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onToggle} size="lg" centered={true}>
      <div className="modal-header">
        <div className="modal-title">
          <h5 style={{ marginBottom: "0px" }}>View Details</h5>
        </div>
        <button
          type="button"
          onClick={onToggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <table id="detailsTable" className="table table-bordered dataTable">
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Candidate</td>
            <td>: {data?.name}</td>
            <td style={{ width: "150px" }}>Candidate Id</td>
            <td style={{ textAlign: "left" }}>: {data?.candidateId}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Mobile</td>
            <td>: {data?.mobile}</td>
            <td style={{ width: "150px" }}>Email</td>
            <td style={{ textAlign: "left" }}>: {data?.email}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>University :</td>
            <td>: {data?.university}</td>
            <td style={{ width: "150px" }}>Institute</td>
            <td style={{ textAlign: "left" }}>: {data?.college}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Course :</td>
            <td>: {data?.course}</td>
            <td style={{ width: "150px" }}>Specialization</td>
            <td style={{ textAlign: "left" }}>: {data?.specialization}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>State :</td>
            <td>: {data?.state}</td>
            <td style={{ width: "150px" }}>District</td>
            <td style={{ textAlign: "left" }}>: {data?.district}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Receipt No</td>
            <td>: {data?.uniqueId}</td>
            <td style={{ width: "150px" }}>Method</td>
            <td style={{ textAlign: "left" }}>: {data?.method}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Payment For :</td>
            <td colSpan={3}>: {data?.paymentFor}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Fee</td>
            <td style={{ textAlign: "left" }}>: {data?.feeAmount}</td>
            <td style={{ width: "150px" }}>Amount</td>
            <td style={{ textAlign: "left" }}>: {data?.amount}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Status</td>
            <td>: {data?.status}</td>
            <td style={{ width: "150px" }}>Remarks</td>
            <td style={{ textAlign: "left" }}>: {data?.remarks}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Payment Id</td>
            <td>: {data?.paymentId}</td>
            <td style={{ width: "150px" }}>Order Id</td>
            <td style={{ textAlign: "left" }}>: {data?.orderId}</td>
          </tr>

          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Date :</td>
            <td>: {data?.date}</td>
            <td style={{ width: "150px" }}>Time</td>
            <td style={{ textAlign: "left" }}>: {data?.time}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Added Date :</td>
            <td>: {data?.addedDate}</td>
            <td style={{ width: "150px" }}>Added Time</td>
            <td style={{ textAlign: "left" }}>: {data?.addedTime}</td>
          </tr>
        </table>
      </div>
    </Modal>
  )
}
