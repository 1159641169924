import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col } from "reactstrap"
import { get } from "../../helpers/api_helper"
import { useNavigate } from "react-router-dom"
import useSearchValue from "../../hooks/useSearchValue"

function SearchnameMobile({ onDataSelected }) {
  const [keyWord, setKeyWord] = useState()
  const selectedRef = useRef(null)

  const [selectedId, setSelectedId] = useState(-1)
  const [highlightedIndex, setHighlightedIndex] = useState(-1)

  const navigate = useNavigate()
  const [filteredData, setFilteredData] = useState([])
  const [optionsCust, setOptionsCust] = useState([])

  const [anchorElStudent, setAnchorElStudent] = useState(false);

  const studentRef = useRef();


  const searchValue = useSearchValue(keyWord, 500)


  useEffect(() => {
    if (searchValue) {
      handleSearchChange(searchValue)
    }
  }, [searchValue])

  const handleSearchChange = search => {
    get(`module/search?keyword=${search}`).then(res => {
      const data = res.candidates.concat(res.college,res.company)
      data.map(item => {
        item.value = item._id
        item.label =
          (item.name ? `${item.name} ` : "") +
          (item.email ? `${item.email} ` : "") +
          (item.mobile ? `${item.mobile} ` : "")
        return item
      })
      setSelectedId(-1)
      setHighlightedIndex(-1)
      setOptionsCust(data)

      setFilteredData(data)
    })
  }
  const [showAdditionalFields, setShowAdditionalFields] = useState({
    show: false,
  })


  const handleKeyDown = e => {
    if (e.key === "ArrowUp" && highlightedIndex > 0) {
      e.preventDefault()
      setHighlightedIndex(highlightedIndex - 1)
    } else if (
      e.key === "ArrowDown" &&
      highlightedIndex < optionsCust.length - 1
    ) {
      e.preventDefault()
      setHighlightedIndex(highlightedIndex + 1)
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      e.preventDefault()
      setKeyWord("")
      setOptionsCust([])
      const selectedOption = optionsCust[highlightedIndex] // Get the selected option
      
      if (selectedOption) {
        if(selectedOption.type==1){
          navigate("/candidate-profile/" + selectedOption.uniqueId)  
        }else if(selectedOption.type==2){
          navigate("/institute/" + selectedOption._id)  
        }else if(selectedOption.type==3){
          navigate("/company/" + selectedOption._id)  
        }
      }
    }
  }

  

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [selectedRef, highlightedIndex])

  const handleSelectSearch = e => {
    setOptionsCust([])
    setKeyWord("")
   
      if(e.type==1){
        navigate("/candidate-profile/" + e.uniqueId)  
      }else if(e.type==2){
        navigate("/institute/" + e._id)  
      }else if(e.type==3){
        navigate("/company/" + e._id)  
      }
    
  }

  const handleSearch1 = () => {
    const value = keyWord
    
    setOptionsCust([])

    setKeyWord("")
    navigate("/student-details", {
      state: { key: value },
    })
  }

  const handleNavigate=(item)=>{
    if(item.type==1){
      navigate("/candidate-profile/" + item.uniqueId)  
    }else if(item.type==2){
      navigate("/institute/" + item._id)  
    }else if(item.type==3){
      navigate("/company/" + item._id)  
    }
  }


  useEffect(() => {
    function handleCloseStudentSearch(e) {
      if (!studentRef.current.contains(e.target)){

        setKeyWord("")
        setOptionsCust([])
      } 
    }
    document.addEventListener("mousedown", handleCloseStudentSearch);
    return () => {
      document.removeEventListener("mousedown", handleCloseStudentSearch);
    };
  });

  return (
    <div className="search-bar" ref={studentRef}>
      <AvForm onValidSubmit={handleSearch1}>
        <Row>
          <Col md="12">
            <div
              style={{ position: "relative", minWidth: "215px" }}
              className="mb-1 mt-3 ms-2 "
            >
              <AvField
                menuPosition="fixed"
                placeholder="Search Candidates/Company/College"
                value={keyWord}
                name="subcategory"
                onKeyDown={e => {
                  handleKeyDown(e)
                  let keyWord = e.target.value

                  if (
                    e.key === "Backspace" &&
                    (!keyWord || keyWord.key === 0)
                  ) {
                    setOptionsCust([])
                  }
                }}
                onChange={e => {
                  if (e.target.value.length === 0) {
                    setOptionsCust([])
                  }
                    setKeyWord(e.target.value)
                }}
                classNamePrefix="select2-selection"
                onFocus={() => setShowAdditionalFields({ show: false })}
              />
              {optionsCust.length > 0 && !showAdditionalFields.show && (
                <div class="fetch-names-div">
                  {optionsCust.map((item, index) => (
                    
                    <>
                      <a
                       
                        onClick={() =>
                         handleNavigate(item)
                        }
                        key={item.name}
                      >
                        <p
                          className="select-email-p"
                         
                          ref={highlightedIndex === index ? selectedRef : null}
                          onClick={e => {
                            handleSelectSearch(item)
                          }}
                          style={{
                            backgroundColor:
                              index === highlightedIndex
                                ? "rgb(240, 249, 255)"
                                : "",
                          }}
                        >
                          {item.label}
                        </p>
                      </a>
                    </>
                  ))}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </AvForm>
    </div>
  )
}

export default SearchnameMobile
