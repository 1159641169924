import React, { useEffect, useState } from "react"

import { Modal, Card, CardBody, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"

import ListItem from "../../../../components/Common/ListItem"

import {
    dateConverter,
    genderValue,
    numberToString,
    timeConverter,
} from "../../../../helpers/functions"
import { get } from "../../../../helpers/api_helper"
import { API_URL } from "../../../../config"
import { STATUS_VALUES } from "./helper"

const AssignSmeDetails = ({ isOpen, onToggle, id }) => {
    const statusValue = {
        0: { text: "Success", color: "success" },
        1: { text: "Failed", color: "danger" },
    }
    const [tableData, setTableData] = useState([])
    const [masterData, setMasterData] = useState({})

    useEffect(() => {
        if (isOpen && id) handleData()
    }, [isOpen && id]) // eslint-disable-line

    const handleData = async () => {
        try {
            const response = await get(`sme-assign/details?id=${id}`)
            const data = response.data
            const tableData = response?.data?.candidates || []
            data.date = dateConverter(data.date)
            data.time = timeConverter(data.time)
            data.startDate = dateConverter(data.startDate)
            data.startTime = timeConverter(data.startTime)
            data.endDate = dateConverter(data.endDate)
            data.endTime = timeConverter(data.endTime)
            data.staff = data.addedBy
            data.assign=data?.smeUser
            setMasterData(data)
            tableData.map((item, index) => {
                item.id = index + 1
                item.name = item.firstName + " " + item.lastName || "----"
                item.email = item.email || "----"
                item.mobile = item.mobile || "----"
                item.totalMark = item.totalMark || "---"
                item.remarks = item.remarks || "----"
                item.gender = genderValue(item.gender) || "----"
                item.status = item?.qualified == 1 ? "Qualified" : item?.qualified == 2 ? "Not qualified" : "---"
                return item
            })

            setTableData(tableData)
        } catch (error) {
            console.error(error)
        }
    }
    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Name", field: "name" },
            { label: "UniqueId", field: "uniqueId" },
            // { label: "Updated", field: "updated" },
            { label: "Mobile", field: "mobile" },
            { label: "Mark", field: "totalMark" },
            { label: "Status", field: "status" },
            { label: "Remarks", field: "remarks" },
        ],
        rows: tableData,
    }
    return (
        <>
            <Modal isOpen={isOpen} toggle={onToggle} size="xl" centered={true}>
                <div className="modal-header">
                    <div className="modal-title">
                        <h5 style={{ marginBottom: "0px" }}>View Details</h5>
                    </div>
                    <button
                        type="button"
                        onClick={onToggle}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <ul className="list-details mb-3">
                        <ListItem label="Interview" value={masterData.interview} />
                        <ListItem label="Internship" value={masterData.internship} />
                        <ListItem label="Start Date" value={masterData.startDate} />
                        <ListItem label="Start Time" value={masterData.startTime} />
                        <ListItem label="End Date" value={masterData.endDate} />
                        <ListItem label="End Time" value={masterData.endTime} />
                        <ListItem label="Total Candidates" value={masterData.totalCandidates} />
                        <ListItem label="Added By" value={masterData.staff} />
                        <ListItem label="Status" value={masterData.statusname} />
                        <ListItem label="Staff" value={masterData.assign} />

                    </ul>

                    <Card>
                        <CardBody>
                            <MDBDataTable
                                id="collegeBulkDetailsTable"
                                responsive
                                bordered
                                data={data}
                                info={false}
                                paging={false}
                                sortable={false}
                                searching={false}
                                disableRetreatAfterSorting={true}
                            />
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </>
    )
}

export default AssignSmeDetails
