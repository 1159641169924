export const URLS = {
  LIST: "payments/cash",
  CREATE: "payments/cash",

  FUND_SOURCE: "options/fund-source",
  CANDIDATE: `payments/cash/search/candidate`,
  INTERNSHIP: `payments/cash/options/internship`,
  JOB: `payments/cash/options/job`,
  JOB_FAIR: `payments/cash/options/job-fair`,
}
