import React, { useEffect, useState, useRef } from 'react'
import { get, put } from '../../helpers/api_helper'
import TablePagination from "../../components/Common/TablePagination"
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { reactSelectCustomStyle } from '../../helpers/functions'
import Select from "react-select"
import Breadcrumb from '../../components/Common/Breadcrumb'
import { AvField, AvForm } from "availity-reactstrap-validation"
import Reject from './Reject'
import toastr from "toastr"
import Icon from '../../components/Common/Icon'
import Details from './Details'
import "./Jobfairstyle.scss"

const JobfairPlaced = () => {
    const formRef = useRef()
    const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })
    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [tableData, setTableData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [filterObject, setFilterObject] = useState({})
    const [companyOptions, setCompanyOptions] = useState([])
    const [selectedFields, setSelectedFields] = useState({})
    const [jobfairoptions, setjobfairoptions] = useState([])
    const [modal, setModal] = useState({ show: false, id: null, status: 4 })
    const [viewModal, setViewModal] = useState({ show: false, id: null })
    const [jobOptions, setjobOptions] = useState([])


    useEffect(() => {
        fetchAlljobfair()
        fetchAllCompany()
    }, [])

    useEffect(() => {
        fetchAllJobOptions()
    }, [filterObject?.company, filterObject?.jobFair])


    useEffect(() => {
        fetchAllApplications()
    }, [filterObject, page])

    function fetchAllApplications() {
        get(`job-fair/applications/placed?page=${page}&limit=${limit}&jobFair=${filterObject?.jobFair}&company=${filterObject?.company}&status=${filterObject?.status}&from=${filterObject?.from}&to=${filterObject?.to}&job=${filterObject?.job}`).then((res) => {
            const { data, count } = res;
            data.map((item, index) => {
                item.id = (page - 1) * limit + index + 1;
                item.candidatename = item.candidate.firstName + " " + item?.candidate?.lastName;
                item.candidateID = item?.candidate?.uniqueId;
                switch (item.status) {
                    case 2:
                        item.status = (
                            <Select
                                styles={reactSelectCustomStyle}
                                menuPosition="fixed"
                                value={""} // Add meaningful value or state
                                options={[
                                    { label: "Placed", value: 3 },
                                    { label: "Reject", value: 4 },
                                ]}
                                onChange={selected => handleStatusChange({ id: item._id, status: selected.value })}
                            />
                        );
                        break;
                    case 3:
                        item.status = (
                            <Button  style={{width:"100%"}} disabled size="sm" color="success">
                                Placed
                            </Button>
                        );
                        break;
                    case 4:
                        item.status = (
                            <Button style={{width:"100%"}} disabled size="sm" color="danger">
                                Rejected
                            </Button>
                        );
                        break; 

                    default:
                        item.status = "Unknown"; // Add a fallback for unexpected status values
                        break;
                }

                item.options = (
                    <div className="d-flex align-items-center justify-content-center">
                        <Icon
                            icon="eye"
                            title="View"
                        onClick={() => handleViewModal(item._id, true)}
                        />
                    </div>
                )


                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            setTableData(data)

        })
    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "App.ID", field: "uniqueId" },
            { label: "Candidate", field: "candidatename" },
            { label: "Can.ID", field: "candidateID" },
            { label: "Job Fair", field: "jobFair" },
            { label: "Job", field: "job" },
            { label: "Company", field: "company" },
            { label: "Status", field: "status" },
            { label: "Actions", field: "options" },

        ],
        rows: tableData,
    }

    const handlePageChange = value => {
        setPage(value)
        toTop()
    }

    const reset = () => {
        toTop()
        formRef.current?.reset()
        setFilterObject({})
        setSelectedFields({})
    }

    const handleValueChange = (e) => {
        let { name, value } = e.target
        setFilterObject({ ...filterObject, [name]: value })
    }

    const handleSelectValueChange = (selected, name) => {
        if (name === "jobFair") fetchAllCompany(selected?.value)
        setSelectedFields({ ...selectedFields, [name]: selected })
        setFilterObject({ ...filterObject, [name]: selected?.value })
    }

    function fetchAlljobfair() {
        get(`job-fair/applications/jobfair`).then((res) => {
            setjobfairoptions(res.data)
        }).catch((err) => {

        })
    }

    function fetchAllCompany(id = "") {
        get(`job-fair/applications/company?jobFair=${id}`).then((res) => {
            setCompanyOptions(res.data)
        }).catch((err) => {
        })
    }

    const handleStatusChange = async ({ id, status }) => {
        try {
            if (!id || !status) return

            if (status === 4) {
                handleToggle({ show: true, id })
                return
            }
            const response = await put(`/job-fair/applications/status`, {
                id,
                status,
            })

            fetchAllApplications()
            toastr.success(response.message)
        } catch (error) {
            toastr.error(error.response?.data?.message)
        }
    }

    const handleToggle = ({ show = false, id = null, status = 4 } = {}) => {
        setModal({ show, id, status })
        fetchAllApplications()
    }

    const handleViewModal = (id, show = false) => {
        setViewModal({ id, show })
    }

    function fetchAllJobOptions() {
        get(`job-fair/applications/job?jobFair=${filterObject?.jobFair}&company=${filterObject?.company}`).then((res) => {
            setjobOptions(res.data)
        }).catch((err) => {

        })
    }

    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Jobfair Placed Applications " />

                    <Row>
                        <Card>
                            <Row className='mt-3'>
                                <AvForm ref={formRef}>
                                    <Row>
                                        <Col md={2}>
                                            <Label>From</Label>
                                            <AvField
                                                type="date"
                                                name="from"
                                                value={filterObject.from || ""}
                                                onChange={handleValueChange}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Label>To</Label>
                                            <AvField
                                                type="date"
                                                name="to"
                                                value={filterObject.to || ""}
                                                onChange={handleValueChange}
                                                min={filterObject?.from}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label>Job Fair</Label>
                                            <Select
                                                options={jobfairoptions}
                                                onChange={(selected) => {
                                                    handleSelectValueChange(selected, "jobFair")
                                                }}
                                                value={selectedFields.jobFair || ""}
                                                isClearable
                                            />
                                        </Col>

                                        <Col md={2}>
                                            <Label>Company</Label>
                                            <Select
                                                options={companyOptions}
                                                onChange={(selected) => {
                                                    handleSelectValueChange(selected, "company")
                                                }}
                                                value={selectedFields.company || ""}
                                                isClearable
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Label>Job</Label>
                                            <Select
                                                options={jobOptions}
                                                onChange={(selected) => {
                                                    handleSelectValueChange(selected, "job")
                                                }}
                                                value={selectedFields.job || ""}
                                                isClearable
                                            />
                                        </Col>
                                        {/* <Col md={2}>
                                            <Label>Status</Label>
                                            <Select
                                                name="status"
                                                options={[
                                                    { label: "Applied", value: 1 },
                                                    { label: "Shortlisted", value: 2 },
                                                    { label: "Placed", value: 3 },
                                                    { label: "Rejected", value: 4 },
                                                ]}
                                                value={selectedFields.status || ""}
                                                isClearable
                                                onChange={(selected) => {
                                                    handleSelectValueChange(selected, "status")
                                                }}
                                            />
                                        </Col> */}
                                        <Col className="form-button">
                                            <Button onClick={reset} color="danger" type="button">
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </Row>
                            <Row className='mt-2'>
                                <TablePagination
                                    page={page}
                                    onChange={handlePageChange}
                                    data={data}
                                    tableId="jobfairplacedTable"
                                    count={totalPage}
                                    totalCount={totalCount}

                                />

                            </Row>


                        </Card>
                    </Row>

                </div>
            </div>
            <Reject
                id={modal.id}
                isOpen={modal.show}
                onToggle={() => handleToggle({})}
                status={modal.status}
            />
            <Details
                id={viewModal.id}
                isOpen={viewModal.show}
                onToggle={() => handleViewModal()}
            />

        </>
    )
}

export default JobfairPlaced
