import React, { useEffect, useRef, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
// import { getCampaign } from "../../../store/options/actions"
import Select from "react-select"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"//eslint-disable-line
import { get } from "../../../helpers/api_helper"
import moment from "moment"
// import TablePagination from "../../../components/Common/Pagination"
function TrackClicks() {
  const dispatch = useDispatch()//eslint-disable-line
  const formRef = useRef()

  // const { Campaign } = useSelector(campaign => campaign.Options)
  const [tableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)//eslint-disable-line
  const [page, setPage] = useState(1)
  const limit = 10

  const [selectedFromDate, setSelectedFromDate] = useState("")
  const [selectedToDate, setSelectedToDate] = useState("")
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [state, setState] = useState(false)
  const [campaignOptions, setCampaignOptions] = useState([])
  const [resetAll, setResetAll] = useState(false)

  // useEffect(() => {
  //   dispatch(getCampaign({}))
  // }, [dispatch])

  useEffect(() => {
    fetchCampaignOptions()
    fetchData()
  }, [page])//eslint-disable-line

  useEffect(() => {
    if (resetAll) fetchData()
  }, [resetAll]) //eslint-disable-line

  const fetchCampaignOptions = async () => {
    try {
      const response = await get("/options/campaign")
      setCampaignOptions(response.data)
    } catch (error) {}
  }

  const handleChange = (event, value) => {//eslint-disable-line
    setPage(value)
  }

  const fetchData = async () => {
    try {
      const filterQuery = `page=${page}&limit=${limit}&campaign=${selectedCampaign?.value}&fromDate=${selectedFromDate}&toDate=${selectedToDate}`
      const response = await get(`/campaign/click-track?${filterQuery}`)

      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(response.data)
      setResetAll(false)
    } catch (error) {}
  }

  const reset = () => {
    formRef.current.reset()
    setSelectedFromDate("")
    setSelectedToDate("")
    setSelectedCampaign(null)
    setState(!state)
    setCampaignOptions(null)
    setResetAll(true)
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Campaign URL",
        field: "Campaign",
        width: 150,
      },
      {
        label: "Campaign ",
        field: "CampaignName",
        width: 150,
      },

      {
        label: "jobFair",
        field: "jobFair",
        width: 150,
      },

      // {
      //     label: "Actions",
      //     field: "options",
      //     sort: "desc",
      //     width: 400,
      // },
    ],
    rows: tableData.map((item, index) => {
      console.log("item", item)
      return {
        id: index + 1 + (page - 1) * limit,
        Campaign: (
          <>
            <a href={`${item?.url}`}>{item?.url}</a>
          </>
        ),
        date: moment(item?.date).format("DD-MM-YYYY"),
        time: moment(item.time, "HH:mm:ss").format("hh:mm A"),
        jobFair: item?.jobFairDetails[0]?.name,
        CampaignName: item?.CampaignsDetils[0]?.name,
        options: (
          <div>
            <i
              className="uil-edit-alt"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      }
    }),
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Track Clicks/Leads" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md="2">
                        <Label>From Date</Label>
                        <AvField
                          name="date"
                          type="date"
                          onChange={e => setSelectedFromDate(e.target.value)}
                          value={selectedFromDate}
                          // validate={max: {}}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </Col>
                      <Col md="2">
                        <Label>To Date</Label>
                        <AvField
                          name="date"
                          type="date"
                          onChange={e => setSelectedToDate(e.target.value)}
                          value={selectedToDate}
                          min={selectedFromDate}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </Col>

                      <Col md="2">
                        <Label>Campaign</Label>

                        <div className="mb-3">
                          <Select
                            name="Description"
                            options={campaignOptions}
                            // options={Campaign}
                            placeholder="Select Campaign"
                            onChange={item => setSelectedCampaign(item)}
                            value={selectedCampaign}
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ marginTop: "1.7rem" }}>
                        <Button
                          onClick={fetchData}
                          type="submit"
                          color="success"
                        >
                          Filter
                        </Button>

                        <Button
                          style={{ marginLeft: "1rem" }}
                          onClick={reset}
                          color="danger"
                          type="submit"
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>

                  <MDBDataTable
                    id="Campaign1Id"
                    bordered
                    responsive
                    searching={false}
                    entries={20}
                    paging={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  {/* <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default TrackClicks
