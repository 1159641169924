import React, { useRef, useState, useEffect } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"

import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import { get, post, put } from "../../../../helpers/api_helper"
import { qualificationOptions, QUALIFIED, typeOptions, TYPES } from "../helper"
import { MDBDataTable } from "mdbreact"
import TablePagination from "../../../../components/Common/TablePagination"
// import "./styles.scss"
import {
    dateConverter,
    numberToString,
    reactSelectCustomStyle,
    renderTableValue,
} from "../../../../helpers/functions"
import { API_URL } from "../../../../config"
import axios from "axios"
import BulkuploadDetails from "./BulkuploadDetails";
const SmeBulkupload = () => {
    const formRef = useRef()
    // const [tableData, setTableData] = useState([])
    const [masterObject, setMasterObject] = useState({})
    const [selectedFields, setSelectedFields] = useState({})

    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [tableData, setTableData] = useState([])
    const [modal, setModal] = useState({ show: false, id: null })

    const [internshipOptions, setInternshipOptions] = useState([])
    const [jobOptions, setJobOptions] = useState([])
    const [smeOptions, setSmeOptions] = useState([])

    const [candidates, setCandidates] = useState([])

    useEffect(() => {
        fetchAllbulkuploadLog()
    }, [page])

    const handleToggleModal = ({ show = false, id = null }) => {
        setModal({ show, id })
        if (!show) fetchAllbulkuploadLog()
    }
    const handleInternshipOptions = async () => {
        try {
            const response = await get(`sme/score/options/internship`)
            setInternshipOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleJobOptions = async () => {
        try {
            const response = await get(`sme/score/options/job`)
            setJobOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleSmeOptions = async (obj = masterObject) => {
        try {
            const query = queryString.stringify(obj)

            const response = await get(`sme/score/options/sme?${query}`)
            setSmeOptions(response.data)
        } catch (error) {
            console.error(error)
        }
    }



    const handleSelectValueChange = ({ selected, name }) => {
        const obj = { ...selectedFields }
        obj[name] = selected

        if (name === "type") {
            obj.job = null
            obj.sme = null
            obj.internship = null
        }
        if (name === "internship" || name === "job") {
            obj.sme = ""
        }

        setSelectedFields(obj)
        handleValueChange({ value: selected.value, name })
    }

    const handleValueChange = async ({ value, name }) => {
        const obj = { ...masterObject }
        obj[name] = value

        if (name === "type") {
            if (value === TYPES.JOB && !jobOptions.length) {
                handleJobOptions()
            } else if (!internshipOptions.length) handleInternshipOptions()

            obj.job = null
            obj.sme = null
            obj.internship = null

            setCandidates([])
            formRef.current?.reset()
        }

        if (name === "internship" || name === "job") {
            obj.sme = null
            handleSmeOptions(obj)
            setCandidates([])
            formRef.current?.reset()
        }


        setMasterObject(obj)
    }



    const reset = () => {
        formRef.current?.reset()
        setMasterObject({})
        setSelectedFields({})
        setSmeOptions([])
        setCandidates([])
    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "Type", field: "type" },
            { label: "Internship/Job", field: "jobintership" },
            { label: "Internship", field: "smename" },
            { label: "Total", field: "total" },
            { label: "Success", field: "success" },
            { label: "Failed", field: "failed" },
            { label: "Actions", field: "options" },
        ],
        rows: tableData,
    }



    const handleSubmit = () => {
        const formData = new FormData()
        for (const key in masterObject) {
            formData.append(key, masterObject[key])
        }
        post(`sme/score/bulkupload`, formData).then((res) => {
            toastr.success(res.message)
            fetchAllbulkuploadLog()
        }).catch((err) => {
            toastr.error(err.response.data.message)
        })

    }

    function fetchAllbulkuploadLog() {
        get(`sme/score/bulkupload?page=${page}&limit=${limit}`).then((res) => {
            console.log(res, "res");
            const { data, count } = res
            data.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = dateConverter(item.date)
                item.jobintership = item?.job ? item.job.name : item.internship.name
                item.type = item.type === 1 ? "Internship" : item.type === 2 ? "Job" : "--"
                item.smename = item.sme.name
                item.total = item.count.total
                item.success = item.count.success
                item.failed = item.count.failed
                item.options = (
                    <div>
                        <Tooltip title="File" arrow placement="top">
                            <a href={`${API_URL}` + item?.fileName} download>
                                <i className="fa fa-file-excel excel-icon"></i>
                            </a>
                        </Tooltip>
                        <Tooltip title="View" arrow placement="top">
                            <i
                                className="fas fa-eye eye-icon"
                                onClick={() => handleToggleModal({ show: true, id: item._id })}
                            ></i>
                        </Tooltip>
                    </div>
                )

                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTableData(data)




        })
    }
    const handlePageChange = value => {
        setPage(value)
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Card>
                    <CardBody>
                        <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                            <Row>
                                <Col md={3}>
                                    <Label>Type <span className="required">*</span></Label>
                                    <Select
                                        menuPosition="fixed"
                                        options={typeOptions}
                                        value={selectedFields.type || ""}
                                        onChange={selected =>
                                            handleSelectValueChange({ name: "type", selected })
                                        }
                                    />
                                </Col>

                                {masterObject.type === TYPES.INTERNSHIP && (
                                    <Col md={3}>
                                        <Label>Internship<span className="required">*</span></Label>
                                        <Select
                                            menuPosition="fixed"
                                            options={internshipOptions}
                                            value={selectedFields.internship || ""}
                                            onChange={selected =>
                                                handleSelectValueChange({
                                                    name: "internship",
                                                    selected,
                                                })
                                            }
                                        />
                                    </Col>
                                )}

                                {masterObject.type === TYPES.JOB && (
                                    <Col md={3}>
                                        <Label>Job<span className="required">*</span></Label>
                                        <Select
                                            menuPosition="fixed"
                                            options={jobOptions}
                                            value={selectedFields.job || ""}
                                            onChange={selected =>
                                                handleSelectValueChange({ name: "job", selected })
                                            }
                                        />
                                    </Col>
                                )}

                                <Col md={3}>
                                    <Label>Internship<span className="required">*</span></Label>
                                    <Select
                                        options={smeOptions}
                                        value={selectedFields.sme || ""}
                                        isDisabled={!masterObject.internship && !masterObject.job}
                                        onChange={selected =>
                                            handleSelectValueChange({ selected, name: "sme" })
                                        }
                                    />
                                </Col>
                                <Col md={3}>
                                    <Label>Upload File<span className="required">*</span></Label>
                                    <Tooltip title="(.xls, .xlsx)" arrow placement="bottom">
                                        <div>
                                            <AvField
                                                name="file"
                                                type="file"
                                                onChange={e =>
                                                    handleValueChange({
                                                        value: e.target.files[0],
                                                        name: "file",
                                                    })
                                                }
                                                accept=".xls, .xlsx"
                                                style={{ lineHeight: "1.8" }}
                                                validate={{ required: { value: true } }}
                                                errorMessage="Please select a file to import"
                                            />
                                        </div>
                                    </Tooltip>
                                </Col>
                                <Col md="4">
                                    <div className="d-flex gap-2 mt-2 mb-2">
                                        <Button color="primary">Submit</Button>
                                        <Button type="reset" onClick={reset} color="danger">
                                            Reset
                                        </Button>
                                        <Tooltip
                                            title="Download sample file"
                                            arrow
                                            placement="bottom"
                                        >
                                            <a
                                                href={`${API_URL}sample/SME_score_update_sample_sheet.xlsx`}
                                                download
                                                style={{ fontSize: "1rem", width: "fit-content" }}
                                                className="d-flex align-items-center btn btn-success "
                                            >
                                                <i
                                                    style={{ fontSize: "13px" }}
                                                    className="fa fa-solid fa-download "
                                                />
                                                <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                                                    Download Sample Sheet
                                                </span>
                                            </a>
                                        </Tooltip>
                                    </div>
                                </Col>
                            </Row>
                            <TablePagination
                                tableId="smeUpdateScoreTable"
                                page={page}
                                data={data}
                                count={totalPage}
                                onChange={handlePageChange}

                            />
                        </AvForm>
                    </CardBody>
                </Card>
            </Container>
            <BulkuploadDetails
                isOpen={modal.show}
                onToggle={() => handleToggleModal({})}
                id={modal.id}
            />
        </div>
    )
}

export default SmeBulkupload
