import React from "react"
import Tooltip from "@mui/material/Tooltip"
import PropTypes from "prop-types"
import { API_URL } from "../../../config"

import "./styles.scss"

const ICONS = {
  eye: "fas fa-eye eye-icon",
  edit: "fas fa-edit edit-icon",
  delete: "fas fa-trash-alt delete-icon",
  share: "fas fa-share share-icon",
  pdf: "fas fa-file-pdf pdf-icon",
  generate: "fas fa-file-signature generate-icon",
  check: "fas fa-check-circle check-icon",
  unCheck: "fas fa-times-circle un-check-icon",
  downloadFile: "uil-file-download download-file-icon",
  asapOfferLetter: "fas fa-file-download asap-offer-letter-icon",
  copy:"far fa-copy"
}

const Icon = ({
  icon,
  title,
  onClick,
  hidden,
  placement = "top",
  arrow = true,
  additionalClass = "",
  style = {},
  href = null,
  ...props
}) => {
  if (hidden) return null
  const iconClass = ICONS[icon]

  if (!iconClass) return null

  const IconElement = (
    <i
      className={`${iconClass} ${additionalClass}`}
      onClick={onClick}
      style={style}
      {...props}
    />
  )

  return (
    <Tooltip title={title} arrow={arrow} placement={placement}>
      {href ? (
        <a
          href={`${API_URL}${href}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {IconElement}
        </a>
      ) : (
        IconElement
      )}
    </Tooltip>
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
  placement: PropTypes.string,
  arrow: PropTypes.bool,
  additionalClass: PropTypes.string,
  style: PropTypes.object,
  href: PropTypes.string,
}

Icon.defaultProps = {
  onClick: () => {},
  hidden: false,
  href: null,
}
export default Icon
