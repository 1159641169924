import React, { useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Collapse, Card, CardBody, Button } from "reactstrap"
import classnames from "classnames"
import { Divider } from "@mui/material"
import { get } from "../../../helpers/api_helper"
import moment from "moment"
import { Tooltip } from "@mui/material"
import { API_URL } from "../../../config"
import {
  resultValues,
  statusValues,
  testStatusValues,
} from "../../ScreeningTest/TestResults/helper"
import { typeValues } from "../../ScreeningTest/TestCreation/helper"
import { renderTableValue } from "../../../helpers/functions"
import { useNavigate } from "react-router-dom"
// import Retake from "./Retake"

const TestTypeOptions = {
  1: "KTU",
  2: "Specialization",
  3: "Internship",
}

const StatusValues = {
  0: "Not Started",
  1: "Ongoing",
  2: "Completed",
  3: "Terminated",
  4: "Absent",
}

const ResultValues = {
  0: "Passed",
  1: "Failed",
  2: "Retake",
}

const MarkAssessment = ({ candidateId }) => {
  const navigate = useNavigate()
  const [selectedEnrolled, setSelectedEnrolled] = useState([])
  const [popupView, setPopupView] = useState(false)
  const [modalData, setModalData] = useState(null)

  const [activeTab, setActiveTab] = useState(null)
  const [assessmentMarks, setAssessmentMarks] = useState([])
  const [data, setData] = useState([])
  const [internshipData, setInternshipData] = useState([])
  const toggleTab = tab => {
    setActiveTab(prevTab => (prevTab === tab ? null : tab))
  }

  useEffect(() => {
    handleTableData()
  }, [candidateId])

  // const handleNavigate = url => {
  //   navigate(`/test-results/${url}`)
  // }

  // const handleToggle = ({ show = false, id = null } = {}) => {
  //   // setModal({ show, id })
  //   handleTableData()
  // }

  const certificates = {
    columns: [
      { label: "#", field: "id", width: 50 },
      { label: "Date", field: "date", width: 50 },
      { label: "Type", field: "type", width: 50 },
      { label: "Name", field: "name", width: 50 },
      { label: "Status", field: "status", width: 50 },
      { label: "General", field: "general", width: 50 },
      { label: "Specialization", field: "specialization", width: 50 },
      { label: "Result", field: "result", width: 50 },
      // { label: "Options", field: "options", width: 100 },
    ],
    rows: [],
  }

  function handleTableData() {
    get(`candidate/screentest?candidate=${candidateId}`)
      .then(res => {
        const result = res?.data || []
        console.log("Fetched data:", result)
        setAssessmentMarks(result)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  useEffect(() => {
    const fetchData = async () => {
      get(`candidate/assessment?candidate=${candidateId}`)
        .then(res => {
          console.log("res", res)
          const result = res?.data || []
          console.log("Fetched data:", result)
          setData(result)
        })
        .catch(error => {
          console.error("Error fetching data:", error)
        })
    }

    fetchData()
  }, [])


  useEffect(() => {
    const fetchInternshipData = async () => {
      get(`candidate/assessment/internship?candidate=${candidateId}`)
        .then(res => {
          console.log("res", res)
          const result = res?.data || []
          console.log("Fetched data:", result)
          setInternshipData(result)
        })
        .catch(error => {
          console.error("Error fetching data:", error)
        })
    }

    fetchInternshipData()
  }, [])

  

  const rows = assessmentMarks?.map((test, index) => {
    const generalTooltip = (
      <Tooltip title={`Mark/Total`} arrow placement="top">
        {Number(test?.markObtained?.general || 0).toFixed(0)}/
        {Number(test?.generalMark || 0).toFixed(0)}
      </Tooltip>
    )

    const specializationTooltip = (
      <Tooltip title={`Mark/Total`} arrow placement="top">
        {Number(test?.markObtained?.specialization || 0).toFixed(0)}/
        {Number(test?.maxMark || 0).toFixed(0)}
      </Tooltip>
    )

    let status = statusValues.COMPLETED
    if (test.status === testStatusValues.ABSENT) {
      status = statusValues.ABSENT
    } else if (test.status === testStatusValues.ONGOING) {
      status = statusValues.ONGOING
    }

    // const options = (() => {
    //   if (status === statusValues.COMPLETED) {
    //     return (
    //       <div>
    //         {/* <Tooltip title="View" arrow placement="top">
    //           <i
    //             className="fas fa-eye eye-icon"
    //             onClick={() => handleNavigate(test.uniqueUrl)}
    //           />
    //         </Tooltip> */}
    //         {test.result !== resultValues.RETAKE && (
    //           <Tooltip title="Retake" arrow placement="top">
    //             <i
    //               className="fa fa-history ml-2"
    //               style={{ cursor: "pointer" }}
    //               onClick={() => handleToggle({ id: test._id, show: true })}
    //             />
    //           </Tooltip>
    //         )}
    //       </div>
    //     );
    //   } else if (
    //     status === statusValues.ABSENT &&
    //     test.result !== resultValues.RETAKE
    //   ) {
    //     return (
    //       <div>
    //         <Tooltip title="Retake" arrow placement="top">
    //           <i
    //             className="fa fa-history ml-2"
    //             style={{ cursor: "pointer" }}
    //             onClick={() => handleToggle({ id: test._id, show: true })}
    //           />
    //         </Tooltip>
    //       </div>
    //     );
    //   }
    //   return null;
    // })();

    const result = resultValues[test.result] ? (
      <Button size="sm" disabled color={resultValues[test.result]?.color}>
        {resultValues[test.result]?.text}
      </Button>
    ) : (
      renderTableValue("")
    )

    return {
      id: index + 1,
      date: moment(test.date).format("DD-MM-YYYY"),
      type: typeValues[test.type],
      name: test.screenName || "",
      status: statusValues[status],
      general: generalTooltip,
      specialization: specializationTooltip,
      result: result,
      // options: options,
    }
  })

  certificates.rows = rows
  const tableData = {
    columns: [
      {
        label: "Company",
        field: "company",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Internship",
      //   field: "internship",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Job",
        field: "job",
        sort: "desc",
        width: 100,
      },
      {
        label: "Unique Id",
        field: "uniqueId",
        sort: "asc",
        width: 100,
      },
      {
        label: "SME",
        field: "sme",
        sort: "asc",
        width: 100,
      },
    ],
    rows: data.map(item => ({
      company: item.company,
      date: moment(item.date).format("DD-MM-YYYY"),
      job: item.job,
      uniqueId: item.uniqueId,
      internship: item.internship,
      sme: item.sme,
      status:item.status === 0
        ? "Pending"
        : item.status === 1
        ? "Applied"
        : item.status === 2
        ? "Shortlisted"
        : item.status === 3
        ? "Placed"
        : item.status === 4
        ? "rejected from application"
        : item.status === 5
        ? "rejected from shortlisted"
        : "",
      type: item.type === 1
      ? "KTU"
      : item.type === 2
      ? "General"
      : item.type === 3
      ? "Public Sector"
      : "Unknown"

    })),
  }
  const internshiptableData = {
    columns: [
      {
        label: "Company",
        field: "company",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Internship",
      //   field: "internship",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Job",
      //   field: "job",
      //   sort: "desc",
      //   width: 100,
      // },
      {
        label: "Internship",
        field: "internship",
        sort: "desc",
        width: 100,
      },
      {
        label: "Unique Id",
        field: "uniqueId",
        sort: "asc",
        width: 100,
      },
      {
        label: "SME",
        field: "sme",
        sort: "asc",
        width: 100,
      },
    ],
    rows: internshipData.map(item => ({
      company: item.company,
      date: moment(item.date).format("DD-MM-YYYY"),
      // job: item.job,
      uniqueId: item.uniqueId,
      internship: item.internship,
      sme: item.sme,
      status:
      item.status === 0
  ? "applied"
  : item.status === 2
  ? "shortlisted"
  : item.status === 3
  ? "placed"
  : item.status === 4
  ? "rejected_from_application"
  : item.status === 5
  ? "rejected_from_shortlist"
  : item.status === 6
  ? "withdrawn"
  : item.status === 7
  ? "withdraw_pending"
  : item.status === 8
  ? "withdraw_rejected"
  : item.status === 9
  ? "completed"
  : item.status === 10
  ? "pending"
  : "",
      type: item.type === 1
      ? "KTU"
      : item.type === 2
      ? "General"
      : item.type === 3
      ? "Public Sector"
      : "Unknown"

    })),
  }

  return (
    <Row>
      <Col xl="12">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: activeTab !== 1,
                })}
                type="button"
                onClick={() => toggleTab(1)}
              >
                <h6>Screening Test</h6>
              </button>
            </h2>
            <Collapse isOpen={activeTab === 1} className="accordion-collapse">
              <div className="accordion-body">
                <Row>
                  <Col md="12">
                    <MDBDataTable
                      id="marksTableId"
                      className="marks-assessment"
                      responsive
                      bordered
                      data={certificates}
                      info={true}
                      searching={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>

          {/* {assessmentMarks.length > 0 && ( */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: activeTab !== 2,
                })}
                type="button"
                onClick={() => toggleTab(2)}
              >
                <h6>Internship Assessment Report</h6>
              </button>
            </h2>
            <Collapse isOpen={activeTab === 2} className="accordion-collapse">
              <div className="accordion-body">
                <Row>
                  <Col md="12">
                  <div style={{ maxHeight: "430px", overflowX: "auto",overflowY: "auto" }}>
                    <MDBDataTable
                      striped
                      bordered
                      hover
                      data={tableData}
                      paginationLabel={["Previous", "Next"]}
                      entriesLabel="Show entries"
                      searchLabel="Search"
                      noBottomColumns
                    />
                    </div>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: activeTab !== 3,
                })}
                type="button"
                onClick={() => toggleTab(3)}
              >
                <h6>Job Assessment Report</h6>
              </button>
            </h2>
            <Collapse isOpen={activeTab === 3} className="accordion-collapse">
              <div className="accordion-body">
                <Row>
                  <Col md="12">
                  <div style={{ maxHeight: "430px", overflowX: "auto",overflowY: "auto" }}>
                    <MDBDataTable
                      striped
                      bordered
                      hover
                      data={internshiptableData}
                      paginationLabel={["Previous", "Next"]}
                      entriesLabel="Show entries"
                      searchLabel="Search"
                      noBottomColumns
                    />
                    </div>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          {/* )} */}
        </div>
      </Col>
    </Row>
  )
}

export default MarkAssessment
