import React, { useState, useRef, useEffect, useTransition } from "react"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import toastr from "toastr"
import Swal from "sweetalert2"
import moment from "moment"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { del, get, post, put } from "../../helpers/api_helper"
import Breadcrumb from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import Icon from "../../components/Common/Icon"
import { toTop } from "../../helpers/functions"
import "./Feedabackform.scss"
import { ratingOptions, AssignedForOptions, assignedForValues, categoryOptions, categoryValues, statgeOptions, stageValues } from "./helpers"
const Feedbackforms = () => {
    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [tableData, setTableData] = useState([])

    const [popupView, setPopupView] = useState(false)
    const handleCloseModal = () => setPopupView(false)
    const [popUpData, setpopUpData] = useState([])

    const formRef = useRef()
    const initialMaster = {
        required: { name: false, mobile: false, email: false },
        questions: [{}],
        name: "",
        assignFor: "",
    }
    const initaialSelected = {
        assignFor: "",
    }


    const initialFilter = {
        assignFor: "",
        assigned: "",
        course: "",
    }
    const [masterObj, setmasterObj] = useState(initialMaster)
    const [selected, setSelected] = useState(initaialSelected)
    const [errorMessages, setErrorMessages] = useState([])
    const [jobFairOptions, setjobFairOptions] = useState([])
    const [jobOptions, setjobOptions] = useState([])
    const [internshipOptions, setinternshipOptions] = useState([])

    const [filterObject, setfilterObject] = useState({})
    const [selectedFilter, setselectedFilter] = useState({})

    useEffect(() => {
        getAllJobfiarOptions()
        getAllJobOptions()
        getAllInternshipOptions()
    }, [])


    function getAllJobfiarOptions() {
        get(`options/jobfair`).then((res) => {
            setjobFairOptions(res.data)
        }).catch((err) => {
        })
    }

    function getAllJobOptions() {
        get(`options/all-job`).then((res) => {
            setjobOptions(res.data)
        }).catch((err) => {
        })
    }

    function getAllInternshipOptions() {
        get(`options/all-internship`).then((res) => {
            setinternshipOptions(res.data)
        }).catch((err) => {
        })
    }

    const handleCheckboxChange = field => {
        setmasterObj(prevState => ({
            ...prevState,
            required: {
                ...prevState.required,
                [field]: !prevState.required[field], // Toggle the value of 'name'
            },
        }))
    }

    const handlechange = e => {
        setmasterObj(prev => ({
            ...prev,
            name: e.target.value,
        }))
    }

    function handlevalue(value, name, id) {
        const data = { ...masterObj }
        const prev = data.questions
        prev[id][name] = value
    }

    const handlevalueType = (selected, index) => {
        setmasterObj(prevState => {
            const updatedQuestions = [...prevState.questions];
            updatedQuestions[index] = { ...updatedQuestions[index], type: selected.value };
            return { ...prevState, questions: updatedQuestions };
        });
    };

    function resetAll() {
        formRef.current?.reset()
        setmasterObj(initialMaster)
        setSelected(initaialSelected)
    }



    function handleSubmit() {
        if (masterObj?.id) {
            put(`feedback-form`, masterObj).then((res) => {
                toastr.success(res.message)
                resetAll()
                getAllTableData()
            }).catch((err) => {
                toastr.error(err.response.data.message)
            })

        } else {
            post(`feedback-form`, masterObj).then((res) => {
                toastr.success(res.message)
                resetAll()
                getAllTableData()
            }).catch((err) => {
                toastr.error(err.response.data.message)
            })
        }


    }

    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "Name", field: "name" },
            { label: "Id", field: "uniqueId" },
            { label: "Type", field: "type1" },
            { label: "Assign", field: "assign" },
            { label: "Category", field: "category" },
            { label: "Internship/Job/Job Fair", field: "ijfname" },
            { label: "Action", field: "options" },
        ],
        rows: tableData,
    }


    useEffect(() => {
        getAllTableData()

    }, [page, filterObject])


    function getAllTableData() {
        get(`feedback-form?page=${page}&limit=${limit}&type=${filterObject?.type}&assignFor=${filterObject?.assignFor}&category=${filterObject?.category}`).then((res) => {
            const { count, data } = res
            data.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.category = categoryValues[item.category]
                item.assign = assignedForValues[item.assignFor]
                item.type1 = item?.type === 1 ? "Static" : item?.type === 2 ? "Dynamic" : "--"
                if (item.internship) {
                    item.ijfname = item.internship.name
                } else if (item.job) {
                    item.ijfname = item.job.name
                } else if (item.jobFair) {
                    item.ijfname = it.jobFair.name
                } else {
                    item.ijfname = "---"
                }
                item.options = (
                    <div>
                        <Icon
                            icon="copy"
                            title={``}
                            hidden={item?.type === 2 ? true : false}
                            additionalClass="copy-icon"
                            // href={`https://sda.srvinfotech.com/feedback/fbs/${item.permalink}`}
                            onClick={() => handleCopy(item.permalink)}
                        />
                        <Icon
                            icon="eye"
                            title="View"
                            onClick={() => handlePopupView(item._id)}
                        />
                        <Icon
                            icon="edit"
                            title="Edit"
                            onClick={() => handleUpdate(item._id)}
                        />

                        <Icon
                            icon="delete"
                            title="Delete"
                            onClick={() => handleDelete(item._id)}
                        />
                    </div>
                )

                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTableData(data)
        }).catch((err) => {
            console.error(err)

        })
    }


    const handlePageChange = value => {
        setPage(value)
    }

    function resetFilter() {
        setfilterObject({})
        setselectedFilter({})
    }

    const handleDelete = async id => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            })
            if (!result.isConfirmed) return
            const response = await del(`feedback-form?id=${id}`)
            toastr.success(response.message)
            getAllTableData()
        } catch (error) {
            toastr.error(error.response.data.message)
        }
    }

    function handleUpdate(id) {
        toTop()
        get(`feedback-form/update-details?id=${id}`).then((res) => {
            const data = res.data
            setmasterObj({
                ...data,
                id: data._id,
                internship: data?.internship?.value,
                job: data?.job?.value,
                jobFair: data?.jobFair?.value
            })
            setSelected({
                ...data,
                assignFor: { label: assignedForValues[data.assignFor], value: data.assignFor },
                category: { label: categoryValues[data.category], value: data.category },
                type: { label: data.type === 1 ? "Static" : data.type === 2 ? "Dynamic" : "", value: data.type },
                stage: { label: stageValues[data.stage], value: data?.stage }
            })
        })
    }

    function handleCopy(link) {
        const baseUrl = "https://sda.srvinfotech.com/feedback/fbs/";
        const fullUrl = `${baseUrl}${link}`;
        navigator.clipboard
            .writeText(fullUrl)
            .then(() => {
                toastr.success("Link copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    }

    function handlePopupView(id) {
        get(`feedback-form/details?id=${id}`).then((res) => {
            let data = res.data
            if (data.required.name) {
                data.name1 = (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <i
                                style={{ color: "green", textAlign: "center" }}
                                class="fas fa-check"
                            ></i>
                        </div>
                    </>
                )
            } else if (!data.required.name) {
                data.name1 = (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <i
                                style={{ color: "green", textAlign: "center" }}
                                class="fas fa-times"
                            ></i>
                        </div>
                    </>
                )
            }
            if (data.required.mobile) {
                data.mobile = (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <i
                                style={{ color: "green", textAlign: "center" }}
                                class="fas fa-check"
                            ></i>
                        </div>
                    </>
                )
            } else if (!data.required.mobile) {
                data.mobile = (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <i
                                style={{ color: "green", textAlign: "center" }}
                                class="fas fa-times"
                            ></i>
                        </div>
                    </>
                )
            }
            if (data.required.email) {
                data.email = (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <i
                                style={{ color: "green", textAlign: "center" }}
                                class="fas fa-check"
                            ></i>
                        </div>
                    </>
                )
            } else if (!data.required.email) {
                data.email = (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <i
                                style={{ color: "green", textAlign: "center" }}
                                class="fas fa-times"
                            ></i>
                        </div>
                    </>
                )
            }

            setpopUpData(data)

        }).catch((err) => {

        })
        setPopupView(true)
    }

    return (
        <>
            <Modal
                show={popupView}
                isOpen={popupView}
                toggle={() => setPopupView(!popupView)}
                size="lg"
                centered={true}
                className="lead-modal"
            >
                <div className="modal-header">
                    <div className="modal-title">
                        <h6 style={{ marginBottom: "0px" }} className="modal-title ">
                            <h6 className="mx-2 my-2">Feedback Details</h6>
                        </h6>
                    </div>
                    <button
                        style={{ marginBottom: "2px" }}
                        type="button"
                        onClick={handleCloseModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <Col md="12">
                        <ul className="feedback-details-modal">
                            {/* <li>
                                Date:<Label>{popUpData?.date}</Label>
                            </li> */}
                            <li>
                                Feedback Form Name: <Label>{popUpData?.name}</Label>
                            </li>
                            <li>
                                Created By: <Label>{popUpData?.addedBy?.name}</Label>
                            </li>
                            <li>
                                Type : <Label>{popUpData?.type === 1 ? "Static" : popUpData?.type === 2 ? "Dynamic" : "---"}</Label>
                            </li>
                            <li>
                                Assign For : <Label>{assignedForValues[popUpData.assignFor]}</Label>
                            </li>
                            <li>
                                Category : <Label>{categoryValues[popUpData?.category]}</Label>
                            </li>
                            <li>
                                {popUpData?.internship ? "Internship" : popUpData?.job ? "Job" : popUpData?.jobFair ? "JobFair" : "Internship/job/job Fair"} : <Label>{popUpData?.internship ? popUpData.internship.label : popUpData?.job ? popUpData.job.label : popUpData?.jobFair ? popUpData.jobFair.label : "---"}</Label>
                            </li>
                            <li>
                                Name : <Label>{popUpData?.name1}</Label>
                            </li>
                            <li>
                                Mobile : <Label>{popUpData?.mobile}</Label>
                            </li>
                            <li>
                                Email : <Label>{popUpData?.email}</Label>
                            </li>
                        </ul>
                    </Col>
                    <div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ width: "120px", textAlign: "left" }}>
                                        Question
                                    </th>
                                    <th style={{ width: "320px", textAlign: "left" }}>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {popUpData &&
                                    popUpData?.questions?.map((item, index) => (
                                        <tr>
                                            <td style={{ textAlign: "left" }}>{item.question}</td>
                                            <td style={{ textAlign: "left" }}>
                                                {item.type == 1
                                                    ? "Yes/No"
                                                    : item?.type == 2
                                                        ? "Rating"
                                                        : item.type == 3
                                                            ? "Emoji Rating"
                                                            : ""}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Feedback Form" />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        onValidSubmit={handleSubmit}
                                        ref={formRef}>
                                        <Row>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label>Type</Label>
                                                    <Select
                                                        options={[{ label: "Static", value: 1 }, { label: "Dynamic", value: 2 }]}
                                                        classNamePrefix="select2-selection"
                                                        onChange={item => {
                                                            setmasterObj({
                                                                ...masterObj,
                                                                type: item?.value,
                                                            })
                                                            setSelected({ ...selected, type: item })
                                                        }}
                                                        value={selected?.type}
                                                        placeholder="Type"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Assigned For</Label>
                                                    <Select
                                                        // options={AssignedForOptions}
                                                        options={
                                                            selected?.type?.value === 2
                                                                ? AssignedForOptions.filter(option => [1, 3].includes(option.value)) // Show only 1 and 3 for Dynamic
                                                                : AssignedForOptions // Show all options for Static
                                                        }
                                                        classNamePrefix="select2-selection"
                                                        onChange={item => {
                                                            setmasterObj({
                                                                ...masterObj,
                                                                assignFor: item?.value,
                                                            })
                                                            setSelected({ ...selected, assignFor: item })
                                                        }}
                                                        value={selected?.assignFor || null}
                                                        placeholder="Assigned For"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Category</Label>
                                                    <Select
                                                        // options={categoryOptions}
                                                        options={
                                                            selected?.assignFor?.value === 1 && selected.type.value === 2
                                                                ? categoryOptions.filter(option => [1, 2].includes(option.value)) // Show only 1 and 3 for Dynamic
                                                                : selected?.assignFor?.value === 3 && selected.type.value === 2
                                                                    ? categoryOptions.filter(option => [1, 2, 3].includes(option.value))
                                                                    : categoryOptions
                                                        }
                                                        classNamePrefix="select2-selection"
                                                        onChange={item => {
                                                            setmasterObj({
                                                                ...masterObj,
                                                                category: item?.value,
                                                            })
                                                            setSelected({ ...selected, category: item })
                                                        }}
                                                        value={selected?.category || null}
                                                        placeholder="Type"
                                                    />
                                                </div>
                                            </Col>
                                            {masterObj?.type === 2 ? (
                                                <>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Stage</Label>
                                                            <Select
                                                                // options={statgeOptions}
                                                                options={
                                                                    selected?.assignFor?.value === 1 && selected?.category?.value === 1
                                                                        ? statgeOptions.filter(option => [1, 2, 3].includes(option.value))
                                                                        : selected?.assignFor?.value === 1 && selected.category?.value === 2
                                                                            ? statgeOptions.filter(option => [1, 2,].includes(option.value))
                                                                            : selected?.assignFor?.value === 3 && selected?.category?.value === 1
                                                                                ? statgeOptions.filter(option => [4, 5].includes(option.value))
                                                                                : selected?.assignFor?.value === 3 && selected?.category?.value === 2
                                                                                    ? statgeOptions.filter(option => [4].includes(option.value))
                                                                                    : selected?.assignFor?.value === 3 && selected?.category?.value === 3
                                                                                        ? statgeOptions.filter(option => [6].includes(option.value))
                                                                                        : statgeOptions
                                                                }
                                                                classNamePrefix="select2-selection"
                                                                onChange={item => {
                                                                    setmasterObj({
                                                                        ...masterObj,
                                                                        stage: item?.value,
                                                                    })
                                                                    setSelected({ ...selected, stage: item })
                                                                }}
                                                                value={selected?.stage || null}
                                                                placeholder="Stage"
                                                                isDisabled={!selected.assignFor || !selected.category}
                                                            />
                                                        </div>
                                                    </Col>
                                                </>
                                            ) : ("")}

                                            {selected?.type?.value === 1 && selected?.category?.value === 1 ? (
                                                <>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Internship</Label>
                                                            <Select
                                                                classNamePrefix="select2-selection"
                                                                onChange={item => {
                                                                    setmasterObj({
                                                                        ...masterObj,
                                                                        internship: item?.value,
                                                                    })
                                                                    setSelected({ ...selected, internship: item })
                                                                }}
                                                                value={selected?.internship || null}
                                                                placeholder="Internship"
                                                                options={internshipOptions}

                                                            />
                                                        </div>
                                                    </Col>

                                                </>
                                            ) : null}
                                            {selected?.type?.value === 1 && selected?.category?.value === 2 ? (
                                                <>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Job</Label>
                                                            <Select
                                                                classNamePrefix="select2-selection"
                                                                onChange={item => {
                                                                    setmasterObj({
                                                                        ...masterObj,
                                                                        job: item?.value,
                                                                    })
                                                                    setSelected({ ...selected, job: item })
                                                                }}
                                                                value={selected?.job || null}
                                                                placeholder="Job"
                                                                options={jobOptions}
                                                            />
                                                        </div>
                                                    </Col>

                                                </>
                                            ) : null}

                                            {selected?.type?.value === 1 && selected?.category?.value === 3 ? (
                                                <>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Job Fair</Label>
                                                            <Select
                                                                classNamePrefix="select2-selection"
                                                                options={jobFairOptions}
                                                                onChange={item => {
                                                                    setmasterObj({
                                                                        ...masterObj,
                                                                        jobFair: item?.value,
                                                                    })
                                                                    setSelected({ ...selected, jobFair: item })
                                                                }}
                                                                value={selected?.jobFair || null}
                                                                placeholder="job Fair"
                                                            />
                                                        </div>
                                                    </Col>

                                                </>
                                            ) : null}



                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label>Feedback Form Name</Label>
                                                    <AvField
                                                        name="name"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Feedback Form Name"
                                                        errorMessage="Enter Feedback Form Name"
                                                        onChange={handlechange}
                                                        value={masterObj?.name || ""}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            {selected?.type?.value === 1 ? (
                                                <>

                                                    <Col className="mt-2" md="1" >

                                                        <div
                                                            className="form-check pt"
                                                            style={{ display: "flex", gap: "3px" }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input mycheckbox"
                                                                id="myCheckbox1"
                                                                checked={masterObj?.required.name}
                                                                onClick={() => handleCheckboxChange("name")}
                                                            />
                                                            <label className="myname" htmlFor="myCheckbox1">
                                                                Name
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col className="mt-2" md="1" >

                                                        <div
                                                            className="form-check pt"
                                                            style={{ display: "flex", gap: "3px" }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input mycheckbox"
                                                                id="myCheckbox2"
                                                                checked={masterObj?.required.mobile}
                                                                onClick={() => handleCheckboxChange("mobile")}
                                                            />
                                                            <label className="myname" htmlFor="myCheckbox2">
                                                                Mobile
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col className="mt-2" md="1" >
                                                        <div
                                                            className="form-check pt"
                                                            style={{ display: "flex", gap: "3px" }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input mycheckbox"
                                                                id="myCheckbox3"
                                                                checked={masterObj?.required.email}
                                                                onClick={() => handleCheckboxChange("email")}
                                                            />
                                                            <label className="myname" htmlFor="myCheckbox3">
                                                                Email
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </>
                                            ) : ("")}


                                            {masterObj.questions.map((item, idx) => (
                                                <Row key={idx}>
                                                    <>
                                                        <Col md="4">
                                                            <div className="mb-3">
                                                                <Label>Question</Label>
                                                                <AvField
                                                                    name={`feedbackname-${idx}`}
                                                                    className="form-control"
                                                                    rows={1}
                                                                    type="textarea"
                                                                    placeholder="Question"
                                                                    value={item.question || ""}
                                                                    validate={{
                                                                        required: {
                                                                            value: true,
                                                                            errorMessage: "Enter Question",
                                                                        },
                                                                    }}
                                                                    // errorMessage="Enter Question"
                                                                    errorMessage={errorMessages[idx]}
                                                                    onChange={e => {
                                                                        handlevalue(e.target.value, "question", idx)
                                                                    }}
                                                                    onValid={() => {
                                                                        setErrorMessages(prevMessages => {
                                                                            const updatedMessages = [...prevMessages]
                                                                            updatedMessages[idx] = "" // Clear error message on valid input
                                                                            return updatedMessages
                                                                        })
                                                                    }}
                                                                    onInvalid={() => {
                                                                        setErrorMessages(prevMessages => {
                                                                            const updatedMessages = [...prevMessages]
                                                                            updatedMessages[idx] = "Enter Question" // Set error message on invalid input
                                                                            return updatedMessages
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md="3" key={idx}>
                                                            <div className="mb-3">
                                                                <Label>Type</Label>
                                                                <Select
                                                                    options={ratingOptions}
                                                                    classNamePrefix="select2-selection"
                                                                    onChange={selected => {
                                                                        handlevalueType(selected, idx)
                                                                    }}
                                                                    value={ratingOptions.find(option => option.value === item.type) || null}                                                                    placeholder="Select type"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            {" "}
                                                            {masterObj.questions.length > 1 && (
                                                                <div className="mt-4 d-flex justify-content-start">
                                                                    <span
                                                                        className="mr-1 p-2"
                                                                        style={{
                                                                            fontSize: "1.125rem",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        // onClick={() => {
                                                                        //   const data = [...questions]
                                                                        //   data.splice(idx, 1)
                                                                        //   setQuestions(data)
                                                                        // }}
                                                                        onClick={() => {
                                                                            const data = { ...masterObj }
                                                                            data.questions.splice(idx, 1)
                                                                            setmasterObj(data)
                                                                        }}
                                                                    >
                                                                        <i className="trash-btn mdi mdi-delete"></i>
                                                                    </span>
                                                                </div>
                                                            )}{" "}
                                                        </Col>
                                                    </>
                                                </Row>
                                            ))}
                                            <Col md={12}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <button
                                                        type="button"
                                                        // onClick={() => {
                                                        //   const data = [...questions]
                                                        //   data.push({})
                                                        //   setQuestions(data)
                                                        // }}
                                                        onClick={() => {
                                                            const newData = [...masterObj.questions, {}]
                                                            setmasterObj(prevState => ({
                                                                ...prevState,
                                                                questions: newData,
                                                            }))
                                                        }}
                                                        className="waves-effect btn btn-outline-light d-flex"
                                                        style={{ gap: "5px" }}
                                                    >
                                                        Add more{" "}
                                                        <i className="mdi mdi-plus-circle-outline"></i>
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3" style={{ paddingTop: "20px" }}>
                                                    <Button
                                                        color={masterObj?._id ? "warning" : "primary"}
                                                        style={{ marginRight: "10px" }}
                                                        type="submit"
                                                    >
                                                        {masterObj?._id ? "Update" : "Submit"}
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        type="button"
                                                        onClick={resetAll}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="2">
                                        <div className="mb-3">
                                            <Label>Type</Label>
                                            <Select
                                                options={[{ label: "Static", value: 1 }, { label: "Dynamic", value: 2 }]}
                                                classNamePrefix="select2-selection"
                                                onChange={item => {
                                                    setfilterObject({
                                                        ...filterObject,
                                                        type: item?.value,
                                                    })
                                                    setselectedFilter({ ...selectedFilter, type: item })
                                                }}
                                                value={selectedFilter?.type || null}
                                                placeholder="Type"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label>Assigned For</Label>
                                            <Select
                                                options={AssignedForOptions}

                                                classNamePrefix="select2-selection"
                                                onChange={item => {
                                                    setfilterObject({
                                                        ...filterObject,
                                                        assignFor: item?.value,
                                                    })
                                                    setselectedFilter({ ...selectedFilter, assignFor: item })
                                                }}
                                                value={selectedFilter?.assignFor || null}
                                                placeholder="Assigned For"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label>Category</Label>
                                            <Select
                                                options={categoryOptions}

                                                classNamePrefix="select2-selection"
                                                onChange={item => {
                                                    setfilterObject({
                                                        ...filterObject,
                                                        category: item?.value,
                                                    })
                                                    setselectedFilter({ ...selectedFilter, category: item })
                                                }}
                                                value={selectedFilter?.category || null}
                                                placeholder="Type"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="mt-4">
                                            <Button onClick={resetFilter} color="danger" type="button">Reset</Button>
                                        </div>
                                    </Col>
                                    <TablePagination
                                        page={page}
                                        tableId="assignTest"
                                        data={data}
                                        count={totalPage}
                                        onChange={handlePageChange}
                                    />
                                </Row>
                            </CardBody>
                        </Card>

                    </Row>


                </div>
            </div>

        </>
    )
}

export default Feedbackforms
